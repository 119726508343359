$nav-custom-breakpoint: 1020px !default;

.site-header {
  border-top: 2px solid palette(blue, light);
  border-bottom: 4px solid palette(blue, x-dark);
  background-color: #fff;
  position: relative;
  z-index: z-index(site-header);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
  transition: all .25s;
  height: var(--header-height);

  @include breakpoint($nav-custom-breakpoint) {
    flex-wrap: nowrap;
  }
}

.logo {
  padding: .5rem 1.5em .5rem 0;
  margin-right: .5em;
  max-width: 50%;
  transition: all .25s;
  display: flex;
  align-items: center;
  height: 54px; // required for transition

  @include breakpoint($med) {
    padding: .3rem 1.5em .3rem 0;
    position: relative;
    top: -.125em;
  }

  @include breakpoint($nav-custom-breakpoint) {
    height: 54px; // required for transition
    border-right: 1px solid palette(blue, x-light);
  }

  a, img {
    border: none;
    max-height: 100%;
    transition: all .25s;
  }

  img {
    width: 180px;
    @include breakpoint($nav-custom-breakpoint) {
      width: 200px;
    }
  }
}

#nav,
.nav-buttons {
  display: none;

  @include breakpoint($nav-custom-breakpoint) {
    display: block;
  }
}

.mobile-nav-button {
  margin-left: auto;
  
  @include breakpoint($nav-custom-breakpoint) {
    display: none;
  }
}


// Centered Logo in Header
// ----------------------------------------------------------------
@include breakpoint($lg) {
  .site-header.no-nav {
    text-align: center;

    .logo {
      width: 100%;
      max-width: none;
      margin: 0 auto;

      a {
        width: 100%;

        img {
          display: inline-block;
        }
      }
    }
  }
}


// Top-bar announcement
// ----------------------------------------------------------------

.site-announcement {
  background-color: palette(blue, light);
  color: white;
  font-size: .9em;
  padding: .35em .25em .25em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--announcement-height);
  z-index: z-index(site-announcement);
  box-shadow: 0 2px 5px rgba(0,0,0,.25);
  border-bottom: 1px solid rgba(255,255,255,.5);

  a {
    color: inherit;
  }
}

// Announcement gets two lines one narrower screens
body:has(.site-announcement) {
  --announcement-height: 2rem;

  @include breakpoint(max-width $med) {
    --announcement-height: 3rem;
  }
}


// Sticky Header
// ----------------------------------------------------------------

@include breakpoint($nav-custom-breakpoint) {
  // Stick the header and announcement to the top of the browser
  body.sticky-header {
    .site-announcement,
    .site-header {
      position: fixed;
      width: 100%;
    }

    .site-header {
      top: var(--announcement-height);
    }
    .wrapper {
      padding-block-start: var(--page-block-start); // minus borders
    }
  }
  
  // Shrink the header a bit when scrolling
  body.scrolled {
    --header-height: 3.5rem;

    .site-header {
      padding: 0 1rem;
      box-shadow: 0 1px 3px rgba(0,0,0,.25);
      border-bottom: 1px solid palette(blue, light);

      .nav-buttons .button {
        font-size: 0.75em;
      }
      .logo {
        height: 45px;
  
        img {
          width: 180px;
        }
      }
    }
    #nav {
      font-size: .8em; // originally .9em
    }
    #nav .mega-menu {
      font-size: 1.128em; // originally 1em
    }
    
  }
}


// Sticky mobile-nav
@include breakpoint(max-width $nav-custom-breakpoint) {
  body.sticky-header.scrolled-mobile {
    --header-height: 2.25rem;
    --header-border-height: 0;

    .mobile-navbar {
      position: fixed;
      top: 0;
      width: 100%;
      font-size: .8em;
      border-bottom: 1px solid #25578b;
      box-shadow: 0 2px 5px rgba(0,0,0,.25);
      
      ul {
        height: var(--header-height);
      }
    }
  }
}