// Tables
// ----------------------------------------------------------------
table {
  width: 100%;
  border-collapse: collapse;

  &.simple {
    th, td {
      border: none;
    }
  }
  th,td {
    padding: .5em;
    border-bottom: 1px solid #eeeeee;
    vertical-align: top;
    text-align: left;
  }
  thead {
    th {
      font-size: .82rem;
      letter-spacing: 1px;
      font-weight: normal;
      text-transform: uppercase;
      color: #888888;
      border-bottom: 2px solid #dddddd;
      vertical-align: bottom;
      white-space: nowrap;
    }
  }
  tbody {
    th { font-weight: bold; }
  }
  tr.odd td {
    background: #fffce5;
  }
  
  caption {
    text-align: center;
  }

  .actions {
    text-align: right;
  }
}


// Hover-able Table
// ----------------------------------------------------------------
.table-hover {
  tbody tr:hover {
    td, th {
      background-color: #fffceb;
    }
  }
}


// Zebra Striped Table
// ----------------------------------------------------------------
.table-striped {
  td {
    border: none;
  }
  tbody tr:nth-child(odd) {
    td {
      background-color: palette(blue, x-light);
    }
  }
}



// Responsive Tables
// ----------------------------------------------------------------

@include breakpoint(max-width $lg) {

  // Responsive-Stacked
  // ref: http://css-tricks.com/responsive-data-tables/
  // add class to table or container DIV
  // ----------------------------------------------------------------
  $resp-table-stacked-label-width: 40%;
  .table-responsive-stacked {
    display: block;

    // Force table to not be like tables anymore
    table,thead, tbody, th, td, tr { 
      display: block; 
    }

    // Hide table headers (but not display: none;, for accessibility)
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 3px solid #eee;
      padding-bottom: 1em;
      padding-top: 1em;
      

      &:nth-child(2n) {
        background-color: rgba(0,0,0,.05);
      }
    }

    tbody tr:hover {
      td { background-color: #fff; }
      &:nth-child(2n) td { background-color: rgba(0,0,0,.05); }
    }
    
    td { 
      // Behave  like a "row"
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;

      &[data-label] {
        padding-left: $resp-table-stacked-label-width + 3%;
      }

      &:last-child,
      &.no-border {
        border-bottom: none;
      }

      &.empty {
        display: none;
      }

      &.actions {
        padding-left: 0;
        text-align: center;
        &:before {
          content: '' !important;
        }
      }
    }
    
    td:before { 
      content: attr(data-label); // Add a label from the data-label attribute
      position: absolute; // Now like a table header
      top: 6px; // Top/left values mimic padding
      left: 6px;
      width: $resp-table-stacked-label-width; 
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
      font-size: .9em;
    }

    // Override zebra striping
    tbody tr:nth-child(odd) td {
      background-color: transparent;
    }
  }

  // Responsive-Scrollable
  // ref. https://getbootstrap.com/docs/4.0/content/tables/
  // add class to a container DIV
  // ----------------------------------------------------------------
  .table-responsive-scrollable {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
  }
}






// Reusable Scrum Presentation table
// ref: /agile/scrum/a-reusable-scrum-presentation/
// ----------------------------------------------------------------
.reusable-presentation-table {
  .language {
    padding-left: 36px;
  }
  .download {
    text-align: center;
    vertical-align: middle;
  }
  .icon-download-powerpoint,
  .icon-download-keynote,
  .icon-download-openoffice {
    @include hide-text;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
  .quiet {
    font-size: .85em;
  }
  .flag {
    max-width: 32px;
    box-shadow: 0 1px 1px rgba(0,0,0,.35);
    margin-top: 0.25em;
  }
}


// Likert Table for surveys
// ----------------------------------------------------------------
.likert-table {
  @include trailer(2);
  width: 100%;
  table-layout: fixed;

  tr {
    transition: background .2s;
    width: 100%;
  }

  td {
    padding-left: 50%;    
  }

  td, th {
    border-color: #e3e3e3;
    
    @include breakpoint($lg) {
      padding: 1em 0;
    }
  }

  thead th {
    white-space: normal;
    text-align: center;
    line-height: 1.5;
    padding-left: 0;
  }

  label {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  thead h3,
  thead h4 {
    margin: 0 !important;
    font-weight: 600;
    text-transform: none;
    text-align: left;
    font-size: 1.6em;
    letter-spacing: normal;
  }

  @include breakpoint($lg) {
    thead th:first-child,
    tbody th {
      width: 45%;
    }
    
    thead th,
    tbody td {
      text-align: center;
      width: 11%;
    }  
  }
  

  tbody tr:hover {
    background: #dde8ef;
  }

  input[type="radio"] {
    display: inline-block !important;
    cursor: pointer;
  }
}


// Course Modules table
// ----------------------------------------------------------------
.course-modules-table th {
  width: 30%;
  color: #4797eb;
  font-family: Dosis;
  font-weight: normal;
  font-size: 1.15em;
  border-bottom: none;
  border-top: 1px solid #d5efff;
}
.course-modules-table td {
  padding-bottom: 3em;
  border-bottom: none;
  border-top: 1px solid #d5efff;
  padding-top: 0.8em;
}

// Training Table
// ----------------------------------------------------------------
.upcoming-training-table {
  thead {
    td {
      border-bottom-width: 2px;
      text-transform: uppercase;
      color: $quiet-font-color;
    }
  }

  a:not(.button) {
    text-decoration: none;
    &:hover { text-decoration: underline; }
  }

  .training-date {
    white-space: nowrap;
  }

  .early-bird {
    @include type-setting(-1);
    font-style: italic;
    color: $quiet-font-color;
  }


  .training-actions {
    white-space: nowrap;
  }

  .td-more-info,
  .actions,
  .training-actions {
    .alert {
      width: 7.2em;
      text-align: center;
      float: right;
      clear: both;
    }
    .button {
      white-space: nowrap;
      width: 7em;
    }
  }

  .spots-left,
  .sold-out {
    color: #900;
  }


  
  // Fix bug in FF where tbodies don't show borders
  tbody+tbody:before{
    content: " ";
  }

  // space out sibling tbodys
  tbody + tbody {
    border-top: 2px solid #d0dae2;
  }

}

// Organized by Week
// ----------------------------------------------------------------
.upcoming-training-weeks-heading {
  @include type-setting(0);
  @include rhythm(.325,0,0,0);
  font-weight: 600;
  clear: both;

  @include breakpoint($lg) {
    float: left;
    width: 20%;    
  }

  &.has-flag {
    padding-left: 30px;      
  }

  i {
    float: left;
    width: 24px;
    height: 24px;
    margin-left: -30px;
  }

  time,
  em {
    @include type-setting(-1);
    display: block;
    font-weight: normal;
    font-style: italic;
    color: $quiet-font-color;
  }
}

.upcoming-training-weeks {
  @include padding-leader(1);

  .upcoming-training-table {
    margin-bottom: 0;

    @include breakpoint($lg) {
      @include padding-trailer;
      border-bottom: 1px solid $divider-color;
      float: right;
      width: 80%;  

      tr:last-child {
        td, th { border-bottom: none; }
      }
      .training-date   { width: 14%; }
      .training-course { width: 32%; }
      .training-price  { width: 25%; }
      .td-more-info    { width: 30%; }
    }
  }
}

// Upcoming training table on smaller screens
// ----------------------------------------------------------------
@include breakpoint(max-width $lg) {
  .upcoming-training-weeks-heading {
    margin: 0 -1*$gutter;
    padding: .5rem $gutter;
    font-size: 1.1em;
    display: flex;
    flex-wrap: wrap;
    background-color: #f7f7f7;
    line-height: 1.2;

    time {
      font-size: 0.9em;
      font-style: normal;
      color: $base-font-color;
      margin-left: auto;
    }
    em {
      flex-basis: 100%;
    }
  }
  .upcoming-training-table {
    font-size: 0.9em;

    tr {
      display: flex;
      flex-wrap: wrap;
      padding: .75em 0;
      position: relative;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      > td {
        border: none;
        padding: 0.125em;
      }
    }
    a {
      white-space: normal;
    }
    .training-date {
      flex: 1 0 25%;
      font-weight: bold;
    }
    .training-course,
    .training-location,
    .training-time,
    .training-price,
    .training-instructor,
    .training-actions {
      flex: 0 1 75%;
      margin-left: auto;
    }
    .training-location,
    .training-course {
      font-weight: bold;
    }
    .training-location .text-small,
    .training-course .text-small {
      display: none;
    }

    // create an invisible button over the whole row for easy clicking
    .training-date a::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .training-date a::before {
      content: url('data:image/svg+xml;utf8,<svg width="18" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M16.9 17.023L3.26 30.664a1.627 1.627 0 01-1.195.492c-.469 0-.867-.164-1.196-.492l-.132-.133a1.759 1.759 0 01-.528-1.195 1.513 1.513 0 01.457-1.195L12.98 15.828.666 3.515A1.513 1.513 0 01.21 2.32c.024-.469.2-.867.528-1.195L.869.992A1.627 1.627 0 012.065.5c.469 0 .867.164 1.195.492l13.64 13.64c.376.329.563.727.563 1.196s-.187.867-.562 1.195z" /></svg>');
      position: absolute;
      top: 10px;
      right: 0;
      width: 18px;
      height: 32px;
      opacity: .08;
    }
  } 
}

// on small screens, show the training time in the TD
// on bigger screens, show it within the training-date
.upcoming-training-table {
  .training-date .training-time,
  .training-course .training-time { display: none; }
  td.training-time                { display: block; }

  @include breakpoint(min-width $lg) {
    .training-date .training-time,
    .training-course .training-time { display: block; }
    td.training-time                { display: none; }
  }
}