// --------------------------------------------------------
// triangles
// --------------------------------------------------------
// $direction: top, left, right, bottom, top-left, top-right, bottom-left, bottom-right
// $color: hex, rgb or rbga
// $size: px or em
// @example
// .element{
//     @include triangle(top, #000, 50px);   
// }
@mixin triangle($direction, $color, $size){
  display: block;
  height: 0;
  width: 0;
  
  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  } @else if $direction == 'top-left' {
    border-top: $size solid $color; 
    border-right: $size solid transparent;  
  } @else if $direction == 'top-right' {
    border-top: $size solid $color; 
    border-left: $size solid transparent;
  } @else if $direction == 'bottom-left' {
    border-bottom: $size solid $color; 
    border-right: $size solid transparent;  
  } @else if $direction == 'bottom-right' {
    border-bottom: $size solid $color; 
    border-left: $size solid transparent;
  }
}