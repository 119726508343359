$search-bg: palette(blue, x-dark) !default;
$search-height: 25em;

#search {
  @include rhythm(0,1,0,0);
  background-color: $search-bg;
  position: relative;
  height: $search-height;
  top: -1 * $search-height;
  margin-bottom: -1 * $search-height;

  &.is-open-results,
  &.is-open {
    top: 0;
    margin-bottom: 0;
  }

  &.has-opened {
    transition: all .4s ease-in-out;
  }

  .search-title {
    color: palette(blue, x-light);
    margin: 0 auto 1em;
    font-size: 1.5em;
    font-weight: 300;
  }

  form {
    @include trailer(2);
    position: relative;
  }

  input[type=search],
  button {
    font-size: 2em;
    height: 1.5em;
    
    @include breakpoint($lg) {
      font-size: 3em;
    }
  }

  input[type=search] {
    transition: all .2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-bottom: 2px solid palette(blue, light);
    padding-bottom: .125em;
    padding-right: 2em;
    font-family: $sans-font;
    font-weight: 300;
    color: #fff;
    width: 100%;

    &::placeholder {
      color: palette(blue, xxx-dark);
    }

    // Hide "clear" button
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }
    &::-ms-clear,
    &::-ms-reveal {  display: none; width : 0; height: 0; }


    &:focus {
      outline: none;
      border-bottom-color: #fff;
    }
  }

  button {
    width: 1.5em;
    border: none;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
  }

  .search-browse {
    display: none;
  }

}
