// Icons
// ----------------------------------------------------------------

$default-icon-color: #2C7ACB !default;

.icon-hidetext {
  @include hide-text;
}

*[class*="button-icon-"] {
  padding-left: 2.5em !important;
  background-position: .75em 50%;
}

*[class*="heading-icon-"] {
  padding-left: 1.5em;
  background-position: 0 50%;
}

i.icon-64 {
  @include block-icon(80px);

  img {
    display: block;
    width: 64px;
    max-width: 64px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

$iconcircle-width: 180px;
$iconcircle-small-width: 148px;
$iconcircle-xs-width: 108px;

.iconcircle {
  width: $iconcircle-width;
  height: $iconcircle-width;
  border-radius: 50%;
  background: palette(blue, light);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    display: block;
    margin: 0 auto;
  }

  &-small,
  &-xs {
    width: $iconcircle-small-width;
    height: $iconcircle-small-width;

    img {
      transform: scale(.8);
    }
  }

  &-xs {
    width: $iconcircle-xs-width;
    height: $iconcircle-xs-width;
  }

  &-light {
    background-color: palette(blue, x-light);
  }

  &-white {
    background-color: #fff;
  }
}
a.iconcircle:hover {
  background-color: palette(blue);
}

// SVG Sprite
// ----------------------------------------------------------------
.icon {
  font-size: 1.25em;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: $default-icon-color; // Can be overriden for specific icons
}
.icon-white {
  fill: #fff;
}
.icon-large {
  font-size: 5em;
}