// Footer
// ----------------------------------------------------------------
.site-footer {
  font-size: .75rem;
  background-color: #696969;
  border-top: none;
  border-bottom: 6px solid $footer-bg-color;
  color: white;
  padding: 0 0 .5em 0;

  > .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .logo-footer {
    margin-bottom: -.9em;
    flex: 0 0 75px;
    display: none;

    img { display: block; }
  }

  .footer-info-nav {
    ul {
      @include inline-block-list;
      margin-top: .75rem;
    }
    li {
      margin-right: 1em;
      width: auto;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover { text-decoration: underline; }
  }
}

@include breakpoint($med-lg) {
  .site-footer{
    > .container {
      flex-direction: row;
    }
    .logo-footer {
      display: block;
    }
    .footer-info-nav {
      flex: 2;

      ul {
        margin-top: 0;
      }
    }
  }
}

.footer-cta {
  background-color: palette(grey, xx-light);
  background-image: url(/mgs_assets/images/v4/footer-mountains.svg);
  background-position: 105% 100%;
  background-repeat: no-repeat;
  padding: 2em 0 5em;
  color: $base-font-color;

  h2 {
    letter-spacing: normal;
    color: palette(teal);
    text-transform: none;
    font-size: 2.9em;
    margin-block-end: .4em;
  }
  p:not(.text-small) {
    font-size: 1.2em;
    text-wrap: pretty;
  }
  fieldset {
    overflow: visible;
    flex-wrap: wrap;
  }
  fieldset > * {
    flex: 1;
    min-width: 200px;
  }
  label {
    display: block;
    float: none;
    width: auto;
  }
  input[type=text],
  input[type=email] {
    width: 100%;
  }
}

.footer-nav {
  padding: 1em 0;
  color: #f7f7f7;
  
  > .container {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint($med) {
      flex-wrap: nowrap;
    }

    > * {
      flex: 1;
      min-width: 12.5em;
    }
  }

  ul {
    list-style: none;
    margin-top: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 2em;
  }
  
  li {
    padding-left: 0;
    padding: .25em 0;
    opacity: .75;

    &:hover {
      opacity: 1;
    }
  }

  h4 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0;
  }

  :where(h4,strong,a) {
    color: inherit
  }

  .button:hover {
    text-decoration: none;
  }
}

// Simple footer, hide extra
// ----------------------------------------------------------------
.site-footer-simple {
  padding-bottom: 0;

  :is(.footer-cta, .footer-nav) {
    display: none;
  }

  .logo-footer {
    margin-block: .5em 0;
  }
}