
.stripe-features h3,
.stripe-features h4,
.stripe-features h5 {
  font-size: 1.1em;
  color: $base-font-color;
  font-weight: bold;
  margin-bottom: 0;
}
.media-feature.left .media-item,
.media-feature.left-small .media-item {
  align-self: flex-start;
}
.media-feature.left-small img {
  max-width: 5rem;
}

.media-feature.center-bgrect {
  .media-item { width: 100%; }
}