// Buttons
$button-bg:         #ddd !default;
$button-text-color: #333 !default;
$button-v-padding:  .55em !default;
$button-h-padding:  1.5em !default;
$button-border-radius: 4px !default;


@mixin button-colors($bg_color, $text_color: $button-text-color, $bg_hover: '') {
  background-color: $bg_color;
  color: $text_color !important;
  border-color: lighten($bg_color, 20);

  &:hover {
    color: $text_color !important;
    @if ($bg_hover != '') {
      background-color: $bg_hover;
    } @else {
      background-color: saturate(darken($bg_color, 10), 10);
    }
  }
}

@mixin button-height($v-padding: $button-v-padding) {
  // border-radius: 1em + ($v-padding * 2);
  padding-top: $v-padding;
  padding-bottom: $v-padding;
}

@mixin button($bg_color: $button-bg, $text_color: $button-text-color, $bg_hover: '') {
  @include button-colors($bg_color, $text_color, $bg_hover);
  @include button-height;
  @include type-setting(0);
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all .25s;
  border-radius: $button-border-radius;
  padding-left: $button-h-padding;
  padding-right: $button-h-padding;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: $base-font-family;
  // white-space: pre-wrap;

  @include breakpoint($med) {
    white-space: nowrap;
  }
}

.button {
  @include button($button-bg);

  > i[class*="icon-"] {
    vertical-align: middle;
    margin-right: .25em;
    margin-left: -.5em;
  }
}
.button:has(svg),
.button:has(img) {
  display: flex;
  align-items: center;
  gap: .5em;
}

// Change global button to this?
.button-yellow {
  @include button-colors(palette(yellow), #222, lighten(palette(yellow), 15));
}

.button-disabled,
.button[disabled] {
  @include button(palette(grey, x-light), palette(grey), palette(grey, x-light));
  cursor: default;
}

.button-invert {
  @include button(white, palette(teal), palette(teal, x-light));
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.25);
}

.button-ghost {
  @include button(white, palette(teal), palette(teal, x-light));
  border: 1px solid palette(teal);
}

.button-ghost-invert {
  @include button(transparent, #fff, palette(blue, dark));
  border: 1px solid palette(blue, light);
}

.button-ghost-alt {
  @include button(white, palette(blue), palette(blue, x-light));
  border: 1px solid palette(blue);  
}

.button-alt,
.button-teal {
  @include button(palette(teal), white, palette(teal));
  border: 1px solid palette(teal);
}

.button-error {
  @include button(#bf0013, white, darken(#bf0013, 10));
}

.button-transparent {
  @include button(rgba(0, 0, 0, 0.4), #fff, #000);
  border: 1px solid #fff;
}

.button-shadow,
.button.has-shadow {
  box-shadow: 0 2px 3px rgba(0,0,0,.3);
}

.button-tags {
  @include button(palette(blue, xx-dark), white, palette(blue, xx-dark));
  border: 1px solid palette(blue, xx-dark);
  opacity: 0.85;
  text-transform: uppercase;
  line-height: normal;
  padding: .4em 1em;
  @include type-setting(-1);
}

.button-tags-invert {
  @include button(white, palette(blue, x-dark), white);
  border: 1px solid white;
  text-transform: uppercase;
  line-height: normal;
  padding: .4em 1em;
  @include type-setting(-1);
}

.button-small {
  @include type-setting(-1);
  padding-top: $button-v-padding * .5;
  padding-bottom: $button-v-padding * .5;
}

.button-large {
  @include type-setting(1);
}

.button-wide {
  white-space: normal; // Allow to wrap
  
  @include breakpoint($med) {
    padding-left: $button-h-padding * 2;
    padding-right: $button-h-padding * 2;
  }
}

.button-full {
  display: block;
  width: 100%;
}

// Split buttons
// Creates two buttons that each cover 48% of the width
.button-double {
  @include horizontal-list;
  text-align: center;
  > li {
    float: left;
    width: 50%;
    padding: 0 1em;
    &:last-child {
      float: right;
    }
  }
  .button {
    width: 100%;
  }
}