@use 'sass:math';

:root {
  --container-width: 1170px;
}

// Colors
// ----------------------------------------------------------------
$palettes: (
  blue: (
    xx-light: #f7fcff,
    x-light: #d5efff,
    light: #65bef6,
    base: #4797eb,
    dark: #2c7acb,
    x-dark: #25578b,
    xx-dark: #1d446c,
    xxx-dark: #13253b
  ),
  teal: (
    xx-light: #f6fffe,
    x-light: #dcfffa,
    light: #9cfff2,
    base: #00c3a9,
    dark: #447871
  ),
  grey: (
    xx-light: #f7f7f7,
    x-light: #dbe3e5,
    light: #abb4b9,
    mid-light: #828f97,
    base: #808080,
    mid-dark: #4c4d52,
    dark: #2e3135,
    x-dark: #202226
  ),
  yellow: (
    light: #ffd738,
    base: #fec60b
  ),
  red: (
    light: #fb606e,
    base: #bf0013
  )
);

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}

$link-color         : palette(blue);
$link-color-alt     : palette(teal);

$header-bg-color    : transparent;
$body-bg-color      : white;
$footer-bg-color    : #4a4a4a;

$divider-color      : palette(blue, x-light);
$divider-color-alt  : #48494b;

$base-font-color    : palette(grey, mid-dark);
$quiet-font-color   : palette(grey, light);
$heading-color      : palette(blue, dark);
$heading-color-alt  : palette(blue, x-dark);
$heading-color-hl   : palette(teal);



// Fonts
// ----------------------------------------------------------------
// $base-font-size            : 1.0625rem; // 17px based on default 16px
$base-font-size: 19px;
$base-line-height: 28px;

// 1.25 Major Third Scale (ref. http://type-scale.com/)
$type-scale: (
  5: 3.052rem,
  4: 2.441rem,
  3: 1.8rem,
  2: 1.5rem,
  1: 1.25rem,
  0: 1rem,
  -1: .8rem,
  -2: .64rem,
);

@function type-scale($level) {
  @return map-get($type-scale, $level);
}

@function line-height($level) {
  @return math.div($base-line-height,$base-font-size);
}

@mixin type-setting($level: 0) {
  line-height: line-height($level);

  // Default to 85% as big if level 0 or higher
  @if ($level >= 0) {
    font-size: type-scale($level) * .85;
  } @else {
    font-size: type-scale($level);
  }

  // On large, change to regular size
  @include breakpoint($lg) {
    font-size: type-scale($level);
    // line-height: line-height($level);
  }
}


$sans-font                 : "interface", sans-serif;
$serif-font                : Times, serif;
$fixed-font                : monospace, sans-serif;
$mono-font                 : $fixed-font;

$relative-font-sizing      : true;
$round-to-nearest-half-line: true;

$base-font-family          : $sans-font;

$heading-font              : $sans-font;
$heading-font-alt          : 'Dosis', sans-serif;
$h1-font-size              : type-scale(4);
$h2-font-size              : type-scale(2);
$h3-font-size              : type-scale(0);
$h4-font-size              : type-scale(0);
$h5-font-size              : type-scale(0);
$h6-font-size              : type-scale(-1);



// Defaults
// ----------------------------------------------------------------
$default-border-radius: 4px;

// Text Shadow
$default-text-shadow-color   : rgba(0,0,0,.25);
$default-text-shadow-h-offset: 0;
$default-text-shadow-v-offset: 1px;
$default-text-shadow-blur    : 1px;

// Box Shadow
$default-box-shadow-color   : rgba(0,0,0,.25);
$default-box-shadow-h-offset: 0;
$default-box-shadow-v-offset: 1px;
$default-box-shadow-blur    : 3px;
$default-box-shadow-spread  : 0;
$default-box-shadow         : $default-box-shadow-color $default-box-shadow-h-offset $default-box-shadow-v-offset $default-box-shadow-blur $default-box-shadow-spread;

// Transitions
$default-transition-duration: .25s;

// Buttons
$button-bg:         palette(blue);
$button-text-color: white;

// Panels
$panel-bg: white;
$panel-border-radius: 4px;
$panel-border-color: palette(blue, x-light);
$panel-border-color-alt: palette(grey, x-light);
$panel-border-color-highlight: palette(teal);
$panel-padding: $base-line-height * .5;


// Modules
// ----------------------------------------------------------------
$announcement-height-small: 3rem;

$breadcrumbs-height     : $base-line-height * 1.5;
$breadcrumb-color       : palette(blue, light);
$breadcrumb-color-dark  : palette(blue, dark);

$summary-closed-color: palette(blue);
$summary-open-color: palette(teal);
$summary-icon-width: 18px;
$summary-icon-gap: .5em;


body {
  --header-height: 5.9rem;
  --header-border-height: 6px;
  --announcement-height: 0px;
  --page-block-start: calc(var(--header-height) + var(--announcement-height) - var(--header-border-height));
}
@include breakpoint(900px) {
  body {
    --header-height: 5rem;
  }
} 


// Grid
// ----------------------------------------------------------------
$gutter: 1rem;

$susy: (
  columns      : 12,
  gutters      : 0.2222222222, //18px/81px,
  column-width : 81px,
);


// Z-Indexes
// ----------------------------------------------------------------
$z-indices: (
  mega-menu: 100,
  site-header: 200,
  site-announcement: 300,
  floating-cta: 400,
);
@function z-index($item) {
  @return map-get($z-indices, $item);
}

// Breakpoints
// ----------------------------------------------------------------
$sm     : 400px;
$med    : 600px;
$med-lg : 760px;
$lg     : 900px;
$xl     : 1170px;

$nav-custom-breakpoint: 1020px;