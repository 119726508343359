// Horizontal Scrollers
// ----------------------------------------------------------------
.scroller-wrapper {
  display: flex;
  justify-content: center; // ensures if less than full-width, will be centered

  &:has(.container) {
    overflow: scroll;

    .container {
      overflow: visible;

      > :where(.box, .panel) {
        &:first-child { margin-inline-start: 0; }
      }
    }
  }
}
:is( .instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller ) {
  --inline-margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1em 2em;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  scroll-padding-inline-start: var(--inline-margin);

  > * {
    flex: 1 0 280px;
    scroll-snap-align: start;
  }

  > :where(.box, .panel) {
    &:first-child { margin-inline-start: var(--inline-margin); }
    &:last-child  { margin-inline-end: var(--inline-margin); }
    &:first-child:last-child { margin-inline: auto; }
  }

  @include breakpoint(378px) {
    --inline-margin: 2em;
    > :is(.box, .panel) {
      flex-basis: 26%;
      min-width: 370px;
      max-width: 400px;      
    }
  }
}

// Instructors Scroller
// ----------------------------------------------------------------

.instructors-scroller {
  padding-block-start: 4em;

  .box {
    background-color: #fff;

    blockquote {
      margin: 1em 2em;
    }
  }

  @include breakpoint(378px) {
    .box {
      flex-basis: 26%;
      min-width: 370px;
      max-width: 400px;

      &:first-child {
        margin-inline-start: 2em;
      }
      &:first-child:last-child {
        margin-inline: auto;
      }
    }
  }
}


// Courses Scroller
// ----------------------------------------------------------------

.courses-scroller {
  padding-block-start: 4em;

  @include breakpoint(378px) {
    .box {
      flex-basis: 26%;
      min-width: 370px;
    }
  }
}


// Testimonials
// ----------------------------------------------------------------
.testimonials-scroller {
  --large-width: 475px;

  .box {
    background-color: #fff;

    blockquote {
      margin: 1em 2em;
      width: 100%;
    }

    .wistia_responsive_padding {
      width: 100%;
    }
  }

  .single-testimonial {
    font-style: italic;
    font-size: 1.1em;
    color: #447871;
  }

  @include breakpoint(475px) {
    .box {
      flex-basis: 475px;

      &:first-child {
        margin-inline-start: 2em;
      }
    }
  }
}


// Blog Posts
// ----------------------------------------------------------------
.blog-scroller-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 2em;
  flex-wrap: wrap;
  padding-inline-start: calc((100vw - var(--container-width)) / 2);
  padding-inline-end: 1em;
  margin-inline-start: 1rem; // container padding
}
.blog-scroller-actions {
  display: flex;
  gap: 1em;
  padding-block-end: .25em;

  .grid-scroller-arrows {
    display: flex;
    gap: .5em;
  }

  .grid-scroller-arrow {
    border-color: palette(grey, light);
    color: palette(grey, light) !important;
    padding-inline: .55em;
  }
}

.blog-scroller {
  padding-inline-start: 1rem; // container padding
  align-items: stretch;

  // Adjust inline start paddings/margins to ensure first item appears in line with the containers
  > *:first-child {
    margin-inline-start: 0;
  }
  @include breakpoint(1170px) {
    scroll-padding-inline-start: calc((100vw - var(--container-width)) / 2);

    > *:first-child {
      margin-inline-start: calc((100vw - var(--container-width)) / 2);
      scroll-margin-inline-start: calc((100vw - var(--container-width)) / 2);
    }
  }

  > :is(.box, .panel) {
    margin-top: .5em; // make space for hover
  }

  @include breakpoint(378px) {
    > :is(.box, .panel) {
      max-width: 377px; // Keep inline with width of 3-wide grid
    }
  }
}