// Lead Magnets
// ----------------------------------------------------------------

// Container
.c-promo {
  container: promo / inline-size;
}

.promo-lead-magnet,
.promo-lead-magnet-stacked,
.stripe-lead-magnet {
  text-align: center;

  h1 {
    @include rhythm(0, 1, .5, 0);
    color: inherit;
  }

  h2 {
    @include type-setting(3);
    margin: 0;
    padding: .75em 0 .25em;
    color: inherit;
  }
}



// A promo is the same content, but isn't a full stripe, it appears within a content-narrow or other content block

.promo-lead-magnet {
  border-radius: $default-border-radius;
  padding: 1em;
  display: grid;
  gap: .5em 1em;
  grid-template-columns: 1fr;
  grid-template-areas: "image"
                       "heading"
                       "content"
                       "action";

  .promo-heading {
    grid-area: heading;
    font-size: 1.2em;
    line-height: 1.2;
    margin-block: .25em;
    padding: 0;
    text-wrap: pretty;
  }
  .promo-image {
    grid-area: image;
    display: block;
    margin-inline: auto;
  }
  .promo-content {
    grid-area: content;
    font-size: .8em;
    text-wrap: pretty;
  }
  .promo-description {
    align-self: center;
  }
  .promo-action  {
    grid-area: action;
  }
  .promo-form {
    text-align: left;
    font-size: .85em;
  }
  .button-full {
    max-width: 22em;
    margin-inline: auto;
  }

  // Container allows lead magnet to be horizontal when in larger space
  @container promo (min-width: 440px) {
    gap: 0 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "heading heading"
                         "image   content"
                         "image   action";

    .promo-heading {
      font-size: 1.75em;
    }
    .promo-content {
      align-self: center;
      font-size: .9em;
    }
  }
}

.promo-lead-magnet-grey {
  background-color: #f3f3f3 !important;
  color: palette(blue, x-dark) !important;

  .button {
    color: palette(blue, x-dark) !important;
  }
}

.promo-lead-magnet-simple {
  grid-template-columns: 1fr;
  gap: 0;
  padding: 0;

  .promo-heading {
    padding: 1rem 1rem 0 1rem;
    font-size: 1.3em;
    text-align: left;
    line-height: 1.2;
  }
  .promo-image {
    display: none;
  }
  .promo-content {
    padding: .5rem 1rem 1rem 1rem;
    text-align: left;
  }
  .promo-action {
    padding: 1rem;
    background-color: #e8f6ff;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .promo-form {
    text-align: left;
    font-size: 0.8em;

    input[type=text],
    input[type=email] {
      font-size: 1em;
      padding: .1em .2em;
    }
  }

  @include breakpoint($med) {
    grid-template-columns: 3fr 2fr;

    .promo-heading {
      grid-column: 1/2;
      grid-row: 1/2;
      align-self: end; // put at the bottom of the first row
    }
    .promo-content {
      grid-column: 1/2;
      grid-row: 2/3;
      align-self: start; // put at the top of the second row
    }
    .promo-action {
      grid-column: 2/3;
      grid-row: 1 / span 2;
    }
  }

  &-blue {
    background-color: #d5efff !important;
    border: 1px solid #c1e5fc;
    color: $base-font-color !important;

    .promo-heading {
      color: #466c93;
    }
    .promo-action {
      background-color: #e8f6ff;
    }
    .button {
      background-color: #4797eb;
      color: #fff !important;
    }
  }
}

// When a content stripe is followed by a lead magnet stripe, don't need space between
.stripe-content + .stripe-lead-magnet {
  padding-top: 0;
}

// Lead magnet styles
// ----------------------------------------------------------------
.lead-magnet-white {
  background-color: #fff !important; // Importants requried to override inline color styles
  color: $base-font-color !important;
  padding-left: 0;
  padding-right: 0;

  .promo-heading {
    padding-top: 0;
  }

  input[type=email],
  input[type=text] {
    background-color: #fafafa;
  }
  .button {
    box-shadow: none;
    background-color: palette(blue);
    color: #fff !important;
  }
}
.lead-magnet-email-only {
  .promo-image,
  .promo-body,
  .signup-first-name,
  .signup-checkboxes,
  .signup-body {
    display: none;
  }
  .content-split {
    grid-template-columns: 1fr;
  }
  .email-signup-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: end;
  }
  .signup-privacy {
    grid-column: 1/3;
    grid-row: 2/3;
    margin-top: 0;
  }
  .signup-email,
  .signup-submit {
    margin: 0;
  }
  .lead-magnet-button {
    width: 100%;
  }
  .promo-heading {
    padding-bottom: 0;
  }
}

// Modal Windows
// ----------------------------------------------------------------
.lead-magnet-modal {
  margin: -30px;  // negative default modaal padding
  display: flex;
  flex-direction: column;

  @include breakpoint($med) {
    flex-direction: row;
  }

  .lmm-image {
    flex: 1;
    overflow: hidden;

    @include breakpoint($med) {
      flex: 1 1 50%;
      display: flex;
      align-items: center;
    }

    img {
      margin: 1em auto -60px;

      @include breakpoint($med) {
        margin: auto;
      }
    }
  }

  .lmm-content {
    flex: 3;
    padding: 2em;

    @include breakpoint($med) {
      flex: 1 1 50%;
    }
  }
  
}


// BG Stripes (delete when possible)
// ----------------------------------------------------------------

.stripe-101-quotes {
  color: #fff;
  background-image: radial-gradient(circle farthest-side at 75% 50%, #abb6b6 0%, #415151 55%, #213333 80%);

  h1 {
    text-shadow: 0 0 5px rgba(0, 0, 0, .2);
    color: palette(teal, x-light);
  }

  h2 {
    text-shadow: 0 0 5px rgba(0, 0, 0, .2);
    color: palette(teal, x-light);
  }
}

.stripe-lm-swa,
.stripe-lm-scrummaster-interview-questions {
  color: #fff;
  background: palette(blue, x-dark);
}

.stripe-lm-surviving-daily-scrum {
  color: #fff;
  background: palette(teal);
}

.stripe-lm-swa {
  @include stripe-dark;
  background: palette(blue, x-dark) url('/mgs_assets/images/blue_ripple.jpg') no-repeat center center;
  background-size: cover;
}

.stripe-lm-book,
.stripe-lm-sprint-review-agenda {
  @include padding-leader;
  color: palette(blue, x-dark);
  background-color: palette(blue, light);
}

.stripe-lm-micromanagement-log {
  @include padding-leader;
  background-color: #ffa827;

  h2 { color: white; }
}

// Scrum Landing Page banner backgrounds
.stripe-lm-bus {
  background-color: #11253a; // extra dark blue to match content-bg images
  color: palette(grey, x-light);
  background-position: 0 50%;

  @include breakpoint($lg) {
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/mgs_assets/images/v4/photos/content-bg-bus.jpg');
  }

  .banner-content {
    @include padding-leader(2);
    @include padding-trailer(3);
    text-align: center;

    @include breakpoint($lg) {
      @include span(5);
      @include pre(6);
      @include post(1);
      text-align: left;
    }

    @include breakpoint($xl) {
      @include span(5);
      @include pre(5);
      @include post(2);
    }

    h2 {
      @include rhythm(.5, 0, 0, .25);
      font-size: 1.8em;
      color: palette(blue, light);
    }
  }
}

// Lead Magnet Banner
// ----------------------------------------------------------------
.banner-lead-magnet {

  // Ensure lead magnet in banner uses background color of banner
  .promo-lead-magnet {
    background-color: transparent !important;
    color: #fff;
  }
  h2 {
    font-size: 2.44em;
    font-weight: 100;

    &::after {
      content: "";
      height: 2px;
      background-color: #65bef6;
      width: 2.25em;
      display: block;
      margin: .35em auto 0;
    }
  }
  .promo-content-centered {
    max-width: 60ch;
    margin: 0 auto 1em;
    font-size: 1.1em;
    text-align: center;
  }
}