$nav-dropdown-bg-color: #fcfcfc;
$nav-link-color: #5480ab;
$nav-link-hover-color: #65BEF6;

$header-height: $base-line-height * 3;

$nav-custom-breakpoint: 1020px !default;

$nav-icon-hl-1: #4797eb;
$nav-icon-hl-2: #65bef6;
$nav-icon-description: #6089b1;

#nav {
  font-size: .9em;
}

  
// Primary
.nav-primary {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  
  > li > span,
  > li > a {
    @include link-colors($nav-link-color, $nav-link-hover-color);
    transition-duration: .15s;
    text-decoration: none;
    position: relative;
    padding: .55em 1em;
    display: block;
  }
  a.media {
    display: flex; // override block for media elements
  }

  span {
    cursor: default;
  }

  > li {
    position: relative;
  }
  
  // Hover State
  > li:hover > a {
    color: $nav-link-hover-color;
  }

  *[class*='icon-'] {
    margin-left: .25em;
  }
}
  
// Dropdown Nav
// ----------------------------------------------------------------
.primary-subnav {
  box-shadow: rgba(0,0,0,.4) 0 1px 4px;
  border-radius: $default-border-radius;
  background: $nav-dropdown-bg-color;
  z-index: 9999;
  padding: 0;
  font-size: 0.85rem;
  line-height: 1.5;
  overflow: hidden;

  .media {
    gap: .75em;
  }

  a {
    @include link-colors(#12243a, #12243a);
    padding-left: 1em;
    padding-right: .5em;

    &:hover {
      background-color: #EBF7FF;
    }
  }

  em {
    font-size: 0.8em;
    color: #6089b1;
    display: block;
  }

  .nav-item-secondary {
    background-color: #eaebeb;
    font-size: .9em;

    a:hover {
      background-color: #d8d8d8;
    }
  }
  li:not(.nav-item-secondary) + .nav-item-secondary {
    border-top: 1px solid #cacbcb;
    > a { border-top: 1px solid #e7e8e8; }
  }
}


.icon.search {
  font-size: 0.9em;
  fill: $nav-link-color;
}

.icon.dropdown-arrow {
  font-size: 0.6em;
  vertical-align: middle;
  margin-left: .3em;
}

.icon.mobile-nav {
  font-size: 2.25em;
}

// Nav Buttons
// ----------------------------------------------------------------
.nav-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: .5em;
  margin-left: auto;

  .button {
    @include button-colors(palette(blue,x-light), #325687);
    font-size: 0.8em;
    display: flex;
    align-items: center;
    gap: .5em;
  }

  svg {
    margin-top: .1em;
    fill: #65BEF6;
  }
  .button:hover svg {
    fill: #4797EB;
  }

  .training-button {
    display: none;
  
    @include breakpoint($nav-custom-breakpoint) {
      display: flex;
    }
  }
}


// Mobile nav (using jquery.mmenu)
// ----------------------------------------------------------------

$mobile-navbar-height: 2.5rem;

#mm-nav {
  background: #222;

  .icon.dropdown-arrow,
  .icon-dropdown-arrow {
    display: none;
  }

  .button {
    margin: 1em;
  }
}

#mobile-nav {
  background-color: palette(blue, xxx-dark);
  background-image: linear-gradient(to right, rgba(0,0,0,.4), transparent 15px);

  .divider {
    font-weight: bold;
    background-color: #304763;
    text-transform: uppercase;
  }
  .divider:not(:first-child) {
    margin-top: 1em;
  }
}

.mobile-navbar {
  margin-left: -1 * 1rem;
  margin-right: -1 * 1rem;
  width: calc(100% + 2rem);

  background: palette(blue);
  font-size: 0.9em;

  @include breakpoint($nav-custom-breakpoint) {
    display: none;
  }

  ul {
    @include unstyled-list;
    display: flex;
    justify-content: space-around;
    height: $mobile-navbar-height;
    margin: 0;
  }

  a {
    color: #fff;
    padding: 0 .65rem;
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .icon {
    fill: #fff;
  }
}

// Stripe-nav
// a horizontal nav bar at the top of the page
// ----------------------------------------------------------------

.stripe-nav {
  background: palette(blue);

  ul {
    display: flex;
    justify-content: center;
  }

  // a {
  //   @include link-colors(white, palette(teal, light));
  //   text-decoration: none;
  //   display: block;
  //   padding: $base-line-height*.5 1.5em;
  //   text-align: center;
  //   border-bottom: 3px solid palette(blue);
  //   font-size: .91em;
  // }

  .current a {
    color: palette(teal, light);
    border-bottom-color: palette(teal, light);
  }
}

// Side Nav
// ----------------------------------------------------------------
.sidenav {
  font-size: .9em;
  margin-top: 1em;

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0 0 .5em;
  }
  ul ul,
  ol ol {
    padding-left: 1em;
  }
  > ul > li > ul,
  > ol > li > ol {
    font-weight: bold;
    @include breakpoint($med) {
      font-size: 0.85em;      
    }
  }
  > ul > li > ul > li > ul,
  > ol > li > ol > li > ol {
    font-size: 1em;
    font-weight: normal;
  }
  a {
    text-decoration: none;
    color: $base-font-color;
    display: block;
    padding: .25em 0;

    &:hover {
      color: $link-color;
      text-decoration: underline;
    }
  }

  .current > a {
    color: palette(blue);
    &::after {
      content: url("data:image/svg+xml; utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12'%3E%3Cpath fill='%234797EB' fill-rule='nonzero' d='m1.13.203 5.007 5.225a.651.651 0 0 1 0 .907L1.13 11.561a.654.654 0 0 1-.927.02.653.653 0 0 1-.02-.928l4.594-4.796L.184 1.11a.653.653 0 0 1 .02-.927.653.653 0 0 1 .926.02Z'/%3E%3C/svg%3E");
      display: inline-block;
      margin-left: .5em;
      vertical-align: middle;
    }
  }
}

// Sidenav stays in place when scrolling
@include breakpoint($med) {
  .sidenav-sticky {
    position: sticky;
    top: 4rem;
    max-height: calc(100vh - 4rem);
    overflow: auto;
  }  
}

// Sidenav collapses when on small screens
// requires a subnav-heading
.sidenav-heading {
  --heading-color: $base-font-color;

  color: var(--heading-color);
  border-bottom: 2px solid palette(blue, light);
  padding-bottom: .35em;
  margin-bottom: .5em;
  margin-top: .5em;

  > a,
  > a:hover {
    text-decoration: none;
    color: var(--heading-color);
  }

  .dropdown-arrow {
    display: none;
  }
}
@include breakpoint(max-width $med) {
  .sidenav-collapsible {
    border: 1px solid #ccc;
    background: linear-gradient(#fafafa, #ddd);
    border-radius: .25em;
    padding: .25em 1em;
    overflow: hidden;
    position: relative;
    z-index: 100;

    &:target,
    &:focus,
    &:focus-within {
      background: #fafafa;
      box-shadow: 0 2px 5px rgba(0,0,0,.2);

      > ul, > ol {
        max-height: none;
      }
      .sidenav-heading {
        border-bottom: 2px solid palette(blue, light);
        padding-bottom: .35em;
        margin-bottom: .5em;
      }
      ~ .sidenav-collapsible-close {
        display: block;
      }
    }

    .sidenav-heading {
      border: none;
      margin: 0;
      padding: 0;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between; // align arrow to the right
      }

      .dropdown-arrow {
        display: block;
        font-size: 1rem;
      }
    }

    > ul, > ol {
      max-height: 0;
      margin: 0;
      overflow: hidden;
    }
  }
}
.sidenav-collapsible-close {
  display: none;
  text-align: center;
  padding: 0.5em;

  &::before {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.3);
    content: "";
    cursor: default;
  }
}
