* {
  box-sizing: border-box;
}

html {
  background: $footer-bg-color;
  scroll-behavior: smooth;
  scroll-padding-top: 76px;
}

body {
  margin: 0;
  padding: 0;
  background-color: $body-bg-color;
  scroll-behavior: smooth;
}

.wrapper {
  padding-block-start: var(--announcement-height);
}


.container {
  @include container;
  padding: 0 $gutter;

  // Once wide enough (bigger than $lg) to already include horizontal padding
  @include breakpoint(1170px) {
    padding: 0;
  }
}

// Flexbox centering
// ----------------------------------------------------------------
.center-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


// Layout Containers for centered content
// ----------------------------------------------------------------
.content-narrow,
.content-x-narrow,
.content-centered,
.content-med {
  margin-left: auto;
  margin-right: auto;
}

.content-med,
.content-centered {
  max-width: 52rem;
  @include breakpoint($lg) {
    width: 83%;    
  }
}

.content-narrow {
  max-width: 40.75rem;
  @include breakpoint($lg) {
    width: 66%;    
  }
}

.content-x-narrow {
  max-width: 30.25rem;
  @include breakpoint($lg) {
    width: 50%;    
  }
}

@include breakpoint($lg) {
  .content-left  { margin-left: 0; }
  .content-right { margin-right: 0; }
}


.content-sidebar,
.content-sidebar-wide {
  --gap: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--gap);

  @include breakpoint($lg) {
    flex-direction: row;
  }

  @include breakpoint($xl) {
    margin-left: 8.46%;
    margin-right: 8.46%;

    &.full {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
@include breakpoint($lg) {
  // 70/30 content
  .content-sidebar {
    .cs-content { flex: 1 1 70%; }
    .cs-sidebar { flex: 1 1 30%; }
  }

  // 60/40 content
  .content-sidebar-wide {
    .cs-content { flex: 1 1 60%; }
    .cs-sidebar { flex: 1 1 40%; }
  }

  .content-sidebar-reverse {
    flex-direction: row-reverse;
  }
}



// 50/50 content
.content-split,
.content-split-bordered {
  --gap: 0; 
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr;

  @include breakpoint($lg) {
    grid-template-columns: 1fr 1fr;
  }
}
.content-split-reverse {
  @include breakpoint($lg) {
    > :first-child  { grid-row: 1/2; grid-column: 2/3; }
    > :nth-child(2) { grid-row: 1/2; grid-column: 1/2; }
  }
}

// 50/50 bordered (also has extra padding due to border)
.content-split-bordered {
  > *,
  .content-split-section {
    padding: 1em;

    @include breakpoint($lg) {
      margin: 0;

      &:first-child { border-right: 1px solid $divider-color; }
      &:last-child  { border-left: 1px solid $divider-color; }

      // Even more padding left/right
      &.has-padding {
        padding-left: 8%;
        padding-right: 8%;
      }
    }
  }
}


// 3-column layout
.content-3col {
  @include breakpoint($lg) {
    > * {
        float: left;
        width: 33%;
        padding-right: 1rem;

        &:last-child { padding-right: 0; }
      }

      @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        align-items: start;

        // remove floats
        > * {
          float: none;
          width: auto;
          padding: 0;
        }
      }
  }
}


// Content and Image, with image bleeding off the side of the page
.content-image-bleed {
  padding-left: $gutter;
  padding-right: $gutter;

  .image img {
    display: block;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}
@include breakpoint($med-lg) {
  .content-image-bleed {
    padding-left: 0;
    padding-right: 0;
    display: grid;
    grid-template-columns: minmax(1rem,1fr) minmax(auto,28rem) minmax(auto,4rem) minmax(auto,28rem) minmax(1rem,1fr);;
    overflow: hidden;
    
    .image img { max-width: 125%; } // allow image to bleed
    .content, .image { grid-row: 1; }
    .content { grid-column: 2/4; }
    .image { grid-column: 4/6; padding-left: 1rem; }
  
    &.reverse {
      .image { grid-column: 1/3; padding-right: 1rem; padding-left: 0;}
      .content { grid-column: 3/5; }
      .image img { float: right; }
    }
  }
}
@include breakpoint($xl) {
  .content-image-bleed .image img { max-width: 800px; }
}

// Show text in two columns
// ----------------------------------------------------------------
@include breakpoint($med) {
  .text-2-col {
    columns: 2;
  }
}


.stripe-photo-aside {
  position: relative;
  overflow: hidden;

  .content {
    @include span(12);
    @include rhythm(1,0,0,1);

    @include breakpoint($lg) {
      @include span(5);
    }
  }

  .aside {
    @include breakpoint($lg) {
      @include span(last 50%);
      display: inline;
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      height: 450px;
    }

    img {
      width: 100%;
    }
  }
}



// Float an icon to the left of the text
.content-icon {
  @include breakpoint($lg) {
    .content-icon-icon {
      @include span(2);
      @include pre(1);
    }
    .content {
      @include span(last 7);
      @include post(2);

      > h1:first-child { @include rhythm(0,0,0,.5); }
    }
  }
}

// "sticky" content that should stick to the top when scrolled past
// ref. http://webdesign.tutsplus.com/tutorials/sticky-positioning-with-nothing-but-css--cms-24042
// uses Stickfill polyfill js in non-Safari browsers
.sticky {
  @include breakpoint($lg) {
    position: -webkit-sticky;  // required for Safari
    position: sticky;
    top: 0;
  }
}

// Item row
// items displaying horizontally centered
.item-row {
  display: flex;
  align-items: center;
  justify-content: var(--justify, center);
}


// Title on left then content
.l-title-content {
  display: flex;
  > :first-child { flex: 1 1 27%; margin-right: 2*$gutter; }
  > :last-child { flex: 1 1 70%; }

  @supports (display: grid) {
    display: grid;
    > :first-child { margin-right: 0; }
  }
  @include breakpoint($med) {
    grid-template-columns: 3fr 8fr;
    gap: 2*$gutter;
  }
}

// Left navigation (on topics pages)
@include breakpoint($med) {
  .l-subnav-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "subnav content";

    &.l-reverse {
      grid-template-columns: 1fr 3fr;
      grid-template-areas: "subnav content";
    }
    
    > .l-subnav {
      grid-area: subnav;
      margin-block-end: 1em;

      &.has-border {
        border-inline-end: 2px solid palette(grey, x-light);
        padding: 0 1em 0 0;
      }
    }
    > .l-content {
      grid-area: content;
      padding-inline: 2em;
      max-width: 76ch;
      margin: 0 auto;
    }
  }
}



// Pull left/right on large screens
@include breakpoint($lg) {
  .l-pull-left {
    position: relative;
    left: -2rem;
  }
  .l-pull-right {
    position: relative;
    right: -2rem;
  }
}


// Headers
// ----------------------------------------------------------------

$illus-header-bg-stripe-height: 450px;
$illus-header-bg-bp: 700px;
$illus-header-bg: #65bef6;

.illus-header {
  --bg-color: #{$illus-header-bg};

  // min-height: $illus-header-bg-stripe-height;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}

.illus-header.hide-image {
  min-height: 11rem;
  img { display: none; }
}

.illus-header-intro {
  position: relative;
  z-index: 10;
  margin-top: -11rem;

  > .box {
    padding-top: 3.5em;
    padding-bottom: 3.5em;
    margin: -4em auto 4em;

    @include breakpoint($lg) {
      padding-left: 5em;
      padding-right: 5em;
    }
  }
}


// Team Header
.illus-header-intro-team {
  margin-top: -11rem;
}