// $stack-space       : 1.5rem !default;
// $stack-tight-space : .75rem !default;
// $stack-loose-space : 2.5rem !default;
$stack-border-color: #dbe3e5 !default;
$stack-border-width: 1px !default;

// Vertically stacked elements with margin between each
.stack {
  --space: 1.5rem;
}
.stack-tight {
  --space: .75rem;
}
.stack-loose {
  --space: 2.5rem;
}
.stack-x-loose {
  --space: 5rem;
}
.stack-content-rows {
  --space: 5rem;
  margin-bottom: 5rem;
}

.stack > * {
  margin-top: 0;
  margin-bottom: 0;
} 

.stack > * + * {
  margin-top: var(--space);
}

// Recursive stacking applies margins between all descendants
.stack-recursive * + * {
  margin-top: var(--space);
}

// Bordered stack has divider lines between
.stack-bordered > * + * {
  padding-top: var(--space);
  border-top: $stack-border-width solid $stack-border-color;
}
.stack-bordered-thick > * + * {
  padding-top: var(--space);
  border-top: 3px solid $divider-color;
}