.featured-resources {
  
  .feature-box {
    padding: 1em;

    // When in feature-box, hide the images and summary
    .featured-content-image-wrapper,
    .featured-content-summary {
      display: none;
    }

    a {
      padding-top: .5em;
      padding-bottom: .5em;
    }
  }

  h2 {
    font-size: 1em;
    margin: 0;
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
    color: palette(grey, mid-light);
    text-align: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    border-bottom: 1px solid $divider-color;
  }

  .featured-content-image-wrapper {
    flex: 0 0 8em;    
    margin-right: 1em;
  }

  .featured-content-image {
    display: block;
    height: 0;
    padding-bottom: 63%;
    overflow: hidden;
    border-radius: 4px;
    background: #d0d0d0 url(/mgs_assets/images/v4/default-blog-image.png) 50% 50% / cover no-repeat;
  }

  a {
    display: block;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 1em .5em;


    &:hover {
      background-color: palette(blue, x-light);
    }
  }

  .getting-started-with {
    display: block;
    font-size: .75em;
    line-height: 1;
    color: palette(blue, light);
  }

  p {
    color: $base-font-color;
    line-height: 1.4;
    font-size: .8em;
  }
}