$link-color: blue !default;

@mixin link-colors($link,$hover) {
  color: $link;
  &:hover {
    color: $hover;
  }
}

@mixin unstyled-link-with-hover($underline: true, $color: inherit) {
  @include unstyled-link;
  color: $color;

  &:hover, &:focus {
    @if $underline {
      text-decoration: underline;
    }
    color: $link-color;
    cursor: pointer;
  }
}

@mixin unstyled-link {
  color: inherit;
  text-decoration: inherit;
  // cursor: inherit;
  border-bottom: none !important;
  &:active, &:focus {
    outline: none;
  }
}

.unstyled-link {
  @include unstyled-link;
}

.unstyled-link-with-hover {
  @include unstyled-link-with-hover;
}

.content-link,
.quiet-content-link {
  color: inherit;
  border-bottom: 1px solid palette(blue, light);
  text-decoration: none;

  &:hover {
    border-bottom-width: 2px;
  }
}

.quiet-content-link {
  color: $quiet-font-color;
  border-bottom-color: palette(blue, x-light);
}

.block-link {
  display: block;

  &:hover {
    background-color: palette(blue, x-light);
  }
}

.simple-link {
  @include unstyled-link-with-hover($color: $link-color);
}

.link-alt {
  @include link-colors($link-color-alt, darken($link-color-alt, 10));
}

.link-more {
  &:after {
    content: '\00BB';
    display: inline-block;
    margin-left: 0.25em;
  }
}

.element-cover-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tooltipster-base a {
  color: #fff;
}