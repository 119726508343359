@media print, pdf {

  // Overrides
  // -----------------------
  html,body {
    background: white;
  }

  header.site-header {
    display: none;
  }

  footer.site-footer {
    display: none;
  }

  nav.mm-menu {
    display: none;
  }

  nav.breadcrumbs {
    display: none;
  }

  // Blog Overrides
  // -----------------------
  .container {
    page-break-after: auto;
  }

  .related-entries, .entry-comments {
    display: none;
  }

  .post-footer-full {
    .footer-actions, .social-media, .about-avatar {
      display: none;
    }
  }
}