@use 'sass:math';

// Typography
// ----------------------------------------------------------------


// Base Type styles/sizes
// ----------------------------------------------------------------
:root {
  font-family: $base-font-family;
  line-height: math.div($base-line-height, $base-font-size);
  font-size: $base-font-size * .85;

  @include breakpoint($lg) {
    font-size: $base-font-size;
  }
}

// html {
//   font-size: $base-font-size;
//   line-height: $base-line-height;
// }

// body {
//   @include type-setting(0);
// }


// Links
// ----------------------------------------------------------------

@import "modules/links";
a {
  @include link-colors($link-color, darken($link-color, 10));
 transition: all .25s;
}

.offset-anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}


// Headings
// ----------------------------------------------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4 {
  font-weight: 400;
  color: $heading-color;
  font-family: $heading-font;
  text-wrap: balance;
}

h1, .h1 { @include type-setting(4); color: $heading-color-alt; font-weight: 100; }
h2, .h2 { @include type-setting(3); font-weight: 300; }
h3, .h3 { @include type-setting(2); font-weight: 300; }
h4, .h4 { @include type-setting(1); }
h5, .h5 { @include type-setting(1); font-weight: 600; color: $base-font-color; }

.h4 { font-weight: 600; }

h1.heading-alt,
.h1.heading-alt {
  color: $heading-color;
}

.section-heading,
.heading-primary,
.heading-primary-alt {
  text-align: center;
}

.heading-secondary,
.heading-secondary-alt {
  @include type-setting(3);
  text-align: center;
  font-weight: 300;
}

.heading-hl {
  color: $heading-color-hl !important;
}

.heading-tertiary {
  @include type-setting(0);
  color: palette(grey);
  text-transform: uppercase;
  font-weight: 400;
}

.heading-hfeed {
  @include type-setting(3);
  text-align: center;
  font-weight: 300;
  @include rhythm(1,0,0,1)
}

.heading-upper {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: .05ch;
  color: palette(teal);
}

.entry-heading,
.entry-heading-med {
  text-align: center;
  @include trailer(.25);
}

.tag-heading {
  @include rhythm(0,0,0,.5);
}

:is(h1, h2, h3, h4, h5, h6).plain {
  color: $base-font-color;
}

:is(h1,h2,h3).bordered {
  color: $base-font-color;
  margin-bottom: .65em;
  line-height: 1.2;

  &::after {
    content: '';
    height: 2px;
    background-color: palette(blue, light);
    width: 2.25em;
    display: block;
    margin: .35em 0;
  }

  &.text-center::after {
    margin-inline: auto;
  }

  .text-invert &:after {
    // background-color: rgba(255,255,255,.4);
    background-color: palette(blue, light);
  }
}
h3.bordered::after {
  margin-block: .5em;
}

// Appears before a heading
.kicker {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  font-size: .8rem;
  line-height: 1.1;
}

h1 .kicker {
  font-size: .4em
}

// Container for main page title, make top margin slightly less
.breadcrumbs + .page-title-container h1 {
  margin-top: .35em;
}
.page-title-container + .stripe-content {
  padding-top: 0;
}

// Larger, in Dosis
.course-title {
  font-family: 'Dosis', $sans-font;
  font-size: 3rem;

  &.bordered {
    margin-bottom: 0;
    &:after {
      // background-color: palette(blue, light);
      margin-left: 0;
    }
  }
}


// Body Text
// ----------------------------------------------------------------

body {
  color: $base-font-color;
  font-family: $base-font-family; 
}

p {
  margin: 0;
}
p + p {
  @include leader;
}

.text-large {
  @include type-setting(1);
  font-weight: 300;
}
.text-x-large {
  @include type-setting(2);
}
.text-med {
  @include type-setting(0);
}
.text-med-small {
  font-size: 0.9em;
}
.text-small {
  @include type-setting(-1);
}


hr {
  border: none;
  background: $divider-color;
  height: 3px;
  width: 100%;
  margin-top: $base-line-height - 2;
  margin-bottom: $base-line-height - 2;
  position: relative;
  top: -1px;
  clear: both;

  &.tiny {
    height: 2px;
    background-color: palette(blue, light);
    width: 5.5rem;
    display: block;
    margin: 2em auto;
  }

  &.short {
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
  }

  &.thick {
    height: 2px;
    background-color: $divider-color-alt;
  }

  &.mountains {
    background-color: transparent;
    background-image: url(/mgs_assets/images/v4/mountains.svg);
    background-position: 100% 100%;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom: 3px solid $divider-color;
    height: auto;
    max-height: 112px;
    aspect-ratio: 1170 / 112;
    margin-top: 0;
  }
}

em.alt {
  font-style: normal;
  color: palette(blue, light);
}

.dot {
  &:after {
    content: '\2022';
    display: inline-block;
    margin: 0 .75em;
    font-size: .8em;
  }
}

// use this class when showing text on a dark background
.text-invert {
  color: white;

  // h1, h2, h3, h4, h5, h6 { color: palette(blue,light); }
  h1, h2, h3, h4, h5, h6 { color: white; }
  a { color: white; }

  // When inside a panel, revert back to standard colors
  // .panel {
  //   a {
  //     @include link-colors($link-color, darken($link-color, 10));
  //   }
  //   h1, h2, h3, h4, h5, h6 {
  //     color: $heading-color;
  //   }
  // }

  table a:hover {
    color: white !important;
  }

  td {
    border-color: palette(blue, light);
  }
}


// Tables
// ----------------------------------------------------------------
@import "modules/tables";


// Lists
// ----------------------------------------------------------------
@import "modules/lists";

ul.unstyled,
li.unstyled {
  @include unstyled-list;
}


// Images
// ----------------------------------------------------------------

img {
  max-width: 100%;
  height: auto !important;

  &.right {
    float: right;
    margin: 0 0 1em 1em;
  }

  &.left {
    float: left;
    margin: 0 1em 1em 0;
  }

  &.center {
    display: block;
    margin: 0 auto;
  }

  &.pull-right {
    float: right;
    margin: 0 0 1em 1em;
    @include breakpoint($lg) {
      margin-right: -6em;
    }
  }

  &.pull-left {
    float: left;
    margin: 0 1em 1em 0;
    @include breakpoint($lg) {
      margin-left: -6em;
    }
  }

  &.border {
    border: 1px solid #ccc;
  }
}
a img {
  display: block;
}

figure {
  margin: 1em 0;
  
  img {
    display: block;
  }
}
figcaption {
  display: block;
  text-align: center;
  font-style: italic;
  font-size: 0.85em;
  opacity: .75;
  margin-top: 0.5em;
}

.has-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}

.has-deep-shadow {
  box-shadow: 0 5px 17px rgba(0,0,0,.2);
}

.center-bgrect {
  width: 100%; // this is required for a .media-feature > .media-item
}
.img-bgrect {
  // height: 150px;
  aspect-ratio: 125 / 52;
  position: relative;

  > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 360px;
    z-index: 1;
  }

  @include breakpoint($lg) {
    &::before {
      content: '';
      width: 100%;
      border-radius: .7em;
      background-color: #d5efff;
      z-index: 0;
      position: absolute;
      top: 38.6666667%;
      left: 0;
      aspect-ratio: 26/5;
    }
  }

  & + * {
    margin-top: 0;
  }

  & + h4 {
    font-size: 1.1em;
    font-weight: bold;
    color: $base-font-color;
    margin: 0;
  }
}
.img-bgrect-large {
  --bg-color: #d5efff;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    border-radius: .7em;
    background-color: var(--bg-color);
  }

  > img {
    position: relative;
    width: 70%;
  }
}


.avatar-circle {
  --avatar-size: 8rem;

  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > img {
    display: block;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  &-centered {
    display: block;
    margin: 0 auto;
  }
}
.avatar-lg {
  --avatar-size: 12rem;
}
.avatar-circle .video-button {
  appearance: none;
  border: none;
  padding: .5em .5em 1.75em;
  text-align: center;
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  font-size: .65em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5em;
  transition: all .2s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: rgba(0, 101, 87, .8);
    padding-block-end: 2em;
    font-size: .75em;
  }
}



// Misc
// ----------------------------------------------------------------
address {
  font-style: normal;
}

abbr {
  border: none;
}

mark {
  background-color: #fffccd;
  display: inline-block;
  padding: 0 .125em;
}

.text-quiet,
.quiet {
  // color: $quiet-font-color;
  opacity: 0.6;
}

// Blockquote inside .entry-content
.entry-content blockquote {
  border-left: 3px solid palette(blue, x-light);
  color: #3b76b3;
  margin-left: 1.15em;
  padding-left: 1.15em;

  &.handwriting {
    font-size: 1.3em;
  }
}

blockquote.fancy {
  position: relative;
  padding-left: 2.5em;
  margin-left: 0;
  color: #3b76b3;
  border: none;

  &:before {
    content: '\201C';
    position: absolute;
    font-size: 4.5em;
    top: .25em;
    left: 0;
    color: #c1e0f3;
    font-family: Arial, sans-serif;
    font-weight: bold;
  }
}



// Duplicate the blog aside to be used outside of blogs and articles
.aside {
  @include rhythm(1, .5, .5, 1);
  background-color: palette(blue, xx-light);
  color: #68757d;
  font-size: .9em;
  line-height: 1.4;
  padding: 1em;

  @include breakpoint($lg) {
    width: 36%;
    float: right;
    border-left: 2px solid $divider-color;
    margin-top: 0;
    margin-right: -23%;
    margin-left: 1rem;
    font-size: .85em;
  }

  > :first-child { margin-top: 0; }
}

.quiet-color {
  color: $quiet-font-color;
}

.handwriting {
  font-family: 'Kalam', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  letter-spacing: -0.05em;
  vertical-align: middle;
}
em.handwriting {
  color: palette(teal);
  font-weight: 300;
}
.handwriting-blue,
em.handwriting-blue {
  color: palette(blue, light);
}

.hidden {
  display: none !important;
}
.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0); /* Legacy property for Internet Explorer */
  clip-path: inset(50%);
  white-space: nowrap;
}

.highlighter {
  // background-color: #fefeb4;
  background-color: #fff7d6;
  font-style: normal;
  display: inline-block;
  padding-left: .25em;
  padding-right: .25em;
}

.text-center         { text-align: center; }
.text-right          { text-align: right; }
.text-left           { text-align: left !important; }

.text-upper          { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

.no-margin           { margin: 0 !important; }
.no-trailer          { margin-bottom: 0 !important; }
.no-padding-trailer  { padding-bottom: 0 !important; }
.no-leader           { margin-top: 0 !important; }
.no-padding-leader   { padding-top: 0 !important; }

.no-border           { border: none !important; }

.has-leader          { @include leader; }
.has-trailer         { @include trailer; }
.has-leader-wide     { @include leader(2); }
.has-trailer-wide    { @include trailer(2); }
.has-leader-thin     { @include leader(.5); }
.has-trailer-thin    { @include trailer(.5); }
.has-padding-leader  { @include padding-leader; }
.has-padding-trailer { @include padding-trailer; }

.position-up         { position: relative; top: -1.5rem; }

.has-h-padding       {
  display: inline-block;
  padding-left: 1em;
  padding-right: 1em;
}

.font-light { font-weight: 300; }

.icon-hidetext { @include hide-text; }

.hide-mobile {
  @include breakpoint(max-width $med) {
    display: none;
  }
}
.hide-desktop {
  @include breakpoint(min-width $med) {
    display: none;
  }
}

