@mixin inline-list {
  list-style-type: none;
  &, & li {
    margin: 0;
    padding: 0;
    display: inline;
  }
}

@mixin delimited-list($separator: ", ") {
  @include inline-list;
  li:not(:last-child)::after {
    content: $separator;
  }
}

@mixin comma-separated-list() {
  @include delimited-list(', ');
  li { background: none; }
}
.comma-separated-list {
  @include comma-separated-list();
}

@mixin unstyled-list() {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
    margin-left: 0;
    padding-left: 0;
    background: none;
  }
}
.unstyled-list { 
  @include unstyled-list();
}

@mixin horizontal-list($padding: 0) {
  @include pie-clearfix;
  li {
    list-style: none;
    @if $padding {
      padding: 0 $padding;
    }

    &:first-child {
      padding-left: 0;
    }
    
    &:last-child {
      padding-right: 0;
    }
  }
}

@mixin inline-block-list($padding: false) {
  margin: 0;
  padding: 0;
  overflow: hidden;

  li {
    display: inline-block;
    list-style: none;
    margin-left: 0;
    white-space: nowrap;
  }
}


// Content List -- has custom bullets
// ----------------------------------------------------------------
.content-list {
  list-style: none;

  li {
    padding-left: 1.25em;
    position: relative;

    &:before {
      @include type-setting(-2);
      content: '\2022';
      color: palette(teal);
      display: inline-block;
      position: absolute;
      left: 0;
    }
  }
}


// View more list -- has custom arrow bullets
// ----------------------------------------------------------------
.view-more-list {
  list-style: none;
  padding-left: 0;

  > li {
    padding-left: 1.25em;
    background: transparent url('/mgs_assets/images/v4/svg-icons-source/next.svg') .15em .3em no-repeat;
    position: relative;
  }

  a {
    @include unstyled-link-with-hover($color: $link-color);
  }
}


.pipe-nav-list {
  list-style: none;
  padding-left: 0;
  display: flex;

  > li + li {
    border-left: 1px solid rgba(0,0,0,.15);
  }
  a {
    margin-inline: 1em;
    display: block;
  }
  li:first-child a {
    margin-inline-start: 0;
  }
}


.plain {
  @include unstyled-list;
  li {
    line-height: 2;
  }
}


// Icon-Lists
// ----------------------------------------------------------------
.checklist,
.checklist > ul {
  margin-left: 0;
  padding-left: 0;

  li {
    @include trailer(.25);
    background: url('/mgs_assets/images/v4/svg-icons-source/check.svg') no-repeat;
    list-style-type: none;
    padding-left: 30px;
    background-position: 0 2px;
  }
}

.checkbox-list,
.checkbox-list > ul {
  margin-left: 0;
  padding-left: 0;

  li {
    background: url('/mgs_assets/images/v4/svg-icons-source/bullet-checkbox.svg') no-repeat;
    list-style-type: none;
    padding-left: 30px;
    background-position: 0 4px;
  }
}

.question-list,
.question-list > ul {
  margin-left: 0;
  padding-left: 0;

  li {
    background: url('/mgs_assets/images/v4/svg-icons-source/bullet-question.svg') no-repeat;
    list-style-type: none;
    padding-left: 30px;
    background-position: 0 4px;
  }
}



// Multi-columns
// ----------------------------------------------------------------
.two-col,
.three-col,
.four-col {
  padding-left: 0;

  @include breakpoint($med) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 1rem;
  }
}

@include breakpoint($med) {
  .three-col { grid-template-columns: repeat(3, 1fr); }
  .four-col  { grid-template-columns: repeat(4, 1fr); }
}


// "Fancy" ordered list (numbers in circles)
// ----------------------------------------------------------------
ol.fancy {
  list-style: none;
  padding-left: 0;
  counter-reset: fancy-numbers;

  > li {
    padding-left: 2em;
    position: relative;
    margin-bottom: 1em;

    &:before {
      content: counter(fancy-numbers);
      counter-increment: fancy-numbers;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fff;
      color: palette(teal);
      font-weight: bold;
      text-align: center;
      font-size: 0.9em;
      border: 1px solid palette(grey, x-light);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5em;
      height: 1.5em;
    }
  }
}


// Icon List
// ----------------------------------------------------------------
// Has SVG icons within LI
.icon-list {
  list-style: none;
  padding-left: 0;

  li {
    padding-left: 1.75em;
    margin-bottom: 1em;
  }

  .icon {
    margin-left: -1.4em;
    margin-right: .25em;
  }
}


// Vertical Bullets
// ----------------------------------------------------------------
.bullets-vertical ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.bullets-vertical li {
  margin: 1em 0;
  display: block;
}
.bullets-vertical li + li::before {
  content: '•';
  display: block;
  color: palette(blue, light);
  margin-bottom: 1em;
}