// Intro Animations
// add the "go" class to an introanim- to remove the transformations and trigger the animation

.anim-container {
  overflow-x: hidden;
}

@include breakpoint($med-lg) {

  *[class*="introanim"] {
    transition: all .75s ease-in-out;
    &.go {
      transform: none;
      opacity: 1;
    }
  }

  .introanim-l {
    transform: translateX(10rem);
    opacity: .1;
  }
  .introanim-r {
    transform: translateX(-10rem);
    opacity: .1;
  }
  .introanim-up {
    transform: translateY(6.5rem);
    transition-duration: .5s;
    transition-timing-function: ease-out;
    opacity: .1;
  }

  .introanim-cascade {
    @for $i from 1 through 10 {
      > *:nth-child(#{$i}) { transition-delay: ($i - 1) * .075s}
    }
  
  }

}