// Testimonials
// ----------------------------------------------------------------

$single-quote-bg: palette(blue, xx-light) !default;

.testimonial {

  blockquote {
    font-style: italic;
    margin: 0;

    > p {
      &:before {
        content: '\201C';
      }
      &:after {
        content: '\201D';
      }
    }

    cite {
      display: block;
      font-style: normal;
      font-weight: 600;
      margin-top: 1em;
      font-size: 0.9em;
    }
    small {
      display: block;
      font-weight: 400;
      // color: $base-font-color;
      opacity: .6;
    }
  }
  
  .testimonial-avatar + blockquote {
    margin-top: 1rem;
  }
}

.testimonial-avatar {
  --avatar-size: 4.25rem;
}

.testimonial-wide {
  @include breakpoint($lg) {
    padding-left: 90px;
    padding-right: 20px;

    .testimonial-avatar {
      float: left;
      margin-left: -90px;
    }
  }
  
  blockquote {
    p:first-child {
      margin-top: 0;
    }
    cite {
      text-align: left;
    }
  }
}


.testimonial-large blockquote {
  @include type-setting(0);
}

blockquote.single {
  @include type-setting(-1);
  border-radius: $default-border-radius;
  background: $single-quote-bg url(/mgs_assets/images/v3/quote-bubble.png) -10px -10px no-repeat;
  padding: $base-line-height 20px $base-line-height 80px;

  p {
    font-style: italic;
  }

  .testimonial-source {
    display: block;
    font-style: normal;
  }
}

.bq-xl,
.bq-lg,
.bq-xxl {
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 300;
  margin: 0 0 1em;
  color: palette(blue, x-dark);
  font-style: italic;

  cite {
    display: block;
    font-size: 1.15rem;
  }
}

.bq-xl {
  font-size: 1.8rem;
}

.bq-xxl {
  color: palette(blue, light);
  font-size: 2.4rem;
  line-height: 1.2;
}

// Landing page testimonials
.testimonial-style1 {
  --tst-1-border-radius: 1.5em;

  @include breakpoint($med-lg) {
    display: grid;
    grid-template-columns: minmax(240px,4fr) 1fr 7fr 1fr minmax(240px,4fr);
    grid-template-rows: 2em 1fr 2em;
  }

  .content {
    border-radius: var(--tst-1-border-radius);
    background-image: url("data:image/svg+xml,%3Csvg width='78' height='68' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M53.625 68c4.367 0 8.43-1.088 12.188-3.263 3.757-2.176 6.728-5.136 8.912-8.88C76.908 52.113 78 48.065 78 43.714V7.286c0-2.024-.71-3.744-2.133-5.161C74.445.708 72.72 0 70.687 0h-19.5c-2.03 0-3.757.708-5.18 2.125-1.421 1.417-2.132 3.137-2.132 5.16v19.43c0 2.023.71 3.743 2.133 5.16C47.43 33.292 49.156 34 51.188 34h12.187v9.714c0 2.631-.965 4.908-2.895 6.83-1.93 1.923-4.214 2.885-6.855 2.885h-1.219c-1.015 0-1.879.354-2.59 1.062-.71.708-1.066 1.569-1.066 2.58v7.286c0 1.012.355 1.872 1.066 2.58.711.709 1.575 1.063 2.59 1.063h1.219zM9.75 68c4.367 0 8.43-1.088 12.188-3.263 3.757-2.176 6.728-5.136 8.912-8.88 2.183-3.744 3.275-7.792 3.275-12.143V7.286c0-2.024-.71-3.744-2.133-5.161C30.57.708 28.844 0 26.812 0h-19.5c-2.03 0-3.757.708-5.18 2.125C.712 3.542 0 5.262 0 7.285v19.43c0 2.023.71 3.743 2.133 5.16C3.555 33.292 5.28 34 7.313 34H19.5v9.714c0 2.631-.965 4.908-2.895 6.83-1.93 1.923-4.214 2.885-6.855 2.885H8.531c-1.015 0-1.879.354-2.59 1.062-.71.708-1.066 1.569-1.066 2.58v7.286c0 1.012.355 1.872 1.066 2.58.711.709 1.575 1.063 2.59 1.063H9.75z' fill='%23EEF9FF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 92.5% 1em;
    background-color: palette(blue, x-light);
    padding: 2em 3em;
    grid-row: 2/3;
    margin: 0;
    width: 100%;
    display: flex;
    align-content: center;

    cite { display: block; }
  }
  .image {
    background-image: url("data:image/svg+xml,%3Csvg width='400' height='414' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M313.523 49.575c-11.035 0-19.98-8.973-19.98-20.04V20.04C293.543 8.973 284.6 0 273.566 0H55.45c-11.035 0-19.98 8.973-19.98 20.04V110.2c0 11.07-8.945 20.04-19.978 20.04H12.87C5.76 130.24 0 136.022 0 143.152v198.78c0 7.132 5.76 12.913 12.87 12.913h66.195c11.033 0 19.977 8.973 19.977 20.04v18.57c0 11.07 8.946 20.044 19.98 20.044h207.6c11.034 0 19.978-8.974 19.978-20.044v-52.406c0-11.068 8.944-20.04 19.98-20.04h19.813c7.108 0 12.87-5.78 12.87-12.909V62.486c0-7.13-5.762-12.91-12.87-12.91h-72.87z' fill='%2365BEF6' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: .75em;
    grid-row: 1/4;
    z-index: 10;
    display: flex;
    align-items: center;

    @include breakpoint(max-width $med-lg) {
      max-width: 35%;
      height: auto !important;
      margin: 0 auto -1.5em;
    }
   
    > img {
      border-radius: var(--tst-1-border-radius);
      box-shadow: 0 16px 26px rgba(0,0,0,.5);
      object-fit: cover;
      height: 100% !important;
      max-height: 400px;
    }
  }
  blockquote {
    margin: auto;
  }

  &.image-left {
    .content {
      grid-column: 2/6;
      padding-left: 12%;
    }
    .image {
      grid-column: 1/3;
    }
  }
  &.image-right {
    .content {
      grid-column: 1/5;
      padding-right: 12%;
    }
    .image {
      grid-column: 4/6;
    }
  }
}


// Testimonial page style
.testimonial-simple {
  .avatar-circle {
    max-width: 3.5em;
    aspect-ratio: 1;
    object-fit: cover;
  }

  cite {
    font-style: normal;
  }

  .media {
    gap: .75em;
  }
  .media > * { flex: 1; }

  .text-quiet {
    font-weight: normal;
  }

  blockquote {
    font-style: normal;

    > p::before,
    > p::after { content: '' }
  }
}