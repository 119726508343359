@use 'sass:math';

// Mixins
// ----------------------------------------------------------------

@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels,$context) * 1em;
}

@mixin leader($lines: 1, $context: $base-font-size) {
  margin-top: em($lines * $base-line-height, $context);
}

@mixin padding-leader($lines: 1, $context: $base-font-size) {
  padding-top: em($lines * $base-line-height, $context);
}

@mixin trailer($lines: 1, $context: $base-font-size) {
  margin-bottom: em($lines * $base-line-height, $context);
}

@mixin padding-trailer($lines: 1, $context: $base-font-size) {
  padding-bottom: em($lines * $base-line-height, $context);
}

@mixin rhythm($leader: 0, $padding-leader: 0, $padding-trailer: 0, $trailer: 0, $context: $base-font-size) {
  @include leader($leader, $context);
  @include padding-leader($padding-leader, $context);
  @include padding-trailer($padding-trailer, $context);
  @include trailer($trailer, $context);
}

@mixin clearfix {
  overflow: hidden;
}

@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  *zoom: 1;
}

@mixin vector-bg-with-fallback($name, $path: '/mgs_assets/images/v3/', $replace-text: false) {
  background-image: url('#{$path}#{$name}.png');
  background-image: none, url('#{$path}#{$name}.svg');
  background-repeat: no-repeat;

  @if ($replace-text) {
    @include hide-text;
    display: block;
    width: image-width('v3/#{$name}.png');
    height: image-height('v3/#{$name}.png');
  }
}

@mixin doubleborder($side, $innerColor, $outerColor) {
  $shadow: "0 0 0";
  border-#{$side}: 1px solid $innerColor;
  @if ($side == "top") { $shadow: 0 -1px 0; }
  @if ($side == "right") { $shadow: 1px 0 0; }
  @if ($side == "bottom") { $shadow: 0 1px 0; }
  @if ($side == "left") { $shadow: -1px 0 0; }
  -webkit-box-shadow: $shadow $outerColor;
  -moz-box-shadow: $shadow $outerColor;
  -o-box-shadow: $shadow $outerColor;
  box-shadow: $shadow $outerColor;
}


@mixin hide-text {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
}


@mixin block-icon($size) {
  display: block;
  width: $size;
  height: $size;
}

@mixin overflow-ellipsis($width: 100%) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin deep-shadow-box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 17px rgba(0,0,0,.2);
}


@mixin stripe-dark {
  color: white;

  h1, h2, h3, h4, h5, h6,
  a {
    color: white;
  }

  table a:hover {
    color: white !important;
  }

  td {
    border-color: palette(blue, light);
  }
}