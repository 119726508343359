// Panels
// ----------------------------------------------------------------

$panel-bg: white !default;
$panel-border-radius: 4px !default;
$panel-border-color: palette(blue, x-light) !default;
$panel-border-color-alt: palette(grey, x-light) !default;
$panel-border-color-highlight: palette(teal) !default;
$panel-padding: $base-line-height * .5 !default;

$panel-photo-header-height: 13em;

.panel {
  @include type-setting(-1);
  @include trailer;
  border: 1px solid $panel-border-color;
  border-radius: $panel-border-radius;
  text-align: center;
  background: $panel-bg;
  position: relative;
  color: $base-font-color;
  display: flex;
  flex-direction: column;

  &-highlight {
    border-color: $panel-border-color-highlight;
  }

  &-ghost {
    background: rgba(255, 255, 255, .09);
  }

  &-shadow {
    box-shadow: 0 5px 17px rgba(0,0,0,.2);
  }

  // If the entire panel is contained within a link, don't format as a link
  > a {
    // display: block;
    text-decoration: none;
    color: $base-font-color;

    &:hover {
      color: $base-font-color;
    }
  }

  .course-type-icons {
    position: absolute;
    top: $panel-padding;
    left: $panel-padding;
    
    i {
      vertical-align: middle;
      margin-right: .25em;
    }
  }
}

.panel-header {
  background-color: palette(blue, xx-light);
  border-top: 1px solid $panel-border-color;
  border-bottom: 1px solid $panel-border-color;
  border-top-left-radius: $panel-border-radius;
  border-top-right-radius: $panel-border-radius;
  position: relative;
  overflow: hidden;

  &.no-padding {
    padding: 0;
  }

  &:first-child {
    border-top: none;
  }
}

.panel-header,
.panel-body,
.panel-footer {
  padding: $panel-padding;
}

.panel-overlap-bottom-image {
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}


// Panel Headers
// used for blog posts, reviews, presentations to ensure that the images in the headers all fit
// and the panels remain the same size
// ----------------------------------------------------------------
.panel-photo-header {
  // max-height: $panel-photo-header-height;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.panel-asset-header {
  background-size: cover;

  img {
    display: block;
    margin: 10px auto 0;
    border: 2px solid rgba(0,0,0,.5);
  }
}

.panel-blog-header img,
.panel-articles-header img {
  margin: 0;
  border: none;
}


.panel-presentation-header {
  background: #00c3a9 url(/mgs_assets/images/v4/pages/blog/presentation-bg.png) 0 0 / cover no-repeat;

  img {
    max-width: 75%;
  }
}

.panel-review-header {
  background: #65bef6 url(/mgs_assets/images/v4/pages/blog/review-bg.png) 0 0 / cover no-repeat;

  img {
    max-width: 60%;
  }
}


// Panel Body / Footer
// ----------------------------------------------------------------

.panel-body {
  text-align: left;
}

.panel-footer {
  margin-top: auto;
}

.panel-footer > p:last-child {
  margin-bottom: $panel-padding;
}


// Large Panels (usually full width, has greater font size and padding)
// ----------------------------------------------------------------
.panel-large {
  .panel-body {
    @include type-setting(0);
    padding: $panel-padding * 2;
  }

  // with content/sidebars
  .cs-content { padding-right: 0; }
  .cs-sidebar { padding-left: 0; }
}


// Hover Panel
// light border, but "hovers" up a little bit on mouse-over
// ----------------------------------------------------------------

.hover-panel {
  border-color: $panel-border-color-alt;
  transition: all .25s;
  background-color: #fcfcfc;
  
  &:hover {
    border-color: darken($panel-border-color-alt, 15);
    box-shadow: rgba(0,0,0,.15) 0 .25em 6px;
    transform: translateY(-.25em);
    background-color: #fff;
  }

  .block-link:hover {
    background-color: transparent;
    color: inherit;
  }
}


// Article Content Panels
// ----------------------------------------------------------------
// Used for blog posts, presentations, etc.

.content-article-panel {

  .panel-header {
    border-radius: $panel-border-radius $panel-border-radius 0 0;
    margin: -1px; // pull the header out to cover border
    aspect-ratio: 1.911;
  }

  .heading-upper {
    font-size: 1.1em;
  }

  .panel-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    > h3 {
      @include rhythm(0, 0, 0, .2);
      @include type-setting(1);
      line-height: 1.3;
    }
  }

  .post-teaser {
    margin-bottom: 1em;
  }

  .panel-footer {
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .post-metadata {
    padding: .25em 0;
  }

  &.no-footer .panel-footer {
    display: none;
  }
}

// Horizontal content-article-panels
.panel-horizontal {
  flex-direction: row;
  align-items: stretch;

  .panel-header {
    flex: 1;
    aspect-ratio: initial;
    border-radius: 4px 0 0 4px;
    border: none;
  }
  .panel-body {
    flex: 2;
  }
  .panel-photo-header {
    max-height: none;
  }
  .panel-asset-header {
    height: 100%;
  }
  .panel-blog-header img,
  .panel-article-header img {
    height: 100% !important;
    object-fit: cover;
  }
}



// Email Tips Panel
// ----------------------------------------------------------------
.email-panel {
  background: palette(blue);
  color: white;
  padding: 2.5em;
  align-items: center;
  border: none;

  h3 {
    color: inherit;
    margin: .25em 0;
  }

  .icon-mgs-newspaper {
    margin: -1em auto 0;
  }
}

// Article Featured Panel
// ----------------------------------------------------------------

.content-article-panel-featured {
  margin-bottom: 0;

  @include breakpoint($med-lg) {
    flex-direction: row;

    .panel-header {
      flex: 0 1 60%;
      border-radius: 4px 0 0 4px;
    }
    .panel-body {
      flex: 0 1 40%;
      align-self: center;
      padding: 1.5em;
    }
  }

  .panel-photo-header {
    max-height: none;
  }

  .panel-body {
    h3 {
      @include breakpoint($lg) {
        font-size: 2.125em;
        line-height: 1.25;
        margin-top: -.5em;
      }
    }
    .article-summary {
      display: none;

      @include breakpoint($lg) {
        display: block;
        font-size: 1.3em;
        line-height: 1.5;
        color: #666;
      }
    }
    .article-teaser {
      @include breakpoint($lg) {
        display: none;
      }
    }
  }
}


// Content-Topic Panels (full-image background)
// ----------------------------------------------------------------

.content-topic-panel {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 15em;

  .panel-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .panel-content,
  .panel-footer {
    position: relative;
    color: #fff;
  }

  .panel-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      color: inherit;
    }
  }
}


// Carousel content panels
// no way to ensure same height (due to js-injected HTML)
// so, give mandatory minimum height to try and approx equal height
// ----------------------------------------------------------------
.carousel-item {
  .content-article-panel,
  .content-topic-panel {
    min-height: 26em;
  }
}


// Expanding Panels
// ----------------------------------------------------------------


// Start-here Expanding Panels
// specific styles for panels on start here page
// ----------------------------------------------------------------
.expanding-grid {
  @include breakpoint($lg) {
    display: flex;
    align-items: flex-start;

    > * {
      flex: 1;
    }
    > * + * {
      margin-left: 1rem;
    }
    > *.expanded {
      flex-grow: 2;
    }
  }
}
.start-here-expanding-panel {
  margin-bottom: 4em;
  
  &:not(.expanded) {
    cursor: pointer;
  }
  
  h2 {
    margin: .25em 0;
    font-size: 1.9em;
    color: $base-font-color;
  }

  .pre-heading {
    color: palette(teal);
  }

  .panel-body {
    padding-bottom: 6.5em;
    z-index: 200;
  }

  &.expanded .collapse {
    display: block;
  }

  &.expanded .panel-overlap-bottom-image {
    bottom: -3rem;
  }

  .media-item {
    max-width: 20%
  }

  h3 {
    margin: 0;
  }

  .link-more {
    color: palette(blue, light);
    &:hover {
      color: palette(blue);
    }
  }

  // reduce stack padding
  .stack-bordered > * + * {
    margin-top: .5em;
    padding-top: .5em;
  }
}


// Home Panels
// minor style tweaks for panels on the homepage
// if used elsewhere, make these more generic
// ----------------------------------------------------------------
.home-panels {
  margin-top: 1.5em;
  position: relative;
  top: -1.5em;

  .panel-header h3 {
    color: $base-font-color;
  }
  .panel-header .kicker {
    color: palette(teal);
  }
  .panel-body > img {
    margin: -2em auto 0;
    position: relative;
  }
  .panel-footer .button {
    font-size: 0.85rem;
  }
}