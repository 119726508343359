// Carousel
// ----------------------------------------------------------------
.carousel-container {
  @include rhythm(1,0,0,1);
  position: relative;
  
  @include breakpoint($lg) {
    @include rhythm(2,0,0,2);
  }
}

// Slick Carousel Slides
// ----------------------------------------------------------------
// Ensure space in between slides
.slick-slide {
  padding-left: .5rem;
  padding-right: .5rem;
}

// Slick Carousel Dots
// ----------------------------------------------------------------
.slick-dots {
  bottom: auto;
}
.slick-dots li button::before {
  background: palette(blue, dark);
  border-radius: 50%;
  width: .25rem;
  height: .25rem;
  content: '';
}

@include breakpoint($lg) {
  .slick-dots {
    bottom: -30px;
  }
  .slick-dots li button::before {
    width: .5rem;
    height: .5rem;
  }
}


// Arrows
// ----------------------------------------------------------------

.slick-prev,
.slick-next,
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  background: transparent url('/mgs_assets/images/v4/carousel-arrow.svg') 0 0 no-repeat;
  @include hide-text;
  width: 48px;
  height: 82px;
  transition: opacity .25s;
  opacity: .15;
}
.slick-prev {
  left: -40px;
}
.slick-next {
  transform: translateY(-50%) rotate(180deg);
  left: auto;
  right: -40px;
}
.slick-prev:hover,
.slick-next:hover {
  opacity: 0.3;
}


// Clients Carousel
// ----------------------------------------------------------------

.clients-carousel {
  .client-logo { display: block; padding: 0 1rem; }
  .client-logo img { display: block; }

  @include breakpoint($lg) {
    .slick-slide {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

// Testimonial Tab Nav Carousel
// ----------------------------------------------------------------

.testimonial-tab-nav-carousel {
  .testimonial-avatar {
    margin: 2rem auto;
    border: 3px solid transparent;
    transition: all .25s;
    transform: scale(1);
  }

  .slick-current .testimonial-avatar {
    transform: scale(1.2);
    box-shadow: 0 2px 5px rgba(0,0,0,.25);
  }

  .slick-current .testimonial-avatar,
  .slick-active:active .testimonial-avatar {
    border-color: white;
  }

  .slick-active:active {
    outline: none;
  }
}