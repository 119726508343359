// Collapsing/expanding content
// ----------------------------------------------------------------

.collapse:not(.show) {
  display: none;
}
.collapse.in {
  display: block;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
}