$gutter: 1rem !default;

// Box grids
// ----------------------------------------------------------------

$grid-item-width       : 300px; // collapses to 1 per row on mobile
$grid-small-item-width : 150px; // collapses to 2 per row on mobile
$grid-med-item-width   : 215px; // collapses to 1 per row on mobile, up to 5 across on desktop
$grid-large-item-width : 340px; // 1 per row on mobile, 2 per row on desktop



// Flexible grid allows for varying number of columns
// min grid item width: 300px, collapses to 1 per row on mobile
.grid {
  --grid-item-width: #{$grid-item-width};
  --gap: #{$gutter};
  
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));

  > * {
    height: 100%; // ensure all sub-items fill grid space
  }
}

.grid-small { --grid-item-width: #{$grid-small-item-width}; }
.grid-med   { --grid-item-width: #{$grid-med-item-width}; }
.grid-large { --grid-item-width: #{$grid-large-item-width}; }

// Grids with fixed number of items
.grid-2up,
.grid-3up,
.grid-4up,
.grid-5up,
.grid-6up {
  display: grid;
  gap: $gutter;
}

.grid-item {
  height: 100%;
  display: inline-block; // fallback, ignored if grid supported
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;

  > .panel {
    height: 100%;
  }
}



@include breakpoint($lg) {
  .grid-2up { grid-template-columns: repeat(2, 1fr); }
  .grid-3up { grid-template-columns: repeat(3, 1fr); }
  .grid-4up { grid-template-columns: repeat(4, 1fr); }
  .grid-5up { grid-template-columns: repeat(5, 1fr); }
  .grid-6up { grid-template-columns: repeat(6, 1fr); }
}

@include breakpoint($med) {
  .grid-2up.grid-inner-borders {
    grid-gap: 0;

    > *,
    .grid-item {
      border-right: 2px solid #dedede;
      border-top: 2px solid #dedede;
      padding: 2rem;

      &:nth-child(2n) { border-right: none; }
      &:nth-child(1),
      &:nth-child(2) { border-top: none; }
    }
  }
}


// Build using flexbox to allow growing items

:is(.flexgrid-3up, .flexgrid-4up, .flexgrid-5up) {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $gutter;

  > * {
    flex: 1 0 50%;
  }
}
.flexgrid-3up > * { flex: 1 0 32%; }
.flexgrid-4up > * { flex: 1 0 23%; }
.flexgrid-5up > * { flex: 1 0 18%; }
