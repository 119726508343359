video {
  max-width: 100%;
  display: block;
}


// Styles to ensure videos maintain aspect ratio at any width
// Reset hard-coded widths/heights of iframes to fill the space
// ----------------------------------------------------------------
.video-embed,
.wistia_embed {
  max-width: 100%;
  aspect-ratio: 16/9;

  iframe {
    width: 100%;
    height: 100%;
  }
}


// Video Viewer
// ----------------------------------------------------------------
// shows the video on the left and a set of other videos to use on the right
// should be used in conjunction with tabs to switch out videos

.video-viewer {
  
  &-video {
    @include breakpoint($lg) {
      @include span(8);
    }

    .video-embed {
      box-shadow: 0 6px 16px hsla(0, 0%, 0%, 0.2);
    }
  }

  &-list {
    @include breakpoint($lg) {
      @include span(last 4);
    }

    h3 {
      @include rhythm(-0.25, 0, 0, .25);
      text-align: center;
    }

    .video-title {
      display: block;
      font-size: .75em;
      text-align: center;
      line-height: 1.3;

      @include breakpoint($lg) {
        margin-top: -.5em;
      }
    }

    a {
      display: block;
      padding-left: 4%;
      padding-right: 4%;
    }

    .video-thumb {
      @extend .video-embed;
      @extend .video-playicon;
    }

    .active .video-thumb {
      box-shadow: 0 0 1px 1px palette(teal);
    }
  }

  .video-viewer-videos {
    @include unstyled-list;
    margin: 0;

    li {
      @include trailer(.75);
      @include gallery(2 of 4);

      @include breakpoint($med) {
        @include gallery(2 of 8);
      }

      @include breakpoint($lg) {
        @include gallery(2 of 4);
      }
    }
  }
}


// Add a play icon on top of an image link
// ----------------------------------------------------------------
$playicon-size: 42px;
$playicon-hover-color: palette(teal);

// Insert a "play" icon overlay
.video-playicon {
  position: relative;
  display: inline-block;
  text-decoration: none;
  border-bottom: none !important;

  &:before {
    transition: .25s all;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.25em;
    height: 2.25em;
    margin-left: -1.125em;
    margin-top: -1.125em;
    content: '';
    background: rgba(0, 0, 0, 0.5) url("/mgs_assets/images/v4/svg-icons-source/play-white.svg") 55% 50%/36% no-repeat;
    color: white;
    font-size: 1em;
    line-height: 2.45em;
    text-align: center;
    text-decoration: none;
  }

  &:hover {
    &:before {
      background-color: $playicon-hover-color;
    }
  }

  img {
    display: block;
  }
}