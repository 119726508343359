.pdus-grid {
  h2 { font-size: 1.8em; margin: .5em 0; }
  h4 { font-size: 1.25em; color: $base-font-color; }

  > * {
    width: 100%;
  }
}

// PDUS/SEUs box
// ----------------------------------------------------------------
.pdus-box {
  position: relative;

  .scrumalliance-badge {
    max-width: 150px;
    margin: -3.7em auto 0;
    display: block
  }
  .certificate-bubble {
    display: none;
    position: absolute;
    top: -6em;
    right: 1em;
    width: 165px;
    max-width: 25%;

    @include breakpoint($med) {
      display: block;
    }
  }
}