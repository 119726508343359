.this-or-that {
  border: 1px solid #dbe3e5;
  background-color: #fcfcfc;
  display: flex;
  border-radius: .25em;
  align-items: center;
}
.this-or-that a {
  flex: 2;
  text-align: center;
  padding: .75em;
}
.this-or-that .or {
  background-color: #00b39b;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}