// GoatBot Widget

.goatbot-question {
  --icon-size: 44px;
  --button-width: 7.5rem;

  margin-block: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .5em;
  width: calc(100% - .5em); // compensate for submit moved inside
}
.goatbot-question::before {
  content: '';
  width: var(--icon-size);
  height: var(--icon-size);
  background: transparent url(/mgs_assets/images/v4/goatbot-mono-small.svg) 0 0 / contain no-repeat;
  align-self: start;
  margin-top: 0.5em;
}
.goatbot-question input[type="text"],
.goatbot-question .question {
  flex: 1;
  background: #fff;
  border: 1px solid #b5c0cb;
  box-shadow: 0 4px 6px 0 inset rgba(0,0,0,0.1);
  border-radius: .5em;
  font-size: 1.2em;
  color: #222;
  padding: 0.5em;
  padding-inline-end: calc(var(--button-width) + 1em);
}
.goatbot-question input[type="submit"],
.goatbot-question button {
  background: #4897ea;
  color: #fff;
  border: none;
  border-radius: .35em;
  display: block;
  padding: .5em 1.25em;
  margin-inline-start: auto;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.3);
  font-size: .9em;
  cursor: pointer;
  font-family: $base-font-family;
  width: var(--button-width);
  margin-left: calc(-1 * (var(--button-width) + 1.15em));
}
.goatbot-question .goatbot-description {
  width: 100%;
  padding-left: calc(var(--icon-size) + 0.5em);
  text-align: center;
  font-size: .75em;
  font-style: italic;
  opacity: 0.7;
}