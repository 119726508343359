// Boxes
// ----------------------------------------------------------------
$info-box-bg: #f2f7fc !default;
$info-box-border: #d0dae2 !default;
$highlight-box-bg: #fffccd !default;

$announcement-box-bg: palette(grey, xx-light);

$box-padding: 1em !default;
$box-radius: .35rem !default;
$box-border-color: palette(blue, x-light) !default;

.box {
  padding: $box-padding;
  border-radius: $box-radius;
  border: 1px solid $box-border-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  * {
    max-width: 100%; // for IE11
  }
}

.box-info {
  background-color: $info-box-bg;
}

.box-white {
  background-color: #fff;
}

.box-extra-padding {
  padding: $box-padding * 2;
}

.box-footer {
  margin-top: auto;
  width: 100%;
}

.box-slideup {
  margin-top: -4em;
  margin-bottom: 4em;
}

.box-mountains {
  background-image: url(/mgs_assets/images/v4/mountains.svg);
  background-position: 110% 100%;
  background-repeat: no-repeat;
}

// Ghost box, semi transparent white for appearing on dark backgrounds
.ghost-box {
  border-color:rgba(255,255,255,.8);
  background: rgba(255,255,255,.09);
}


// yellow box, rounded corners
// TODO: very similar to .alert, combine?
.highlight-box {
  @include rhythm(1,.5,.5,1);
  border-radius: 4px;
  background-color: $highlight-box-bg;
  padding-left: $box-padding;
  padding-right: $box-padding;
  color: #333;

  > *:first-child { margin-top: 0; }
  > *:last-child { margin-bottom: 0; }

  a {
    color: $link-color !important; // fix white links when on blue stripe
  }
}

// white bordered box
.feature-box-small {
  @include rhythm(1,.5,.5,1);
  overflow: hidden;
  background-color: #fff;
  border-radius: $box-radius;
  padding-left: $box-padding;
  padding-right: $box-padding;
  border: 1px solid $info-box-border;
}

// white shadowed box
.feature-box {
  @include deep-shadow-box;
}


// Course Boxes
// -------------------------------------------------------------------
.course-summary-box {
  position: relative;
  max-width: 400px;

  > .media {
    gap: 0;
  }
  
  @include breakpoint($lg) {
    .media-item {
      margin-top: -2.5em;
    }
  }

  .block-link {
    height: 100%;
  }
  .free-ribbon {
    position: absolute;
    top: -2px;
    right: -2px;
  }
  .box-footer {
    display: none;
    align-self: stretch;
    margin-top: auto;

    @include breakpoint($lg) {
      display: block;
    }
  }
  .box-footer a {
    color: inherit;
  }
}

.course-summary-box-horizontal {
  max-width: none;

  > .media {
    flex-direction: row;
    text-align: left;
    align-items: start;
    gap: 1em;
  }
  .media-item {
    margin-top: -.5em;
    flex-basis: 20%;
  }
  .media-body {
    flex: 1 0 75%;
  }
  .cluster-centered {
    justify-content: start; // remove centering
  }
  .box-footer {
    padding-top: .5em;
  }
  .box-footer .button {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: $link-color !important;

    &::after {
      content: ' »';
    }
  }
}

// Courses Grid Roles Callout
.courses-grid-roles-callout {
  grid-column: 1 / -1; // span all columns no matter how many
  width: 100%;
  background-color: palette(teal);
  color: #fff;
  font-size: 1.3em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em 2em;
  position: relative;

  img {
    margin-block: -2em;
    max-width: 3em;
  }
}