// Discounts
// ----------------------------------------------------------------

.discounts-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    color: $base-font-color;
    margin: auto 0;
  }
}

.discount {
  border-radius: 6px;
  text-align: center;
  padding: 1em 2em;
  border: 2px dashed palette(teal);
  background-color: white;
}

.save {
  @include type-setting(-1);
  color: palette(blue);
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  letter-spacing: 1px;
  margin-bottom: .5em;
}

.dollar-sign,
.discount-price,
.discount-per {
  color: palette(teal);
  font-weight: 300;
}

.dollar-sign {
  font-size: 1.68em;
  line-height: .875em;
  position: relative;
  top: -.4em;
}

.discount-price {
  font-size: 3.36em;
  line-height: .4375em;
  font-weight: 500;
  position: relative;
  top: .1em;
}

.discount-per {
  max-width: 3em;
  display: inline-block;
  text-align: left;
  overflow: visible;
  line-height: 1;
}

.discount-description {
  display: block;
  margin-top: .5em;
  line-height: 1.25;
}