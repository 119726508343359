// Dropdowns
// ----------------------------------------------------------------
// follows markup format from http://getbootstrap.com/javascript/#dropdowns
// 
// <div class="dropdown">
//   <button id="dLabel" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//     Dropdown trigger
//     <span class="caret"></span>
//   </button>
//   <ul class="dropdown-menu" aria-labelledby="dLabel">
//     ...
//   </ul>
// </div>

$dropdown-bg-color: #f9fbfc !default;
$dropdown-link-color: #333 !default;
$dropdown-link-hover-bg: #dbe9d0 !default;

$dropdown-centered-width: 200px;

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  @include unstyled-list;
  margin: 0;
  border-radius: $default-border-radius;
  background-color: $dropdown-bg-color;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.4);
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  z-index: 999;
  padding: 5px;

  &.centered {
    left: 50%;
    width: $dropdown-centered-width;
    margin-left: $dropdown-centered-width * -.5;
  }

  &.has-triangle {
    &:before {
      content: '';
      @include triangle('top', $dropdown-bg-color, 10px);
      position: absolute;
      top: -10px;
      left: 50%;
      margin-left: -5px;
    }
  }

  .open > & {
    display: block;
  }

  li a {
    @include type-setting(-1);
    @include unstyled-link;
    font-weight: 500;
    display: block;
    color: $dropdown-link-color;
    margin-bottom: 2px;
    padding: .5em 1em;

    &:hover {
      background: $dropdown-link-hover-bg;
    }
  }
}