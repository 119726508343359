@use 'sass:math';

// Features Grid
// ----------------------------------------------------------------

$features-grid-border-color: palette(blue, light) !default;
$features-grid-padding: 1em !default;

.features-grid {
  @include unstyled-list;
  @include rhythm(1,0,0,1);

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(math.div($med, 2), 1fr));
    }
  }

  strong {
    @include trailer(.25);
    display: block;
  }

  li {
    padding: $features-grid-padding;
    flex: 0 0 100%;
    height: 100%;

    &:first-child { border-top: none; }

    @include breakpoint($med) {
      flex-basis: 50%;
      border-top: 1px solid $features-grid-border-color;
      border-right: 1px solid $features-grid-border-color;

      &:nth-child(2) { border-top: none; }
      &:nth-child(2n) { border-right-width: 0; }
    }

    @include breakpoint($lg) {
      flex-basis: 32%;

      &:nth-child(3) { border-top: none; }
      &:nth-child(2n) { border-right-width: 1px; }
      &:nth-child(3n) { border-right: none; }
    }
  }

}


// Modules
// ----------------------------------------------------------------
// modules are 50% wide blocks. Different than content-split as these can be more than 2 columns
// different types of modules possible with styles imposed by the containing stripe

@mixin modules-container {
  display: flex;
  flex-wrap: wrap;
}

.modules {
  @include modules-container;
}

.module {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include breakpoint($lg) {
    width: 50%;
    padding: 0 1rem;
  }
}

.module-small {
  @extend .module;

  @include breakpoint($med) {
    width: 50%;
    padding: 0 1rem;
  }

  @include breakpoint($lg) {
    width: 25%;
  }
}

// Video modules
.video-modules {
  > .container {
    @include modules-container;
  }

  .module {
    @include type-setting(-1);
    @include trailer;
    border-radius: $panel-border-radius;
    background: $panel-bg;
    padding: $panel-padding;
    text-align: left;

    @include breakpoint($lg) {
      width: 47%;
      margin-left: 1.5%;
      margin-right: 1.5%;
    }

    > h2 {
      margin-top: 0;
    }
  }
}

// Testimonials modules
.modules--leader {

  .module {
    @include type-setting(-1);
    @include trailer;
    border-radius: $panel-border-radius;
    border: 1px solid palette(blue, light);
    background: $panel-bg;
    padding: $panel-padding;
    text-align: left;
    
    @include breakpoint($lg) {
      width: 47%;
      margin-left: 1.5%;
      margin-right: 1.5%;
    }
    img, h4 {
      text-align: center;
    }
    > img {
      align-self: center;
    }
    > h4 {
      margin: 0.5em 0 0.3em;
    }
  }
}

