// Floating CTA
// a panel containing buttons that slides in on the bottom-right of the page

.floating-cta {
  position: fixed;
  bottom: 2em;
  right: 0;
  opacity: 1;
  background-color: #ededed;
  padding: .5em .65em;
  border-radius: .35em 0 0 .35em;
  box-shadow: 0 1px 3px rgba(0,0,0,.2),
              0 8px 10px rgba(0,0,0,.2);
  transition: right .5s,
              opacity .25s;
  display: none;
  z-index: z-index(floating-cta);

  @include breakpoint($lg) {
    display: block;
  }

  &.hidden {
    right: -100px;
    opacity: 0;
    pointer-events: none;
    display: block !important; // Override default "hidden" styles
  }

  .cluster {
    --gap: .5em;
  }

  .button {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .button,
  .selectboxit-container span {
    font-size: .85em;
    height: 40px;
    line-height: 40px;
  }

  .button,
  .selectboxit {
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
    min-width: 16ch;
  }

  .text-small {
    font-size: 0.7em;
  }
}