@charset "UTF-8";
@import url("https://use.typekit.net/atx3iyq.css");
@import url("https://fonts.googleapis.com/css?family=Kalam:300,400|Dosis:300,400");
:root {
  --container-width: 1170px;
}

body {
  --header-height: 5.9rem;
  --header-border-height: 6px;
  --announcement-height: 0px;
  --page-block-start: calc(var(--header-height) + var(--announcement-height) - var(--header-border-height));
}

@media (min-width: 900px) {
  body {
    --header-height: 5rem;
  }
}
:root {
  font-family: "interface", sans-serif;
  line-height: 1.4736842105;
  font-size: 16.15px;
}
@media (min-width: 900px) {
  :root {
    font-size: 19px;
  }
}

.unstyled-link {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
}
.unstyled-link:active, .unstyled-link:focus {
  outline: none;
}

.unstyled-link-with-hover {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  color: inherit;
}
.unstyled-link-with-hover:active, .unstyled-link-with-hover:focus {
  outline: none;
}
.unstyled-link-with-hover:hover, .unstyled-link-with-hover:focus {
  text-decoration: underline;
  color: #4797eb;
  cursor: pointer;
}

.content-link, .map-infobox a,
.quiet-content-link {
  color: inherit;
  border-bottom: 1px solid #65bef6;
  text-decoration: none;
}
.content-link:hover, .map-infobox a:hover,
.quiet-content-link:hover {
  border-bottom-width: 2px;
}

.quiet-content-link {
  color: #abb4b9;
  border-bottom-color: #d5efff;
}

.block-link {
  display: block;
}
.block-link:hover {
  background-color: #d5efff;
}

.simple-link {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  color: #4797eb;
}
.simple-link:active, .simple-link:focus {
  outline: none;
}
.simple-link:hover, .simple-link:focus {
  text-decoration: underline;
  color: #4797eb;
  cursor: pointer;
}

.link-alt {
  color: #00c3a9;
}
.link-alt:hover {
  color: #00907d;
}

.link-more:after {
  content: "»";
  display: inline-block;
  margin-left: 0.25em;
}

.element-cover-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tooltipster-base a {
  color: #fff;
}

a {
  color: #4797eb;
  transition: all 0.25s;
}
a:hover {
  color: #197de6;
}

.offset-anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4 {
  font-weight: 400;
  color: #2c7acb;
  font-family: "interface", sans-serif;
  text-wrap: balance;
}

h1, .h1 {
  line-height: 1.4736842105;
  font-size: 2.07485rem;
  color: #25578b;
  font-weight: 100;
}
@media (min-width: 900px) {
  h1, .h1 {
    font-size: 2.441rem;
  }
}

h2, .h2 {
  line-height: 1.4736842105;
  font-size: 1.53rem;
  font-weight: 300;
}
@media (min-width: 900px) {
  h2, .h2 {
    font-size: 1.8rem;
  }
}

h3, .h3 {
  line-height: 1.4736842105;
  font-size: 1.275rem;
  font-weight: 300;
}
@media (min-width: 900px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  line-height: 1.4736842105;
  font-size: 1.0625rem;
}
@media (min-width: 900px) {
  h4, .h4 {
    font-size: 1.25rem;
  }
}

h5, .h5 {
  line-height: 1.4736842105;
  font-size: 1.0625rem;
  font-weight: 600;
  color: #4c4d52;
}
@media (min-width: 900px) {
  h5, .h5 {
    font-size: 1.25rem;
  }
}

.h4 {
  font-weight: 600;
}

h1.heading-alt,
.h1.heading-alt {
  color: #2c7acb;
}

.section-heading,
.heading-primary,
.heading-primary-alt {
  text-align: center;
}

.heading-secondary,
.heading-secondary-alt {
  line-height: 1.4736842105;
  font-size: 1.53rem;
  text-align: center;
  font-weight: 300;
}
@media (min-width: 900px) {
  .heading-secondary,
  .heading-secondary-alt {
    font-size: 1.8rem;
  }
}

.heading-hl {
  color: #00c3a9 !important;
}

.heading-tertiary {
  line-height: 1.4736842105;
  font-size: 0.85rem;
  color: #808080;
  text-transform: uppercase;
  font-weight: 400;
}
@media (min-width: 900px) {
  .heading-tertiary {
    font-size: 1rem;
  }
}

.heading-hfeed {
  line-height: 1.4736842105;
  font-size: 1.53rem;
  text-align: center;
  font-weight: 300;
  margin-top: 1.4736842105em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 1.4736842105em;
}
@media (min-width: 900px) {
  .heading-hfeed {
    font-size: 1.8rem;
  }
}

.heading-upper {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: 0.05ch;
  color: #00c3a9;
}

.entry-heading,
.entry-heading-med {
  text-align: center;
  margin-bottom: 0.3684210526em;
}

.tag-heading {
  margin-top: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 0.7368421053em;
}

:is(h1, h2, h3, h4, h5, h6).plain {
  color: #4c4d52;
}

:is(h1, h2, h3).bordered {
  color: #4c4d52;
  margin-bottom: 0.65em;
  line-height: 1.2;
}
:is(h1, h2, h3).bordered::after {
  content: "";
  height: 2px;
  background-color: #65bef6;
  width: 2.25em;
  display: block;
  margin: 0.35em 0;
}
:is(h1, h2, h3).bordered.text-center::after {
  margin-inline: auto;
}
.text-invert :is(h1, h2, h3).bordered:after {
  background-color: #65bef6;
}

h3.bordered::after {
  margin-block: 0.5em;
}

.kicker {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1.1;
}

h1 .kicker {
  font-size: 0.4em;
}

.breadcrumbs + .page-title-container h1 {
  margin-top: 0.35em;
}

.page-title-container + .stripe-content {
  padding-top: 0;
}

.course-title {
  font-family: "Dosis", "interface", sans-serif;
  font-size: 3rem;
}
.course-title.bordered {
  margin-bottom: 0;
}
.course-title.bordered:after {
  margin-left: 0;
}

body {
  color: #4c4d52;
  font-family: "interface", sans-serif;
}

p {
  margin: 0;
}

p + p {
  margin-top: 1.4736842105em;
}

.text-large {
  line-height: 1.4736842105;
  font-size: 1.0625rem;
  font-weight: 300;
}
@media (min-width: 900px) {
  .text-large {
    font-size: 1.25rem;
  }
}

.text-x-large {
  line-height: 1.4736842105;
  font-size: 1.275rem;
}
@media (min-width: 900px) {
  .text-x-large {
    font-size: 1.5rem;
  }
}

.text-med {
  line-height: 1.4736842105;
  font-size: 0.85rem;
}
@media (min-width: 900px) {
  .text-med {
    font-size: 1rem;
  }
}

.text-med-small {
  font-size: 0.9em;
}

.text-small {
  line-height: 1.4736842105;
  font-size: 0.8rem;
}
@media (min-width: 900px) {
  .text-small {
    font-size: 0.8rem;
  }
}

hr {
  border: none;
  background: #d5efff;
  height: 3px;
  width: 100%;
  margin-top: 26px;
  margin-bottom: 26px;
  position: relative;
  top: -1px;
  clear: both;
}
hr.tiny {
  height: 2px;
  background-color: #65bef6;
  width: 5.5rem;
  display: block;
  margin: 2em auto;
}
hr.short {
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
}
hr.thick {
  height: 2px;
  background-color: #48494b;
}
hr.mountains {
  background-color: transparent;
  background-image: url(/mgs_assets/images/v4/mountains.svg);
  background-position: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  border-bottom: 3px solid #d5efff;
  height: auto;
  max-height: 112px;
  aspect-ratio: 1170/112;
  margin-top: 0;
}

em.alt {
  font-style: normal;
  color: #65bef6;
}

.dot:after {
  content: "•";
  display: inline-block;
  margin: 0 0.75em;
  font-size: 0.8em;
}

.text-invert {
  color: white;
}
.text-invert h1, .text-invert h2, .text-invert h3, .text-invert h4, .text-invert h5, .text-invert h6 {
  color: white;
}
.text-invert a {
  color: white;
}
.text-invert table a:hover {
  color: white !important;
}
.text-invert td {
  border-color: #65bef6;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table.simple th, table.simple td {
  border: none;
}
table th, table td {
  padding: 0.5em;
  border-bottom: 1px solid #eeeeee;
  vertical-align: top;
  text-align: left;
}
table thead th {
  font-size: 0.82rem;
  letter-spacing: 1px;
  font-weight: normal;
  text-transform: uppercase;
  color: #888888;
  border-bottom: 2px solid #dddddd;
  vertical-align: bottom;
  white-space: nowrap;
}
table tbody th {
  font-weight: bold;
}
table tr.odd td {
  background: #fffce5;
}
table caption {
  text-align: center;
}
table .actions {
  text-align: right;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #fffceb;
}

.table-striped td {
  border: none;
}
.table-striped tbody tr:nth-child(odd) td {
  background-color: #d5efff;
}

@media (max-width: 900px) {
  .table-responsive-stacked {
    display: block;
  }
  .table-responsive-stacked table, .table-responsive-stacked thead, .table-responsive-stacked tbody, .table-responsive-stacked th, .table-responsive-stacked td, .table-responsive-stacked tr {
    display: block;
  }
  .table-responsive-stacked thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table-responsive-stacked tr {
    border-bottom: 3px solid #eee;
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .table-responsive-stacked tr:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-responsive-stacked tbody tr:hover td {
    background-color: #fff;
  }
  .table-responsive-stacked tbody tr:hover:nth-child(2n) td {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-responsive-stacked td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }
  .table-responsive-stacked td[data-label] {
    padding-left: 43%;
  }
  .table-responsive-stacked td:last-child, .table-responsive-stacked td.no-border {
    border-bottom: none;
  }
  .table-responsive-stacked td.empty {
    display: none;
  }
  .table-responsive-stacked td.actions {
    padding-left: 0;
    text-align: center;
  }
  .table-responsive-stacked td.actions:before {
    content: "" !important;
  }
  .table-responsive-stacked td:before {
    content: attr(data-label);
    position: absolute;
    top: 6px;
    left: 6px;
    width: 40%;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    font-size: 0.9em;
  }
  .table-responsive-stacked tbody tr:nth-child(odd) td {
    background-color: transparent;
  }
  .table-responsive-scrollable {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
.reusable-presentation-table .language {
  padding-left: 36px;
}
.reusable-presentation-table .download {
  text-align: center;
  vertical-align: middle;
}
.reusable-presentation-table .icon-download-powerpoint,
.reusable-presentation-table .icon-download-keynote,
.reusable-presentation-table .icon-download-openoffice {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.reusable-presentation-table .quiet {
  font-size: 0.85em;
}
.reusable-presentation-table .flag {
  max-width: 32px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  margin-top: 0.25em;
}

.likert-table {
  margin-bottom: 2.9473684211em;
  width: 100%;
  table-layout: fixed;
}
.likert-table tr {
  transition: background 0.2s;
  width: 100%;
}
.likert-table td {
  padding-left: 50%;
}
.likert-table td, .likert-table th {
  border-color: #e3e3e3;
}
@media (min-width: 900px) {
  .likert-table td, .likert-table th {
    padding: 1em 0;
  }
}
.likert-table thead th {
  white-space: normal;
  text-align: center;
  line-height: 1.5;
  padding-left: 0;
}
.likert-table label {
  float: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.likert-table thead h3,
.likert-table thead h4 {
  margin: 0 !important;
  font-weight: 600;
  text-transform: none;
  text-align: left;
  font-size: 1.6em;
  letter-spacing: normal;
}
@media (min-width: 900px) {
  .likert-table thead th:first-child,
  .likert-table tbody th {
    width: 45%;
  }
  .likert-table thead th,
  .likert-table tbody td {
    text-align: center;
    width: 11%;
  }
}
.likert-table tbody tr:hover {
  background: #dde8ef;
}
.likert-table input[type=radio] {
  display: inline-block !important;
  cursor: pointer;
}

.course-modules-table th {
  width: 30%;
  color: #4797eb;
  font-family: Dosis;
  font-weight: normal;
  font-size: 1.15em;
  border-bottom: none;
  border-top: 1px solid #d5efff;
}

.course-modules-table td {
  padding-bottom: 3em;
  border-bottom: none;
  border-top: 1px solid #d5efff;
  padding-top: 0.8em;
}

.upcoming-training-table thead td {
  border-bottom-width: 2px;
  text-transform: uppercase;
  color: #abb4b9;
}
.upcoming-training-table a:not(.button) {
  text-decoration: none;
}
.upcoming-training-table a:not(.button):hover {
  text-decoration: underline;
}
.upcoming-training-table .training-date {
  white-space: nowrap;
}
.upcoming-training-table .early-bird {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  font-style: italic;
  color: #abb4b9;
}
@media (min-width: 900px) {
  .upcoming-training-table .early-bird {
    font-size: 0.8rem;
  }
}
.upcoming-training-table .training-actions {
  white-space: nowrap;
}
.upcoming-training-table .td-more-info .alert,
.upcoming-training-table .actions .alert,
.upcoming-training-table .training-actions .alert {
  width: 7.2em;
  text-align: center;
  float: right;
  clear: both;
}
.upcoming-training-table .td-more-info .button,
.upcoming-training-table .actions .button,
.upcoming-training-table .training-actions .button {
  white-space: nowrap;
  width: 7em;
}
.upcoming-training-table .spots-left,
.upcoming-training-table .sold-out {
  color: #900;
}
.upcoming-training-table tbody + tbody:before {
  content: " ";
}
.upcoming-training-table tbody + tbody {
  border-top: 2px solid #d0dae2;
}

.upcoming-training-weeks-heading {
  line-height: 1.4736842105;
  font-size: 0.85rem;
  margin-top: 0.4789473684em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 0em;
  font-weight: 600;
  clear: both;
}
@media (min-width: 900px) {
  .upcoming-training-weeks-heading {
    font-size: 1rem;
  }
}
@media (min-width: 900px) {
  .upcoming-training-weeks-heading {
    float: left;
    width: 20%;
  }
}
.upcoming-training-weeks-heading.has-flag {
  padding-left: 30px;
}
.upcoming-training-weeks-heading i {
  float: left;
  width: 24px;
  height: 24px;
  margin-left: -30px;
}
.upcoming-training-weeks-heading time,
.upcoming-training-weeks-heading em {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  display: block;
  font-weight: normal;
  font-style: italic;
  color: #abb4b9;
}
@media (min-width: 900px) {
  .upcoming-training-weeks-heading time,
  .upcoming-training-weeks-heading em {
    font-size: 0.8rem;
  }
}

.upcoming-training-weeks {
  padding-top: 1.4736842105em;
}
.upcoming-training-weeks .upcoming-training-table {
  margin-bottom: 0;
}
@media (min-width: 900px) {
  .upcoming-training-weeks .upcoming-training-table {
    padding-bottom: 1.4736842105em;
    border-bottom: 1px solid #d5efff;
    float: right;
    width: 80%;
  }
  .upcoming-training-weeks .upcoming-training-table tr:last-child td, .upcoming-training-weeks .upcoming-training-table tr:last-child th {
    border-bottom: none;
  }
  .upcoming-training-weeks .upcoming-training-table .training-date {
    width: 14%;
  }
  .upcoming-training-weeks .upcoming-training-table .training-course {
    width: 32%;
  }
  .upcoming-training-weeks .upcoming-training-table .training-price {
    width: 25%;
  }
  .upcoming-training-weeks .upcoming-training-table .td-more-info {
    width: 30%;
  }
}

@media (max-width: 900px) {
  .upcoming-training-weeks-heading {
    margin: 0 -1rem;
    padding: 0.5rem 1rem;
    font-size: 1.1em;
    display: flex;
    flex-wrap: wrap;
    background-color: #f7f7f7;
    line-height: 1.2;
  }
  .upcoming-training-weeks-heading time {
    font-size: 0.9em;
    font-style: normal;
    color: #4c4d52;
    margin-left: auto;
  }
  .upcoming-training-weeks-heading em {
    flex-basis: 100%;
  }
  .upcoming-training-table {
    font-size: 0.9em;
  }
  .upcoming-training-table tr {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75em 0;
    position: relative;
  }
  .upcoming-training-table tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  .upcoming-training-table tr > td {
    border: none;
    padding: 0.125em;
  }
  .upcoming-training-table a {
    white-space: normal;
  }
  .upcoming-training-table .training-date {
    flex: 1 0 25%;
    font-weight: bold;
  }
  .upcoming-training-table .training-course,
  .upcoming-training-table .training-location,
  .upcoming-training-table .training-time,
  .upcoming-training-table .training-price,
  .upcoming-training-table .training-instructor,
  .upcoming-training-table .training-actions {
    flex: 0 1 75%;
    margin-left: auto;
  }
  .upcoming-training-table .training-location,
  .upcoming-training-table .training-course {
    font-weight: bold;
  }
  .upcoming-training-table .training-location .text-small,
  .upcoming-training-table .training-course .text-small {
    display: none;
  }
  .upcoming-training-table .training-date a::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .upcoming-training-table .training-date a::before {
    content: url('data:image/svg+xml;utf8,<svg width="18" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M16.9 17.023L3.26 30.664a1.627 1.627 0 01-1.195.492c-.469 0-.867-.164-1.196-.492l-.132-.133a1.759 1.759 0 01-.528-1.195 1.513 1.513 0 01.457-1.195L12.98 15.828.666 3.515A1.513 1.513 0 01.21 2.32c.024-.469.2-.867.528-1.195L.869.992A1.627 1.627 0 012.065.5c.469 0 .867.164 1.195.492l13.64 13.64c.376.329.563.727.563 1.196s-.187.867-.562 1.195z" /></svg>');
    position: absolute;
    top: 10px;
    right: 0;
    width: 18px;
    height: 32px;
    opacity: 0.08;
  }
}
.upcoming-training-table .training-date .training-time,
.upcoming-training-table .training-course .training-time {
  display: none;
}
.upcoming-training-table td.training-time {
  display: block;
}
@media (min-width: 900px) {
  .upcoming-training-table .training-date .training-time,
  .upcoming-training-table .training-course .training-time {
    display: block;
  }
  .upcoming-training-table td.training-time {
    display: none;
  }
}

.comma-separated-list {
  list-style-type: none;
}
.comma-separated-list, .comma-separated-list li {
  margin: 0;
  padding: 0;
  display: inline;
}
.comma-separated-list li:not(:last-child)::after {
  content: ", ";
}
.comma-separated-list li {
  background: none;
}

.unstyled-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.unstyled-list li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}

.content-list {
  list-style: none;
}
.content-list li {
  padding-left: 1.25em;
  position: relative;
}
.content-list li:before {
  line-height: 1.4736842105;
  font-size: 0.64rem;
  content: "•";
  color: #00c3a9;
  display: inline-block;
  position: absolute;
  left: 0;
}
@media (min-width: 900px) {
  .content-list li:before {
    font-size: 0.64rem;
  }
}

.view-more-list {
  list-style: none;
  padding-left: 0;
}
.view-more-list > li {
  padding-left: 1.25em;
  background: transparent url("/mgs_assets/images/v4/svg-icons-source/next.svg") 0.15em 0.3em no-repeat;
  position: relative;
}
.view-more-list a {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  color: #4797eb;
}
.view-more-list a:active, .view-more-list a:focus {
  outline: none;
}
.view-more-list a:hover, .view-more-list a:focus {
  text-decoration: underline;
  color: #4797eb;
  cursor: pointer;
}

.pipe-nav-list {
  list-style: none;
  padding-left: 0;
  display: flex;
}
.pipe-nav-list > li + li {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.pipe-nav-list a {
  margin-inline: 1em;
  display: block;
}
.pipe-nav-list li:first-child a {
  margin-inline-start: 0;
}

.plain {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.plain li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.plain li {
  line-height: 2;
}

.checklist,
.checklist > ul {
  margin-left: 0;
  padding-left: 0;
}
.checklist li,
.checklist > ul li {
  margin-bottom: 0.3684210526em;
  background: url("/mgs_assets/images/v4/svg-icons-source/check.svg") no-repeat;
  list-style-type: none;
  padding-left: 30px;
  background-position: 0 2px;
}

.checkbox-list,
.checkbox-list > ul {
  margin-left: 0;
  padding-left: 0;
}
.checkbox-list li,
.checkbox-list > ul li {
  background: url("/mgs_assets/images/v4/svg-icons-source/bullet-checkbox.svg") no-repeat;
  list-style-type: none;
  padding-left: 30px;
  background-position: 0 4px;
}

.question-list,
.question-list > ul {
  margin-left: 0;
  padding-left: 0;
}
.question-list li,
.question-list > ul li {
  background: url("/mgs_assets/images/v4/svg-icons-source/bullet-question.svg") no-repeat;
  list-style-type: none;
  padding-left: 30px;
  background-position: 0 4px;
}

.two-col,
.three-col,
.four-col {
  padding-left: 0;
}
@media (min-width: 600px) {
  .two-col,
  .three-col,
  .four-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 1rem;
  }
}

@media (min-width: 600px) {
  .three-col {
    grid-template-columns: repeat(3, 1fr);
  }
  .four-col {
    grid-template-columns: repeat(4, 1fr);
  }
}
ol.fancy {
  list-style: none;
  padding-left: 0;
  counter-reset: fancy-numbers;
}
ol.fancy > li {
  padding-left: 2em;
  position: relative;
  margin-bottom: 1em;
}
ol.fancy > li:before {
  content: counter(fancy-numbers);
  counter-increment: fancy-numbers;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  color: #00c3a9;
  font-weight: bold;
  text-align: center;
  font-size: 0.9em;
  border: 1px solid #dbe3e5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
}

.icon-list {
  list-style: none;
  padding-left: 0;
}
.icon-list li {
  padding-left: 1.75em;
  margin-bottom: 1em;
}
.icon-list .icon {
  margin-left: -1.4em;
  margin-right: 0.25em;
}

.bullets-vertical ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.bullets-vertical li {
  margin: 1em 0;
  display: block;
}

.bullets-vertical li + li::before {
  content: "•";
  display: block;
  color: #65bef6;
  margin-bottom: 1em;
}

ul.unstyled,
li.unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
ul.unstyled li,
li.unstyled li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}

img {
  max-width: 100%;
  height: auto !important;
}
img.right {
  float: right;
  margin: 0 0 1em 1em;
}
img.left {
  float: left;
  margin: 0 1em 1em 0;
}
img.center {
  display: block;
  margin: 0 auto;
}
img.pull-right {
  float: right;
  margin: 0 0 1em 1em;
}
@media (min-width: 900px) {
  img.pull-right {
    margin-right: -6em;
  }
}
img.pull-left {
  float: left;
  margin: 0 1em 1em 0;
}
@media (min-width: 900px) {
  img.pull-left {
    margin-left: -6em;
  }
}
img.border {
  border: 1px solid #ccc;
}

a img {
  display: block;
}

figure {
  margin: 1em 0;
}
figure img {
  display: block;
}

figcaption {
  display: block;
  text-align: center;
  font-style: italic;
  font-size: 0.85em;
  opacity: 0.75;
  margin-top: 0.5em;
}

.has-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.has-deep-shadow {
  box-shadow: 0 5px 17px rgba(0, 0, 0, 0.2);
}

.center-bgrect {
  width: 100%;
}

.img-bgrect {
  aspect-ratio: 125/52;
  position: relative;
}
.img-bgrect > img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 360px;
  z-index: 1;
}
@media (min-width: 900px) {
  .img-bgrect::before {
    content: "";
    width: 100%;
    border-radius: 0.7em;
    background-color: #d5efff;
    z-index: 0;
    position: absolute;
    top: 38.6666667%;
    left: 0;
    aspect-ratio: 26/5;
  }
}
.img-bgrect + * {
  margin-top: 0;
}
.img-bgrect + h4 {
  font-size: 1.1em;
  font-weight: bold;
  color: #4c4d52;
  margin: 0;
}

.img-bgrect-large {
  --bg-color: #d5efff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-bgrect-large::before {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  border-radius: 0.7em;
  background-color: var(--bg-color);
}
.img-bgrect-large > img {
  position: relative;
  width: 70%;
}

.avatar-circle {
  --avatar-size: 8rem;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.avatar-circle > img {
  display: block;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}
.avatar-circle-centered {
  display: block;
  margin: 0 auto;
}

.avatar-lg {
  --avatar-size: 12rem;
}

.avatar-circle .video-button {
  appearance: none;
  border: none;
  padding: 0.5em 0.5em 1.75em;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 0.65em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}
.avatar-circle .video-button:hover {
  background-color: rgba(0, 101, 87, 0.8);
  padding-block-end: 2em;
  font-size: 0.75em;
}

address {
  font-style: normal;
}

abbr {
  border: none;
}

mark {
  background-color: #fffccd;
  display: inline-block;
  padding: 0 0.125em;
}

.text-quiet,
.quiet {
  opacity: 0.6;
}

.entry-content blockquote {
  border-left: 3px solid #d5efff;
  color: #3b76b3;
  margin-left: 1.15em;
  padding-left: 1.15em;
}
.entry-content blockquote.handwriting {
  font-size: 1.3em;
}

blockquote.fancy {
  position: relative;
  padding-left: 2.5em;
  margin-left: 0;
  color: #3b76b3;
  border: none;
}
blockquote.fancy:before {
  content: "“";
  position: absolute;
  font-size: 4.5em;
  top: 0.25em;
  left: 0;
  color: #c1e0f3;
  font-family: Arial, sans-serif;
  font-weight: bold;
}

.aside {
  margin-top: 1.4736842105em;
  padding-top: 0.7368421053em;
  padding-bottom: 0.7368421053em;
  margin-bottom: 1.4736842105em;
  background-color: #f7fcff;
  color: #68757d;
  font-size: 0.9em;
  line-height: 1.4;
  padding: 1em;
}
@media (min-width: 900px) {
  .aside {
    width: 36%;
    float: right;
    border-left: 2px solid #d5efff;
    margin-top: 0;
    margin-right: -23%;
    margin-left: 1rem;
    font-size: 0.85em;
  }
}
.aside > :first-child {
  margin-top: 0;
}

.quiet-color {
  color: #abb4b9;
}

.handwriting {
  font-family: "Kalam", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  letter-spacing: -0.05em;
  vertical-align: middle;
}

em.handwriting {
  color: #00c3a9;
  font-weight: 300;
}

.handwriting-blue,
em.handwriting-blue {
  color: #65bef6;
}

.hidden {
  display: none !important;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0); /* Legacy property for Internet Explorer */
  clip-path: inset(50%);
  white-space: nowrap;
}

.highlighter {
  background-color: #fff7d6;
  font-style: normal;
  display: inline-block;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.text-upper {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.no-margin {
  margin: 0 !important;
}

.no-trailer {
  margin-bottom: 0 !important;
}

.no-padding-trailer {
  padding-bottom: 0 !important;
}

.no-leader {
  margin-top: 0 !important;
}

.no-padding-leader {
  padding-top: 0 !important;
}

.no-border {
  border: none !important;
}

.has-leader {
  margin-top: 1.4736842105em;
}

.has-trailer {
  margin-bottom: 1.4736842105em;
}

.has-leader-wide {
  margin-top: 2.9473684211em;
}

.has-trailer-wide {
  margin-bottom: 2.9473684211em;
}

.has-leader-thin {
  margin-top: 0.7368421053em;
}

.has-trailer-thin {
  margin-bottom: 0.7368421053em;
}

.has-padding-leader {
  padding-top: 1.4736842105em;
}

.has-padding-trailer {
  padding-bottom: 1.4736842105em;
}

.position-up {
  position: relative;
  top: -1.5rem;
}

.has-h-padding {
  display: inline-block;
  padding-left: 1em;
  padding-right: 1em;
}

.font-light {
  font-weight: 300;
}

.icon-hidetext {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
}

@media (max-width: 600px) {
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 600px) {
  .hide-desktop {
    display: none;
  }
}

.cluster {
  --gap: .5rem;
}

.cluster {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap);
}

.cluster-centered {
  justify-content: center;
}

.cluster-loose {
  --gap: 2rem;
}

.cluster-line {
  flex-wrap: nowrap;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

html {
  background: #4a4a4a;
  scroll-behavior: smooth;
  scroll-padding-top: 76px;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
  scroll-behavior: smooth;
}

.wrapper {
  padding-block-start: var(--announcement-height);
}

.container {
  max-width: 1169.9999999802px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}
.container:after {
  content: " ";
  display: block;
  clear: both;
}
@media (min-width: 1170px) {
  .container {
    padding: 0;
  }
}

.center-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-narrow,
.content-x-narrow,
.content-centered,
.content-med {
  margin-left: auto;
  margin-right: auto;
}

.content-med,
.content-centered {
  max-width: 52rem;
}
@media (min-width: 900px) {
  .content-med,
  .content-centered {
    width: 83%;
  }
}

.content-narrow {
  max-width: 40.75rem;
}
@media (min-width: 900px) {
  .content-narrow {
    width: 66%;
  }
}

.content-x-narrow {
  max-width: 30.25rem;
}
@media (min-width: 900px) {
  .content-x-narrow {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .content-left {
    margin-left: 0;
  }
  .content-right {
    margin-right: 0;
  }
}
.content-sidebar,
.content-sidebar-wide {
  --gap: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--gap);
}
@media (min-width: 900px) {
  .content-sidebar,
  .content-sidebar-wide {
    flex-direction: row;
  }
}
@media (min-width: 1170px) {
  .content-sidebar,
  .content-sidebar-wide {
    margin-left: 8.46%;
    margin-right: 8.46%;
  }
  .content-sidebar.full,
  .content-sidebar-wide.full {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 900px) {
  .content-sidebar .cs-content {
    flex: 1 1 70%;
  }
  .content-sidebar .cs-sidebar {
    flex: 1 1 30%;
  }
  .content-sidebar-wide .cs-content {
    flex: 1 1 60%;
  }
  .content-sidebar-wide .cs-sidebar {
    flex: 1 1 40%;
  }
  .content-sidebar-reverse {
    flex-direction: row-reverse;
  }
}
.content-split,
.content-split-bordered {
  --gap: 0;
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr;
}
@media (min-width: 900px) {
  .content-split,
  .content-split-bordered {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 900px) {
  .content-split-reverse > :first-child {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .content-split-reverse > :nth-child(2) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

.content-split-bordered > *,
.content-split-bordered .content-split-section {
  padding: 1em;
}
@media (min-width: 900px) {
  .content-split-bordered > *,
  .content-split-bordered .content-split-section {
    margin: 0;
  }
  .content-split-bordered > *:first-child,
  .content-split-bordered .content-split-section:first-child {
    border-right: 1px solid #d5efff;
  }
  .content-split-bordered > *:last-child,
  .content-split-bordered .content-split-section:last-child {
    border-left: 1px solid #d5efff;
  }
  .content-split-bordered > *.has-padding,
  .content-split-bordered .content-split-section.has-padding {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (min-width: 900px) {
  .content-3col > * {
    float: left;
    width: 33%;
    padding-right: 1rem;
  }
  .content-3col > *:last-child {
    padding-right: 0;
  }
  @supports (display: grid) {
    .content-3col {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      align-items: start;
    }
    .content-3col > * {
      float: none;
      width: auto;
      padding: 0;
    }
  }
}

.content-image-bleed {
  padding-left: 1rem;
  padding-right: 1rem;
}
.content-image-bleed .image img {
  display: block;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 760px) {
  .content-image-bleed {
    padding-left: 0;
    padding-right: 0;
    display: grid;
    grid-template-columns: minmax(1rem, 1fr) minmax(auto, 28rem) minmax(auto, 4rem) minmax(auto, 28rem) minmax(1rem, 1fr);
    overflow: hidden;
  }
  .content-image-bleed .image img {
    max-width: 125%;
  }
  .content-image-bleed .content, .content-image-bleed .image {
    grid-row: 1;
  }
  .content-image-bleed .content {
    grid-column: 2/4;
  }
  .content-image-bleed .image {
    grid-column: 4/6;
    padding-left: 1rem;
  }
  .content-image-bleed.reverse .image {
    grid-column: 1/3;
    padding-right: 1rem;
    padding-left: 0;
  }
  .content-image-bleed.reverse .content {
    grid-column: 3/5;
  }
  .content-image-bleed.reverse .image img {
    float: right;
  }
}
@media (min-width: 1170px) {
  .content-image-bleed .image img {
    max-width: 800px;
  }
}
@media (min-width: 600px) {
  .text-2-col {
    columns: 2;
  }
}
.stripe-photo-aside {
  position: relative;
  overflow: hidden;
}
.stripe-photo-aside .content {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.4736842105em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 1.4736842105em;
}
@media (min-width: 900px) {
  .stripe-photo-aside .content {
    width: 40.7692307693%;
    float: left;
    margin-right: 1.5384615383%;
  }
}
@media (min-width: 900px) {
  .stripe-photo-aside .aside {
    width: 50%;
    float: right;
    margin-right: 0;
    display: inline;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    height: 450px;
  }
}
.stripe-photo-aside .aside img {
  width: 100%;
}

@media (min-width: 900px) {
  .content-icon .content-icon-icon {
    width: 15.3846153847%;
    float: left;
    margin-right: 1.5384615383%;
    margin-left: 8.4615384615%;
  }
  .content-icon .content {
    width: 57.6923076924%;
    float: right;
    margin-right: 0;
    margin-right: 16.9230769231%;
  }
  .content-icon .content > h1:first-child {
    margin-top: 0em;
    padding-top: 0em;
    padding-bottom: 0em;
    margin-bottom: 0.7368421053em;
  }
}

@media (min-width: 900px) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.item-row {
  display: flex;
  align-items: center;
  justify-content: var(--justify, center);
}

.l-title-content {
  display: flex;
}
.l-title-content > :first-child {
  flex: 1 1 27%;
  margin-right: 2rem;
}
.l-title-content > :last-child {
  flex: 1 1 70%;
}
@supports (display: grid) {
  .l-title-content {
    display: grid;
  }
  .l-title-content > :first-child {
    margin-right: 0;
  }
}
@media (min-width: 600px) {
  .l-title-content {
    grid-template-columns: 3fr 8fr;
    gap: 2rem;
  }
}

@media (min-width: 600px) {
  .l-subnav-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "subnav content";
  }
  .l-subnav-content.l-reverse {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "subnav content";
  }
  .l-subnav-content > .l-subnav {
    grid-area: subnav;
    margin-block-end: 1em;
  }
  .l-subnav-content > .l-subnav.has-border {
    border-inline-end: 2px solid #dbe3e5;
    padding: 0 1em 0 0;
  }
  .l-subnav-content > .l-content {
    grid-area: content;
    padding-inline: 2em;
    max-width: 76ch;
    margin: 0 auto;
  }
}
@media (min-width: 900px) {
  .l-pull-left {
    position: relative;
    left: -2rem;
  }
  .l-pull-right {
    position: relative;
    right: -2rem;
  }
}
.illus-header {
  --bg-color: #65bef6;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}

.illus-header.hide-image {
  min-height: 11rem;
}
.illus-header.hide-image img {
  display: none;
}

.illus-header-intro {
  position: relative;
  z-index: 10;
  margin-top: -11rem;
}
.illus-header-intro > .box {
  padding-top: 3.5em;
  padding-bottom: 3.5em;
  margin: -4em auto 4em;
}
@media (min-width: 900px) {
  .illus-header-intro > .box {
    padding-left: 5em;
    padding-right: 5em;
  }
}

.illus-header-intro-team {
  margin-top: -11rem;
}

.grid {
  --grid-item-width: 300px;
  --gap: 1rem;
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));
}
.grid > * {
  height: 100%;
}

.grid-small {
  --grid-item-width: 150px;
}

.grid-med {
  --grid-item-width: 215px;
}

.grid-large {
  --grid-item-width: 340px;
}

.grid-2up,
.grid-3up,
.grid-4up,
.grid-5up,
.grid-6up {
  display: grid;
  gap: 1rem;
}

.grid-item {
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
}
.grid-item > .panel {
  height: 100%;
}

@media (min-width: 900px) {
  .grid-2up {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-3up {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-4up {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-5up {
    grid-template-columns: repeat(5, 1fr);
  }
  .grid-6up {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 600px) {
  .grid-2up.grid-inner-borders {
    grid-gap: 0;
  }
  .grid-2up.grid-inner-borders > *,
  .grid-2up.grid-inner-borders .grid-item {
    border-right: 2px solid #dedede;
    border-top: 2px solid #dedede;
    padding: 2rem;
  }
  .grid-2up.grid-inner-borders > *:nth-child(2n),
  .grid-2up.grid-inner-borders .grid-item:nth-child(2n) {
    border-right: none;
  }
  .grid-2up.grid-inner-borders > *:nth-child(1), .grid-2up.grid-inner-borders > *:nth-child(2),
  .grid-2up.grid-inner-borders .grid-item:nth-child(1),
  .grid-2up.grid-inner-borders .grid-item:nth-child(2) {
    border-top: none;
  }
}
:is(.flexgrid-3up, .flexgrid-4up, .flexgrid-5up) {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
:is(.flexgrid-3up, .flexgrid-4up, .flexgrid-5up) > * {
  flex: 1 0 50%;
}

.flexgrid-3up > * {
  flex: 1 0 32%;
}

.flexgrid-4up > * {
  flex: 1 0 23%;
}

.flexgrid-5up > * {
  flex: 1 0 18%;
}

.media {
  --space: 1rem;
  display: flex;
  align-items: flex-start;
  gap: var(--space);
}

.media-item img {
  display: block;
}

.media-body {
  flex: 1;
}

.media-reversed {
  flex-direction: row-reverse;
}

.media-centered {
  align-items: center;
}

.media-wrap {
  flex-wrap: wrap;
  justify-content: center;
}
.media-wrap > .media-body {
  min-width: 300px;
}

.media-stacked {
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.media-stacked > .media-item {
  margin-bottom: 1.4736842105em;
  float: none;
  margin-right: 0;
}
.media-stacked > .media-item img {
  margin: 0 auto;
}

.media-feature {
  text-align: left;
}
.media-feature > .media-item {
  flex: 1 0 20%;
}
.media-feature > .media-body {
  flex: 1 0 75%;
}
.media-feature > .media-body > *:first-child {
  margin-top: 0;
}
@media (min-width: 900px) {
  .media-feature {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .media-feature.text-left {
    align-items: flex-start;
  }
  .media-feature > .media-item {
    margin-right: 0;
  }
  .media-feature > .media-item,
  .media-feature > .media-body {
    flex: 0 0 auto;
    max-width: 100%;
  }
}

.stack {
  --space: 1.5rem;
}

.stack-tight {
  --space: .75rem;
}

.stack-loose {
  --space: 2.5rem;
}

.stack-x-loose {
  --space: 5rem;
}

.stack-content-rows {
  --space: 5rem;
  margin-bottom: 5rem;
}

.stack > * {
  margin-top: 0;
  margin-bottom: 0;
}

.stack > * + * {
  margin-top: var(--space);
}

.stack-recursive * + * {
  margin-top: var(--space);
}

.stack-bordered > * + * {
  padding-top: var(--space);
  border-top: 1px solid #dbe3e5;
}

.stack-bordered-thick > * + * {
  padding-top: var(--space);
  border-top: 3px solid #d5efff;
}

.alert,
.alert-info {
  background: #fffde7;
  border: 1px solid #fff9b4;
  margin-bottom: 1em;
  border-radius: 4px;
  padding: 0.5em 1em;
  color: #555;
}
.alert > *:first-child,
.alert-info > *:first-child {
  margin-top: 0;
}
.alert > *:last-child,
.alert-info > *:last-child {
  margin-bottom: 0;
}
.alert p,
.alert-info p {
  margin-bottom: 0;
}
.alert p + p,
.alert-info p + p {
  margin-top: 0.7368421053em;
}

.alert-error,
.errors,
#errorExplanation,
#error_explanation {
  background: #fffde7;
  border: 1px solid #fff9b4;
  margin-bottom: 1em;
  border-radius: 4px;
  padding: 0.5em 1em;
  color: #555;
  background: #fde5e5;
  border: 1px solid #cb7a7a;
  text-align: left;
}
.alert-error > *:first-child,
.errors > *:first-child,
#errorExplanation > *:first-child,
#error_explanation > *:first-child {
  margin-top: 0;
}
.alert-error > *:last-child,
.errors > *:last-child,
#errorExplanation > *:last-child,
#error_explanation > *:last-child {
  margin-bottom: 0;
}
.alert-error p,
.errors p,
#errorExplanation p,
#error_explanation p {
  margin-bottom: 0;
}
.alert-error p + p,
.errors p + p,
#errorExplanation p + p,
#error_explanation p + p {
  margin-top: 0.7368421053em;
}

.error label,
.fieldWithErrors label,
.field_with_erors label {
  color: #990000;
}
.error input.text,
.error input[type=text],
.error input[type=email],
.error input[type=url],
.error input[type=tel],
.error input[type=password],
.error textarea,
.fieldWithErrors input.text,
.fieldWithErrors input[type=text],
.fieldWithErrors input[type=email],
.fieldWithErrors input[type=url],
.fieldWithErrors input[type=tel],
.fieldWithErrors input[type=password],
.fieldWithErrors textarea,
.field_with_erors input.text,
.field_with_erors input[type=text],
.field_with_erors input[type=email],
.field_with_erors input[type=url],
.field_with_erors input[type=tel],
.field_with_erors input[type=password],
.field_with_erors textarea {
  background: #fde5e5;
}

.anim-container {
  overflow-x: hidden;
}

@media (min-width: 760px) {
  *[class*=introanim] {
    transition: all 0.75s ease-in-out;
  }
  *[class*=introanim].go {
    transform: none;
    opacity: 1;
  }
  .introanim-l {
    transform: translateX(10rem);
    opacity: 0.1;
  }
  .introanim-r {
    transform: translateX(-10rem);
    opacity: 0.1;
  }
  .introanim-up {
    transform: translateY(6.5rem);
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    opacity: 0.1;
  }
  .introanim-cascade > *:nth-child(1) {
    transition-delay: 0s;
  }
  .introanim-cascade > *:nth-child(2) {
    transition-delay: 0.075s;
  }
  .introanim-cascade > *:nth-child(3) {
    transition-delay: 0.15s;
  }
  .introanim-cascade > *:nth-child(4) {
    transition-delay: 0.225s;
  }
  .introanim-cascade > *:nth-child(5) {
    transition-delay: 0.3s;
  }
  .introanim-cascade > *:nth-child(6) {
    transition-delay: 0.375s;
  }
  .introanim-cascade > *:nth-child(7) {
    transition-delay: 0.45s;
  }
  .introanim-cascade > *:nth-child(8) {
    transition-delay: 0.525s;
  }
  .introanim-cascade > *:nth-child(9) {
    transition-delay: 0.6s;
  }
  .introanim-cascade > *:nth-child(10) {
    transition-delay: 0.675s;
  }
}
.badge {
  background-color: #00c3a9;
  color: #fff;
  font-weight: bold;
  font-size: 0.6rem;
  display: inline-block;
  text-align: center;
  padding: 0.125em 1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 1.25em;
}

.badge-yellow {
  background-color: #ffd738;
  color: #222;
}

.badge-alt {
  border-radius: 0.5em;
}

.badge-wide {
  padding-left: 2em;
  padding-right: 2em;
}

.badge[class*=in-person] {
  background-color: #ffa728;
}

.badge[class*=live-online] {
  background-color: #fb606e;
}

.badge[class*=on-demand-video] {
  background-color: #25578b;
}

.badge[class*=certified] {
  background-color: #40a4b3;
}

.badge-large,
.badge-xl {
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
}

.badge-xl {
  font-size: 1.25em;
}

.box {
  padding: 1em;
  border-radius: 0.35rem;
  border: 1px solid #d5efff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box * {
  max-width: 100%;
}

.box-info {
  background-color: #f2f7fc;
}

.box-white {
  background-color: #fff;
}

.box-extra-padding {
  padding: 2em;
}

.box-footer {
  margin-top: auto;
  width: 100%;
}

.box-slideup {
  margin-top: -4em;
  margin-bottom: 4em;
}

.box-mountains {
  background-image: url(/mgs_assets/images/v4/mountains.svg);
  background-position: 110% 100%;
  background-repeat: no-repeat;
}

.ghost-box {
  border-color: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.09);
}

.highlight-box {
  margin-top: 1.4736842105em;
  padding-top: 0.7368421053em;
  padding-bottom: 0.7368421053em;
  margin-bottom: 1.4736842105em;
  border-radius: 4px;
  background-color: #fffccd;
  padding-left: 1em;
  padding-right: 1em;
  color: #333;
}
.highlight-box > *:first-child {
  margin-top: 0;
}
.highlight-box > *:last-child {
  margin-bottom: 0;
}
.highlight-box a {
  color: #4797eb !important;
}

.feature-box-small {
  margin-top: 1.4736842105em;
  padding-top: 0.7368421053em;
  padding-bottom: 0.7368421053em;
  margin-bottom: 1.4736842105em;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.35rem;
  padding-left: 1em;
  padding-right: 1em;
  border: 1px solid #d0dae2;
}

.feature-box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 17px rgba(0, 0, 0, 0.2);
}

.course-summary-box {
  position: relative;
  max-width: 400px;
}
.course-summary-box > .media {
  gap: 0;
}
@media (min-width: 900px) {
  .course-summary-box .media-item {
    margin-top: -2.5em;
  }
}
.course-summary-box .block-link {
  height: 100%;
}
.course-summary-box .free-ribbon {
  position: absolute;
  top: -2px;
  right: -2px;
}
.course-summary-box .box-footer {
  display: none;
  align-self: stretch;
  margin-top: auto;
}
@media (min-width: 900px) {
  .course-summary-box .box-footer {
    display: block;
  }
}
.course-summary-box .box-footer a {
  color: inherit;
}

.course-summary-box-horizontal {
  max-width: none;
}
.course-summary-box-horizontal > .media {
  flex-direction: row;
  text-align: left;
  align-items: start;
  gap: 1em;
}
.course-summary-box-horizontal .media-item {
  margin-top: -0.5em;
  flex-basis: 20%;
}
.course-summary-box-horizontal .media-body {
  flex: 1 0 75%;
}
.course-summary-box-horizontal .cluster-centered {
  justify-content: start;
}
.course-summary-box-horizontal .box-footer {
  padding-top: 0.5em;
}
.course-summary-box-horizontal .box-footer .button {
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  color: #4797eb !important;
}
.course-summary-box-horizontal .box-footer .button::after {
  content: " »";
}

.courses-grid-roles-callout {
  grid-column: 1/-1;
  width: 100%;
  background-color: #00c3a9;
  color: #fff;
  font-size: 1.3em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em 2em;
  position: relative;
}
.courses-grid-roles-callout img {
  margin-block: -2em;
  max-width: 3em;
}

.breadcrumbs {
  padding-bottom: 0.7368421053em;
  height: 42px;
  line-height: 42px;
  padding-left: 1.5384615383%;
  display: none;
}
.breadcrumbs[class*=stripe-] {
  border: none;
}
@media (min-width: 600px) {
  .breadcrumbs {
    display: block;
  }
}
.breadcrumbs ol {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.breadcrumbs ol li {
  display: inline-block;
  list-style: none;
  margin-left: 0;
  white-space: nowrap;
}
.breadcrumbs li {
  margin-right: 0.25em;
}
.breadcrumbs li:after {
  content: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%227px%22%20height%3D%2210px%22%20viewBox%3D%220%200%207%2010%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%3E%20%20%20%20%20%20%20%20%3Ctitle%3Ebreadcrumb-divider%3C%2Ftitle%3E%20%20%20%20%3Cdescription%3ECreated%20with%20Sketch.%3C%2Fdescription%3E%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%20%20%20%20%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20sketch%3Atype%3D%22MSPage%22%3E%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M2.00000787%2C0.999976397%20C1.9479244%2C0.999976397%201.90625762%2C1.02080978%201.86459085%2C1.05205987%20L0.552087404%2C2.36456331%20C0.479170546%2C2.43748017%200.479170546%2C2.56248049%200.552087404%2C2.63539735%20L3.41667825%2C5.4999882%20L0.552087404%2C8.36457904%20C0.479170546%2C8.4374959%200.479170546%2C8.56249623%200.552087404%2C8.63541309%20L1.86459085%2C9.94791653%20C1.9375077%2C10.0208334%202.06250803%2C10.0208334%202.13542489%2C9.94791653%20L6.4479362%2C5.63540522%20C6.52085306%2C5.56248836%206.52085306%2C5.43748803%206.4479362%2C5.36457118%20L2.13542489%2C1.05205987%20C2.09375811%2C1.02080978%202.05209134%2C0.999976397%202.00000787%2C0.999976397%20L2.00000787%2C0.999976397%20Z%22%20id%3D%22breadcrumb-divider%22%20fill%3D%22%23BAC5CF%22%20sketch%3Atype%3D%22MSShapeGroup%22%3E%3C%2Fpath%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E");
  display: inline-block;
  position: relative;
  margin-left: 1em;
  margin-right: 0.2em;
}
.breadcrumbs li:last-child:after {
  content: "";
}
.breadcrumbs a {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  transition-duration: 0;
  color: #65bef6;
  text-transform: capitalize;
  text-decoration: none;
}
@media (min-width: 900px) {
  .breadcrumbs a {
    font-size: 0.8rem;
  }
}
.breadcrumbs a:hover {
  text-decoration: underline;
}
.breadcrumbs .breadcrumb-home {
  position: relative;
  left: 0.35em;
  background-repeat: no-repeat;
  display: inline-block;
  width: 12px;
  height: 13px;
}
.breadcrumbs .breadcrumb-home:hover {
  border-bottom: none;
}
.breadcrumbs .breadcrumb-home svg {
  fill: #65bef6;
}

.stripe-green .breadcrumbs a,
.stripe-green.breadcrumbs a {
  color: #fff;
}

.stripe-blue .breadcrumbs a,
.stripe-blue.breadcrumbs a {
  color: #e3eaf1;
}

.breadcrumbs-pullup {
  margin-bottom: -42px;
  position: relative;
  z-index: 1;
}

.breadcrumbs-light a {
  color: white;
}
.breadcrumbs-light .breadcrumb-home svg {
  fill: white;
}

.breadcrumbs-dark a {
  color: #2c7acb;
}
.breadcrumbs-dark .breadcrumb-home svg {
  fill: #2c7acb;
}

.breadcrumbs-fade {
  transition: opacity 0.5s;
  opacity: 0.6;
}
.breadcrumbs-fade:hover {
  opacity: 1;
}

.button {
  background-color: #4797eb;
  color: white !important;
  border-color: #a3cbf5;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
}
.button:hover {
  color: white !important;
  background-color: #0c7df3;
}
@media (min-width: 900px) {
  .button {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button {
    white-space: nowrap;
  }
}
.button > i[class*=icon-] {
  vertical-align: middle;
  margin-right: 0.25em;
  margin-left: -0.5em;
}

.button:has(svg),
.button:has(img) {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.button-yellow {
  background-color: #fec60b;
  color: #222 !important;
  border-color: #fede71;
}
.button-yellow:hover {
  color: #222 !important;
  background-color: #fed857;
}

.button-disabled,
.button[disabled] {
  background-color: #dbe3e5;
  color: #808080 !important;
  border-color: white;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  cursor: default;
}
.button-disabled:hover,
.button[disabled]:hover {
  color: #808080 !important;
  background-color: #dbe3e5;
}
@media (min-width: 900px) {
  .button-disabled,
  .button[disabled] {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-disabled,
  .button[disabled] {
    white-space: nowrap;
  }
}

.button-invert {
  background-color: white;
  color: #00c3a9 !important;
  border-color: white;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
}
.button-invert:hover {
  color: #00c3a9 !important;
  background-color: #dcfffa;
}
@media (min-width: 900px) {
  .button-invert {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-invert {
    white-space: nowrap;
  }
}

.button-ghost {
  background-color: white;
  color: #00c3a9 !important;
  border-color: white;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  border: 1px solid #00c3a9;
}
.button-ghost:hover {
  color: #00c3a9 !important;
  background-color: #dcfffa;
}
@media (min-width: 900px) {
  .button-ghost {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-ghost {
    white-space: nowrap;
  }
}

.button-ghost-invert {
  background-color: transparent;
  color: #fff !important;
  border-color: rgba(51, 51, 51, 0);
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  border: 1px solid #65bef6;
}
.button-ghost-invert:hover {
  color: #fff !important;
  background-color: #2c7acb;
}
@media (min-width: 900px) {
  .button-ghost-invert {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-ghost-invert {
    white-space: nowrap;
  }
}

.button-ghost-alt {
  background-color: white;
  color: #4797eb !important;
  border-color: white;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  border: 1px solid #4797eb;
}
.button-ghost-alt:hover {
  color: #4797eb !important;
  background-color: #d5efff;
}
@media (min-width: 900px) {
  .button-ghost-alt {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-ghost-alt {
    white-space: nowrap;
  }
}

.button-alt,
.button-teal {
  background-color: #00c3a9;
  color: white !important;
  border-color: #2affe3;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  border: 1px solid #00c3a9;
}
.button-alt:hover,
.button-teal:hover {
  color: white !important;
  background-color: #00c3a9;
}
@media (min-width: 900px) {
  .button-alt,
  .button-teal {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-alt,
  .button-teal {
    white-space: nowrap;
  }
}

.button-error {
  background-color: #bf0013;
  color: white !important;
  border-color: #ff263c;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
}
.button-error:hover {
  color: white !important;
  background-color: #8c000e;
}
@media (min-width: 900px) {
  .button-error {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-error {
    white-space: nowrap;
  }
}

.button-transparent {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff !important;
  border-color: rgba(51, 51, 51, 0.4);
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  border: 1px solid #fff;
}
.button-transparent:hover {
  color: #fff !important;
  background-color: #000;
}
@media (min-width: 900px) {
  .button-transparent {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-transparent {
    white-space: nowrap;
  }
}

.button-shadow,
.button.has-shadow {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.button-tags {
  background-color: #1d446c;
  color: white !important;
  border-color: #3377bc;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  border: 1px solid #1d446c;
  opacity: 0.85;
  text-transform: uppercase;
  line-height: normal;
  padding: 0.4em 1em;
  line-height: 1.4736842105;
  font-size: 0.8rem;
}
.button-tags:hover {
  color: white !important;
  background-color: #1d446c;
}
@media (min-width: 900px) {
  .button-tags {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-tags {
    white-space: nowrap;
  }
}
@media (min-width: 900px) {
  .button-tags {
    font-size: 0.8rem;
  }
}

.button-tags-invert {
  background-color: white;
  color: #25578b !important;
  border-color: white;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  line-height: 1.4736842105;
  font-size: 0.85rem;
  line-height: 1.2;
  -webkit-appearance: none;
  transition: all 0.25s;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: "interface", sans-serif;
  border: 1px solid white;
  text-transform: uppercase;
  line-height: normal;
  padding: 0.4em 1em;
  line-height: 1.4736842105;
  font-size: 0.8rem;
}
.button-tags-invert:hover {
  color: #25578b !important;
  background-color: white;
}
@media (min-width: 900px) {
  .button-tags-invert {
    font-size: 1rem;
  }
}
@media (min-width: 600px) {
  .button-tags-invert {
    white-space: nowrap;
  }
}
@media (min-width: 900px) {
  .button-tags-invert {
    font-size: 0.8rem;
  }
}

.button-small {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  padding-top: 0.275em;
  padding-bottom: 0.275em;
}
@media (min-width: 900px) {
  .button-small {
    font-size: 0.8rem;
  }
}

.button-large {
  line-height: 1.4736842105;
  font-size: 1.0625rem;
}
@media (min-width: 900px) {
  .button-large {
    font-size: 1.25rem;
  }
}

.button-wide {
  white-space: normal;
}
@media (min-width: 600px) {
  .button-wide {
    padding-left: 3em;
    padding-right: 3em;
  }
}

.button-full {
  display: block;
  width: 100%;
}

.button-double {
  *zoom: 1;
  text-align: center;
}
.button-double:after {
  content: "";
  display: table;
  clear: both;
}
.button-double li {
  list-style: none;
  padding: 0 0;
}
.button-double li:first-child {
  padding-left: 0;
}
.button-double li:last-child {
  padding-right: 0;
}
.button-double > li {
  float: left;
  width: 50%;
  padding: 0 1em;
}
.button-double > li:last-child {
  float: right;
}
.button-double .button {
  width: 100%;
}

.card {
  background: white;
  border-radius: 4px;
  border: 1px solid #dbe3e5;
  font-size: 0.9rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
.card > *:first-child:not(.card-hero) {
  padding-top: 1rem;
}
.card > *:last-child {
  padding-bottom: 1rem;
}

a.card {
  text-decoration: none;
  color: inherit;
  transition: all 0.25s;
}
a.card:hover {
  border-color: #afc0c5;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.25em 3px;
  transform: translateY(-0.25em);
  background-color: #fcfcfc;
}
a.card > * {
  width: 100%;
}

.card-hero {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.card-title {
  font-size: 1.5em;
  line-height: 1.2;
}

.card-footer {
  margin-top: auto;
}

@media (max-width: 760px) {
  .card-switcher {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .card-switcher .card-hero {
    flex: 3;
    align-self: stretch;
    margin-right: 0;
  }
  .card-switcher .card-title {
    flex: 5;
    margin-top: 0;
    font-size: 1.2em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    font-weight: 400;
  }
  .card-switcher .card-switcher-full {
    flex-basis: 100%;
  }
  .card-switcher .card-switcher-hide {
    display: none;
  }
  .card-switcher .text-center {
    text-align: left;
  }
  .card-switcher .card-switcher-inset {
    background: #f9fbfc;
    margin-top: 0;
    padding-top: 0.75rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    color: #647587;
    height: 100%;
  }
}
@media (min-width: 600px) {
  .card-horizontal {
    flex-direction: row;
  }
  .card-horizontal .card-hero {
    max-width: 33%;
  }
}
.carousel-container {
  margin-top: 1.4736842105em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 1.4736842105em;
  position: relative;
}
@media (min-width: 900px) {
  .carousel-container {
    margin-top: 2.9473684211em;
    padding-top: 0em;
    padding-bottom: 0em;
    margin-bottom: 2.9473684211em;
  }
}

.slick-slide {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.slick-dots {
  bottom: auto;
}

.slick-dots li button::before {
  background: #2c7acb;
  border-radius: 50%;
  width: 0.25rem;
  height: 0.25rem;
  content: "";
}

@media (min-width: 900px) {
  .slick-dots {
    bottom: -30px;
  }
  .slick-dots li button::before {
    width: 0.5rem;
    height: 0.5rem;
  }
}
.slick-prev,
.slick-next,
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  background: transparent url("/mgs_assets/images/v4/carousel-arrow.svg") 0 0 no-repeat;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  width: 48px;
  height: 82px;
  transition: opacity 0.25s;
  opacity: 0.15;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  transform: translateY(-50%) rotate(180deg);
  left: auto;
  right: -40px;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.3;
}

.clients-carousel .client-logo {
  display: block;
  padding: 0 1rem;
}
.clients-carousel .client-logo img {
  display: block;
}
@media (min-width: 900px) {
  .clients-carousel .slick-slide {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.testimonial-tab-nav-carousel .testimonial-avatar {
  margin: 2rem auto;
  border: 3px solid transparent;
  transition: all 0.25s;
  transform: scale(1);
}
.testimonial-tab-nav-carousel .slick-current .testimonial-avatar {
  transform: scale(1.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.testimonial-tab-nav-carousel .slick-current .testimonial-avatar,
.testimonial-tab-nav-carousel .slick-active:active .testimonial-avatar {
  border-color: white;
}
.testimonial-tab-nav-carousel .slick-active:active {
  outline: none;
}

.collapse:not(.show) {
  display: none;
}

.collapse.in {
  display: block;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.35s;
  transition-timing-function: ease;
}

.features-grid {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 1.4736842105em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 1.4736842105em;
}
.features-grid li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.features-grid > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@supports (display: grid) {
  .features-grid > ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
.features-grid strong {
  margin-bottom: 0.3684210526em;
  display: block;
}
.features-grid li {
  padding: 1em;
  flex: 0 0 100%;
  height: 100%;
}
.features-grid li:first-child {
  border-top: none;
}
@media (min-width: 600px) {
  .features-grid li {
    flex-basis: 50%;
    border-top: 1px solid #65bef6;
    border-right: 1px solid #65bef6;
  }
  .features-grid li:nth-child(2) {
    border-top: none;
  }
  .features-grid li:nth-child(2n) {
    border-right-width: 0;
  }
}
@media (min-width: 900px) {
  .features-grid li {
    flex-basis: 32%;
  }
  .features-grid li:nth-child(3) {
    border-top: none;
  }
  .features-grid li:nth-child(2n) {
    border-right-width: 1px;
  }
  .features-grid li:nth-child(3n) {
    border-right: none;
  }
}

.modules {
  display: flex;
  flex-wrap: wrap;
}

.module, .module-small {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .module, .module-small {
    width: 50%;
    padding: 0 1rem;
  }
}

@media (min-width: 600px) {
  .module-small {
    width: 50%;
    padding: 0 1rem;
  }
}
@media (min-width: 900px) {
  .module-small {
    width: 25%;
  }
}

.video-modules > .container {
  display: flex;
  flex-wrap: wrap;
}
.video-modules .module, .video-modules .module-small {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  margin-bottom: 1.4736842105em;
  border-radius: 4px;
  background: white;
  padding: 14px;
  text-align: left;
}
@media (min-width: 900px) {
  .video-modules .module, .video-modules .module-small {
    font-size: 0.8rem;
  }
}
@media (min-width: 900px) {
  .video-modules .module, .video-modules .module-small {
    width: 47%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}
.video-modules .module > h2, .video-modules .module-small > h2 {
  margin-top: 0;
}

.modules--leader .module, .modules--leader .module-small {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  margin-bottom: 1.4736842105em;
  border-radius: 4px;
  border: 1px solid #65bef6;
  background: white;
  padding: 14px;
  text-align: left;
}
@media (min-width: 900px) {
  .modules--leader .module, .modules--leader .module-small {
    font-size: 0.8rem;
  }
}
@media (min-width: 900px) {
  .modules--leader .module, .modules--leader .module-small {
    width: 47%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}
.modules--leader .module img, .modules--leader .module-small img, .modules--leader .module h4, .modules--leader .module-small h4 {
  text-align: center;
}
.modules--leader .module > img, .modules--leader .module-small > img {
  align-self: center;
}
.modules--leader .module > h4, .modules--leader .module-small > h4 {
  margin: 0.5em 0 0.3em;
}

.discounts-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.discounts-header h2 {
  color: #4c4d52;
  margin: auto 0;
}

.discount {
  border-radius: 6px;
  text-align: center;
  padding: 1em 2em;
  border: 2px dashed #00c3a9;
  background-color: white;
}

.save {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  color: #4797eb;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  letter-spacing: 1px;
  margin-bottom: 0.5em;
}
@media (min-width: 900px) {
  .save {
    font-size: 0.8rem;
  }
}

.dollar-sign,
.discount-price,
.discount-per {
  color: #00c3a9;
  font-weight: 300;
}

.dollar-sign {
  font-size: 1.68em;
  line-height: 0.875em;
  position: relative;
  top: -0.4em;
}

.discount-price {
  font-size: 3.36em;
  line-height: 0.4375em;
  font-weight: 500;
  position: relative;
  top: 0.1em;
}

.discount-per {
  max-width: 3em;
  display: inline-block;
  text-align: left;
  overflow: visible;
  line-height: 1;
}

.discount-description {
  display: block;
  margin-top: 0.5em;
  line-height: 1.25;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin: 0;
  border-radius: 4px;
  background-color: #f9fbfc;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  z-index: 999;
  padding: 5px;
}
.dropdown-menu li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.dropdown-menu.centered {
  left: 50%;
  width: 200px;
  margin-left: -100px;
}
.dropdown-menu.has-triangle:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f9fbfc;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
}
.open > .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  font-weight: 500;
  display: block;
  color: #333;
  margin-bottom: 2px;
  padding: 0.5em 1em;
}
@media (min-width: 900px) {
  .dropdown-menu li a {
    font-size: 0.8rem;
  }
}
.dropdown-menu li a:active, .dropdown-menu li a:focus {
  outline: none;
}
.dropdown-menu li a:hover {
  background: #dbe9d0;
}

.icon-hidetext {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
}

*[class*=button-icon-] {
  padding-left: 2.5em !important;
  background-position: 0.75em 50%;
}

*[class*=heading-icon-] {
  padding-left: 1.5em;
  background-position: 0 50%;
}

i.icon-64 {
  display: block;
  width: 80px;
  height: 80px;
}
i.icon-64 img {
  display: block;
  width: 64px;
  max-width: 64px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.iconcircle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #65bef6;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.iconcircle > * {
  display: block;
  margin: 0 auto;
}
.iconcircle-small, .iconcircle-xs {
  width: 148px;
  height: 148px;
}
.iconcircle-small img, .iconcircle-xs img {
  transform: scale(0.8);
}
.iconcircle-xs {
  width: 108px;
  height: 108px;
}
.iconcircle-light {
  background-color: #d5efff;
}
.iconcircle-white {
  background-color: #fff;
}

a.iconcircle:hover {
  background-color: #4797eb;
}

.icon {
  font-size: 1.25em;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: #2C7ACB;
}

.icon-white {
  fill: #fff;
}

.icon-large {
  font-size: 5em;
}

.stripe-features h3,
.stripe-features h4,
.stripe-features h5 {
  font-size: 1.1em;
  color: #4c4d52;
  font-weight: bold;
  margin-bottom: 0;
}

.media-feature.left .media-item,
.media-feature.left-small .media-item {
  align-self: flex-start;
}

.media-feature.left-small img {
  max-width: 5rem;
}

.media-feature.center-bgrect .media-item {
  width: 100%;
}

.filters {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.filters > * {
  margin-top: 0.125em;
  margin-bottom: 0.125em;
}
.filters > .field {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.filters label {
  width: auto;
  margin: 0 0.5em;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
}
.filters .chosen-label {
  line-height: 40px;
}
.filters .chosen-container {
  text-align: left;
  z-index: 1;
}
.filters .chosen-container + input[type=submit] {
  vertical-align: top;
}
.filters .chosen-container-multi .chosen-choices li {
  clear: none;
}
.filters .chosen-container-multi .chosen-choices {
  border-radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.filters .search-choice-close {
  transition: none;
  border: none;
}
.filters .chosen-single {
  height: 40px;
  padding-top: 7px;
}
.filters .selectboxit-container .selectboxit,
.filters .selectboxit-container .selectboxit-options a,
.filters .selectboxit-container span {
  height: 40px;
  line-height: 40px;
}
.filters .selectboxit-container .selectboxit-text {
  color: #333;
}
.filters .filters-submit {
  transition: all 0.25s;
  position: relative;
  left: -0.5em;
  opacity: 0;
  z-index: 0;
  height: 40px;
  padding: 0 0.75em;
}
.filters .is-changed .filters-submit {
  left: 0.25em;
  opacity: 1;
}

.selectboxit-option-anchor {
  border-bottom: none !important;
}

.filters-tags {
  display: none;
}
@media (min-width: 600px) {
  .filters-tags {
    display: block;
  }
}

.filter-tags-form {
  height: 1px;
  overflow: hidden;
}

.filter-tags-form.is-chosen-ready {
  height: auto;
  overflow: visible;
}

.floating-cta {
  position: fixed;
  bottom: 2em;
  right: 0;
  opacity: 1;
  background-color: #ededed;
  padding: 0.5em 0.65em;
  border-radius: 0.35em 0 0 0.35em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 8px 10px rgba(0, 0, 0, 0.2);
  transition: right 0.5s, opacity 0.25s;
  display: none;
  z-index: 400;
}
@media (min-width: 900px) {
  .floating-cta {
    display: block;
  }
}
.floating-cta.hidden {
  right: -100px;
  opacity: 0;
  pointer-events: none;
  display: block !important;
}
.floating-cta .cluster {
  --gap: .5em;
}
.floating-cta .button {
  padding-top: 0;
  padding-bottom: 0;
}
.floating-cta .button,
.floating-cta .selectboxit-container span {
  font-size: 0.85em;
  height: 40px;
  line-height: 40px;
}
.floating-cta .button,
.floating-cta .selectboxit {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  min-width: 16ch;
}
.floating-cta .text-small {
  font-size: 0.7em;
}

input.text,
input[type=text],
input[type=email],
input[type=url],
input[type=tel],
input[type=password],
textarea {
  border-radius: 3px;
  font-family: "interface", sans-serif;
  font-size: 19px;
  line-height: 28px;
  border: 1px solid #c6c7c0;
  margin: 0;
  padding: 7px 0.5em;
  width: 100%;
}
@media (min-width: 400px) {
  input.text,
  input[type=text],
  input[type=email],
  input[type=url],
  input[type=tel],
  input[type=password],
  textarea {
    width: 70%;
  }
  input.tiny.text,
  input.tiny[type=text],
  input.tiny[type=email],
  input.tiny[type=url],
  input.tiny[type=tel],
  input.tiny[type=password],
  textarea.tiny {
    width: 10%;
  }
  input.short.text,
  input.short[type=text],
  input.short[type=email],
  input.short[type=url],
  input.short[type=tel],
  input.short[type=password],
  textarea.short {
    width: 31.1111111111%;
  }
  input.large.text,
  input.large[type=text],
  input.large[type=email],
  input.large[type=url],
  input.large[type=tel],
  input.large[type=password],
  textarea.large {
    width: 87.5%;
  }
}
input.bigtext.text,
input.bigtext[type=text],
input.bigtext[type=email],
input.bigtext[type=url],
input.bigtext[type=tel],
input.bigtext[type=password],
textarea.bigtext {
  font-size: 18px;
  line-height: 56px;
}
input.default.text,
input.default[type=text],
input.default[type=email],
input.default[type=url],
input.default[type=tel],
input.default[type=password],
textarea.default, input.placeholder.text,
input.placeholder[type=text],
input.placeholder[type=email],
input.placeholder[type=url],
input.placeholder[type=tel],
input.placeholder[type=password],
textarea.placeholder {
  color: #999999;
}
input.text:active,
input[type=text]:active,
input[type=email]:active,
input[type=url]:active,
input[type=tel]:active,
input[type=password]:active,
textarea:active, input.text:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=tel]:focus,
input[type=password]:focus,
textarea:focus, input.text:hover,
input[type=text]:hover,
input[type=email]:hover,
input[type=url]:hover,
input[type=tel]:hover,
input[type=password]:hover,
textarea:hover {
  border-color: #555555;
}
input[disabled].text,
input[disabled][type=text],
input[disabled][type=email],
input[disabled][type=url],
input[disabled][type=tel],
input[disabled][type=password],
textarea[disabled] {
  color: #abb4b9;
  border-color: #d3d8da;
  background-color: #f7f7f7;
  cursor: default;
}

fieldset.no_margin,
fieldset li.no_margin {
  margin-bottom: 0;
}

fieldset {
  overflow: hidden;
  margin-bottom: 1.4736842105em;
  border: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

form p {
  margin-bottom: 1.4736842105em;
}
form ol, form ul {
  float: left;
  clear: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
form ol.ruled, form ul.ruled {
  border-top: 1px solid #eff9ff;
}
form ol.ruled > li, form ul.ruled > li {
  padding: 0.5em 0;
  margin-bottom: 0;
  border-bottom: 1px solid #eff9ff;
}
form ol li, form ul li {
  float: left;
  clear: left;
  width: 100%;
  margin: 0 0 14px;
  /* groups of inputs (usually checkboxes or radio buttons) */
  /* instructions beneath the input */
}
form ol li .inputgroup, form ul li .inputgroup {
  margin-left: 25%;
  padding-top: 0.5em;
}
form ol li ul.inputgroup, form ul li ul.inputgroup {
  clear: none;
  list-style: none;
  margin-bottom: 0;
}
form ol li ul.inputgroup li, form ul li ul.inputgroup li {
  margin-bottom: 0;
}
form ol li ul.inputgroup li label, form ul li ul.inputgroup li label {
  width: auto;
  font-weight: normal;
}
form ol li .instructions, form ul li .instructions {
  float: left;
  margin-right: 0.5em;
  font-size: 0.91em;
}
form ol li .instructions.optional, form ul li .instructions.optional {
  color: #abb4b9;
  font-style: italic;
  font-size: 12px;
}
form ol li .instructions label, form ul li .instructions label {
  float: none;
  clear: both;
  display: block;
  margin: 0;
  padding-top: 4px;
  width: auto;
  font-size: 0.91em;
  font-weight: normal;
  line-height: 1;
  color: #777777;
}

@media (min-width: 400px) {
  p.submit,
  div.submit,
  fieldset.submit,
  li.submit {
    padding-left: 27%;
  }
}
p.submit input,
div.submit input,
fieldset.submit input,
li.submit input {
  display: inline;
}

@media (min-width: 400px) {
  .no-label {
    padding-left: 27%;
  }
}

legend span {
  display: block;
  line-height: 22px;
  font-weight: bold;
  font-family: "interface", sans-serif;
  padding-bottom: 1.2em;
  white-space: normal;
}

label {
  display: block;
  float: none;
  width: auto;
  margin: 0;
  font-weight: bold;
  padding: 4px 0;
}
@media (min-width: 400px) {
  label {
    float: left;
    margin-right: 2%;
    width: 25%;
  }
}

textarea {
  height: 8em;
}

select {
  margin: 4px 0;
}

.checkboxes,
.radios,
.choices-group {
  margin-left: 0;
}
.checkboxes li,
.radios li,
.choices-group li {
  margin-bottom: 0;
}
.checkboxes input[type=checkbox],
.checkboxes input[type=radio],
.radios input[type=checkbox],
.radios input[type=radio],
.choices-group input[type=checkbox],
.choices-group input[type=radio] {
  margin-right: 0.5em;
  display: inline;
}
.checkboxes input[type=radio],
.radios input[type=radio],
.choices-group input[type=radio] {
  vertical-align: baseline;
}
.checkboxes label,
.radios label,
.choices-group label {
  float: none;
  width: auto;
  display: inline;
  font-weight: normal;
}

input.checkbox, input[type=checkbox] {
  margin-right: 0.5em;
  display: inline-block;
}

label.checkbox {
  float: none;
  width: auto;
  display: inline-block;
}

/* Top Labels
---------------------------------------- */
.top_labels ul, .top_labels ol, .top_labels .instructions,
.stacked ul,
form.email-signup-horizontal ul,
.stacked ol,
form.email-signup-horizontal ol,
.stacked .instructions,
form.email-signup-horizontal .instructions {
  float: none;
}
.top_labels li,
.stacked li,
form.email-signup-horizontal li {
  float: none;
  clear: both;
  width: 100%;
  margin: 0 0 1.2em;
}
.top_labels li li,
.stacked li li,
form.email-signup-horizontal li li {
  margin-bottom: 0;
}
.top_labels label,
.stacked label,
form.email-signup-horizontal label {
  display: block;
  float: none;
  width: auto;
  margin: 0;
}
.top_labels .checkboxes li,
.top_labels .radios li,
.top_labels .choices-group li,
.stacked .checkboxes li,
form.email-signup-horizontal .checkboxes li,
.stacked .radios li,
form.email-signup-horizontal .radios li,
.stacked .choices-group li,
form.email-signup-horizontal .choices-group li {
  margin-bottom: 0.7368421053em;
}
.top_labels .checkboxes label,
.top_labels .radios label,
.top_labels .choices-group label,
.stacked .checkboxes label,
form.email-signup-horizontal .checkboxes label,
.stacked .radios label,
form.email-signup-horizontal .radios label,
.stacked .choices-group label,
form.email-signup-horizontal .choices-group label {
  display: inline;
}
.top_labels input.text,
.top_labels input[type=text],
.top_labels input[type=email],
.top_labels input[type=url],
.top_labels input[type=tel],
.top_labels textarea,
.stacked input.text,
form.email-signup-horizontal input.text,
.stacked input[type=text],
form.email-signup-horizontal input[type=text],
.stacked input[type=email],
form.email-signup-horizontal input[type=email],
.stacked input[type=url],
form.email-signup-horizontal input[type=url],
.stacked input[type=tel],
form.email-signup-horizontal input[type=tel],
.stacked textarea,
form.email-signup-horizontal textarea {
  margin-left: 0;
  width: 100%;
}
.top_labels .submit, .top_labels.submit,
.stacked .submit,
form.email-signup-horizontal .submit,
.stacked.submit,
form.submit.email-signup-horizontal {
  padding-left: 0;
}

/* Wide Labels
---------------------------------------- */
.wide-labels li > label {
  width: 45%;
}
.wide-labels input.text,
.wide-labels input[type=text],
.wide-labels input[type=email],
.wide-labels input[type=url],
.wide-labels input[type=tel],
.wide-labels input[type=password],
.wide-labels textarea {
  width: 50%;
}
.wide-labels .submit,
.wide-labels .no-label {
  padding-left: 47%;
}

.button-radios {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 900px) {
  .button-radios {
    flex-direction: row;
  }
}
.button-radios li {
  flex: 1 0 0;
  padding: 0.25em;
}
.button-radios label {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  transition: all 0.2s;
  border-radius: 4px;
  background: white;
  border: 1px solid #d5efff;
  padding: 1em 0.5em;
  text-align: center;
  display: block;
  cursor: pointer;
  color: #65bef6;
  position: relative;
  height: 100%;
  width: 100%;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 900px) {
  .button-radios label {
    font-size: 0.8rem;
  }
}
.button-radios label:hover {
  background-color: #d5efff;
}
.button-radios label.has-icon {
  padding-top: 2em;
}
.button-radios .button-radio-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-size: 3em;
  margin: -0.5em auto 0.25em;
}
.button-radios .button-radio-icon.fa-question-circle:before, .button-radios .button-radio-icon.fa-meh:before, .button-radios .button-radio-icon.fa-dizzy:before, .button-radios .button-radio-icon.fa-grin-stars:before {
  background-color: #fff;
  border-radius: 50%;
}
.button-radios strong {
  line-height: 1.4736842105;
  font-size: 1.53rem;
  line-height: 1.1;
  display: inline-block;
  width: 100%;
}
@media (min-width: 900px) {
  .button-radios strong {
    font-size: 1.8rem;
  }
}
.button-radios input[type=radio] {
  position: absolute;
  opacity: 0;
}
.button-radios input[type=radio]:checked + label, .button-radios input[type=radio]:checked + label:active {
  border-color: #00c3a9;
  color: #00c3a9;
  background-color: white;
}

@media (min-width: 900px) {
  form.email-signup-horizontal > p {
    display: inline-block;
    width: 31.9587628867%;
  }
  form.email-signup-horizontal > p + p {
    margin-top: 0;
    margin-left: 1.5384615383%;
  }
  form.email-signup-horizontal .button {
    width: 100%;
  }
}

#flash {
  clear: both;
  padding: 8px 10px;
  margin-bottom: 1em;
  color: #222222;
  background: #fffbbe;
  border: 1px solid #d8d056;
}
#flash.error {
  background: #fde5e5;
  border: 1px solid #cb7a7a;
}
#flash p {
  margin-bottom: 0;
}

#errorExplanation,
#error_explanation,
.error-explanation {
  clear: both;
  padding: 8px 10px;
  margin-bottom: 2em;
  color: #222222;
  background: #fde5e5;
  border: 1px solid #cb7a7a;
}
#errorExplanation h2, #errorExplanation .error-title,
#error_explanation h2,
#error_explanation .error-title,
.error-explanation h2,
.error-explanation .error-title {
  color: #990000;
  font-size: 1.4em;
  margin-bottom: 0.35em;
}
#errorExplanation p,
#error_explanation p,
.error-explanation p {
  margin-bottom: 0.8em;
}
#errorExplanation ul,
#error_explanation ul,
.error-explanation ul {
  margin-bottom: 0;
}

.fieldWithErrors label,
.field_with_erors label,
.field-with-errors label {
  color: #880000;
}
.fieldWithErrors input.text,
.fieldWithErrors input[type=text],
.fieldWithErrors input[type=email],
.fieldWithErrors input[type=url],
.fieldWithErrors input[type=tel],
.fieldWithErrors input[type=password],
.fieldWithErrors textarea,
.field_with_erors input.text,
.field_with_erors input[type=text],
.field_with_erors input[type=email],
.field_with_erors input[type=url],
.field_with_erors input[type=tel],
.field_with_erors input[type=password],
.field_with_erors textarea,
.field-with-errors input.text,
.field-with-errors input[type=text],
.field-with-errors input[type=email],
.field-with-errors input[type=url],
.field-with-errors input[type=tel],
.field-with-errors input[type=password],
.field-with-errors textarea {
  background: #fde5e5;
}

.input-group {
  display: inline-block;
  width: 70%;
}
.input-group input, .input-group textarea {
  width: 100%;
}
.input-group .error-explanation {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

form ol.address,
form ul.address {
  float: left;
  clear: none;
  width: 70%;
  margin-left: 0;
  margin-bottom: 0;
}
form ol.address .street_address,
form ol.address .locality,
form ol.address .region,
form ol.address .postal_code,
form ul.address .street_address,
form ul.address .locality,
form ul.address .region,
form ul.address .postal_code {
  float: left;
  clear: none;
}
form ol.address .street_address label,
form ol.address .locality label,
form ol.address .region label,
form ol.address .postal_code label,
form ul.address .street_address label,
form ul.address .locality label,
form ul.address .region label,
form ul.address .postal_code label {
  width: auto;
  position: relative;
  top: 42px;
  font-weight: normal;
  font-size: 15.96px;
  color: #abb4b9;
}
form ol.address .street_address input,
form ol.address .locality input,
form ol.address .region input,
form ol.address .postal_code input,
form ul.address .street_address input,
form ul.address .locality input,
form ul.address .region input,
form ul.address .postal_code input {
  margin-bottom: 0.7368421053em;
  position: relative;
  top: -1.95em;
  width: 100%;
}
form ol.address .locality,
form ul.address .locality {
  width: 50%;
}
form ol.address .region,
form ul.address .region {
  width: 20%;
  margin: 0 5%;
}
form ol.address .postal_code,
form ul.address .postal_code {
  width: 20%;
  float: left;
}
form ol.address .country label,
form ul.address .country label {
  display: none;
}

form ol.name,
form ul.name {
  float: left;
  clear: none;
  width: 70%;
  margin-left: 0;
  margin-bottom: 0.5em;
}
form ol.name .fname,
form ol.name .lname,
form ul.name .fname,
form ul.name .lname {
  float: left;
  clear: none;
  margin-right: 10px;
  margin-bottom: 0;
  width: 35%;
}
form ol.name .fname label,
form ol.name .lname label,
form ul.name .fname label,
form ul.name .lname label {
  width: auto;
  position: relative;
  top: 2.25em;
  font-weight: normal;
  font-size: 0.91em;
}
form ol.name .fname input,
form ol.name .lname input,
form ul.name .fname input,
form ul.name .lname input {
  position: relative;
  top: -1.95em;
  width: 100%;
}
form ol.name .lname,
form ul.name .lname {
  margin-right: 0;
}

.star-rating-input {
  margin-bottom: 1.4736842105em;
  font-size: 0;
  white-space: nowrap;
  display: inline-block;
  width: 120px;
  height: 24px;
  overflow: hidden;
  position: relative;
  background: url("/mgs_assets/images/v4/svg-icons-source/star.svg");
  background-size: contain;
  vertical-align: text-bottom;
}
.star-rating-input i {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 20%;
  z-index: 1;
  background: url("/mgs_assets/images/v4/svg-icons-source/star-orange.svg");
  background-size: contain;
}
.star-rating-input input {
  -moz-appearance: none;
  -webkit-appearance: none;
  opacity: 0;
  display: inline-block !important;
  width: 20%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 2;
  position: relative;
  cursor: pointer;
}
.star-rating-input input:hover + i, .star-rating-input input:checked + i {
  opacity: 1;
}
.star-rating-input i ~ i {
  width: 40%;
}
.star-rating-input i ~ i ~ i {
  width: 60%;
}
.star-rating-input i ~ i ~ i ~ i {
  width: 80%;
}
.star-rating-input i ~ i ~ i ~ i ~ i {
  width: 100%;
}

.star-rating-choice {
  display: inline-block;
  padding-left: 0.5em;
}

.counter {
  text-align: right;
  font-weight: bold;
}
.counter-alert {
  color: red;
}

.infield-label {
  position: relative;
}
.infield-label > label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.2s;
  opacity: 1;
}
.infield-label.hide-label > input:focus + label,
.infield-label.hide-label > input:valid + label {
  opacity: 0;
}

.custom-select {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  border: 1px solid #bbb;
  border-radius: 0.3em;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  background: #f3f3f3; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #e5e5e5));
  background: -webkit-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e5e5e5",GradientType=0 );
}
.custom-select:after {
  background: none;
  color: #bbb;
  content: "▼";
  font-size: 0.7em;
  padding: 0;
  position: absolute;
  right: 1em;
  bottom: 0.3em;
  z-index: 1;
  pointer-events: none;
}
.custom-select:hover {
  border: 1px solid #888;
}
.custom-select select {
  width: 110%;
  max-width: 110%;
  min-width: 110%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: window;
  text-indent: 0.01px;
  text-overflow: "";
  font-size: 16px;
  font-weight: bold;
  background: none;
  border: none;
  color: #444;
  outline: none;
  padding: 0.4em 19% 0.4em 0.8em;
  font-family: helvetica, sans-serif;
  line-height: 1.2;
  margin: 0.2em;
}
.custom-select select::-ms-expand {
  display: none;
}
.custom-select select:focus {
  outline: none;
  box-shadow: 0 0 3px 3px rgba(180, 222, 250, 0.85);
}
.custom-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.goatbot-question {
  --icon-size: 44px;
  --button-width: 7.5rem;
  margin-block: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5em;
  width: calc(100% - 0.5em);
}

.goatbot-question::before {
  content: "";
  width: var(--icon-size);
  height: var(--icon-size);
  background: transparent url(/mgs_assets/images/v4/goatbot-mono-small.svg) 0 0/contain no-repeat;
  align-self: start;
  margin-top: 0.5em;
}

.goatbot-question input[type=text],
.goatbot-question .question {
  flex: 1;
  background: #fff;
  border: 1px solid #b5c0cb;
  box-shadow: 0 4px 6px 0 inset rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  font-size: 1.2em;
  color: #222;
  padding: 0.5em;
  padding-inline-end: calc(var(--button-width) + 1em);
}

.goatbot-question input[type=submit],
.goatbot-question button {
  background: #4897ea;
  color: #fff;
  border: none;
  border-radius: 0.35em;
  display: block;
  padding: 0.5em 1.25em;
  margin-inline-start: auto;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
  font-size: 0.9em;
  cursor: pointer;
  font-family: "interface", sans-serif;
  width: var(--button-width);
  margin-left: calc(-1 * (var(--button-width) + 1.15em));
}

.goatbot-question .goatbot-description {
  width: 100%;
  padding-left: calc(var(--icon-size) + 0.5em);
  text-align: center;
  font-size: 0.75em;
  font-style: italic;
  opacity: 0.7;
}

.c-promo {
  container: promo/inline-size;
}

.promo-lead-magnet,
.promo-lead-magnet-stacked,
.stripe-lead-magnet {
  text-align: center;
}
.promo-lead-magnet h1,
.promo-lead-magnet-stacked h1,
.stripe-lead-magnet h1 {
  margin-top: 0em;
  padding-top: 1.4736842105em;
  padding-bottom: 0.7368421053em;
  margin-bottom: 0em;
  color: inherit;
}
.promo-lead-magnet h2,
.promo-lead-magnet-stacked h2,
.stripe-lead-magnet h2 {
  line-height: 1.4736842105;
  font-size: 1.53rem;
  margin: 0;
  padding: 0.75em 0 0.25em;
  color: inherit;
}
@media (min-width: 900px) {
  .promo-lead-magnet h2,
  .promo-lead-magnet-stacked h2,
  .stripe-lead-magnet h2 {
    font-size: 1.8rem;
  }
}

.promo-lead-magnet {
  border-radius: 4px;
  padding: 1em;
  display: grid;
  gap: 0.5em 1em;
  grid-template-columns: 1fr;
  grid-template-areas: "image" "heading" "content" "action";
}
.promo-lead-magnet .promo-heading {
  grid-area: heading;
  font-size: 1.2em;
  line-height: 1.2;
  margin-block: 0.25em;
  padding: 0;
  text-wrap: pretty;
}
.promo-lead-magnet .promo-image {
  grid-area: image;
  display: block;
  margin-inline: auto;
}
.promo-lead-magnet .promo-content {
  grid-area: content;
  font-size: 0.8em;
  text-wrap: pretty;
}
.promo-lead-magnet .promo-description {
  align-self: center;
}
.promo-lead-magnet .promo-action {
  grid-area: action;
}
.promo-lead-magnet .promo-form {
  text-align: left;
  font-size: 0.85em;
}
.promo-lead-magnet .button-full {
  max-width: 22em;
  margin-inline: auto;
}
@container promo (min-width: 440px) {
  .promo-lead-magnet {
    gap: 0 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "heading heading" "image   content" "image   action";
  }
  .promo-lead-magnet .promo-heading {
    font-size: 1.75em;
  }
  .promo-lead-magnet .promo-content {
    align-self: center;
    font-size: 0.9em;
  }
}

.promo-lead-magnet-grey {
  background-color: #f3f3f3 !important;
  color: #25578b !important;
}
.promo-lead-magnet-grey .button {
  color: #25578b !important;
}

.promo-lead-magnet-simple {
  grid-template-columns: 1fr;
  gap: 0;
  padding: 0;
}
.promo-lead-magnet-simple .promo-heading {
  padding: 1rem 1rem 0 1rem;
  font-size: 1.3em;
  text-align: left;
  line-height: 1.2;
}
.promo-lead-magnet-simple .promo-image {
  display: none;
}
.promo-lead-magnet-simple .promo-content {
  padding: 0.5rem 1rem 1rem 1rem;
  text-align: left;
}
.promo-lead-magnet-simple .promo-action {
  padding: 1rem;
  background-color: #e8f6ff;
  margin: 0;
  display: flex;
  align-items: center;
}
.promo-lead-magnet-simple .promo-form {
  text-align: left;
  font-size: 0.8em;
}
.promo-lead-magnet-simple .promo-form input[type=text],
.promo-lead-magnet-simple .promo-form input[type=email] {
  font-size: 1em;
  padding: 0.1em 0.2em;
}
@media (min-width: 600px) {
  .promo-lead-magnet-simple {
    grid-template-columns: 3fr 2fr;
  }
  .promo-lead-magnet-simple .promo-heading {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: end;
  }
  .promo-lead-magnet-simple .promo-content {
    grid-column: 1/2;
    grid-row: 2/3;
    align-self: start;
  }
  .promo-lead-magnet-simple .promo-action {
    grid-column: 2/3;
    grid-row: 1/span 2;
  }
}
.promo-lead-magnet-simple-blue {
  background-color: #d5efff !important;
  border: 1px solid #c1e5fc;
  color: #4c4d52 !important;
}
.promo-lead-magnet-simple-blue .promo-heading {
  color: #466c93;
}
.promo-lead-magnet-simple-blue .promo-action {
  background-color: #e8f6ff;
}
.promo-lead-magnet-simple-blue .button {
  background-color: #4797eb;
  color: #fff !important;
}

.stripe-content + .stripe-lead-magnet {
  padding-top: 0;
}

.lead-magnet-white {
  background-color: #fff !important;
  color: #4c4d52 !important;
  padding-left: 0;
  padding-right: 0;
}
.lead-magnet-white .promo-heading {
  padding-top: 0;
}
.lead-magnet-white input[type=email],
.lead-magnet-white input[type=text] {
  background-color: #fafafa;
}
.lead-magnet-white .button {
  box-shadow: none;
  background-color: #4797eb;
  color: #fff !important;
}

.lead-magnet-email-only .promo-image,
.lead-magnet-email-only .promo-body,
.lead-magnet-email-only .signup-first-name,
.lead-magnet-email-only .signup-checkboxes,
.lead-magnet-email-only .signup-body {
  display: none;
}
.lead-magnet-email-only .content-split {
  grid-template-columns: 1fr;
}
.lead-magnet-email-only .email-signup-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: end;
}
.lead-magnet-email-only .signup-privacy {
  grid-column: 1/3;
  grid-row: 2/3;
  margin-top: 0;
}
.lead-magnet-email-only .signup-email,
.lead-magnet-email-only .signup-submit {
  margin: 0;
}
.lead-magnet-email-only .lead-magnet-button {
  width: 100%;
}
.lead-magnet-email-only .promo-heading {
  padding-bottom: 0;
}

.lead-magnet-modal {
  margin: -30px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .lead-magnet-modal {
    flex-direction: row;
  }
}
.lead-magnet-modal .lmm-image {
  flex: 1;
  overflow: hidden;
}
@media (min-width: 600px) {
  .lead-magnet-modal .lmm-image {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
  }
}
.lead-magnet-modal .lmm-image img {
  margin: 1em auto -60px;
}
@media (min-width: 600px) {
  .lead-magnet-modal .lmm-image img {
    margin: auto;
  }
}
.lead-magnet-modal .lmm-content {
  flex: 3;
  padding: 2em;
}
@media (min-width: 600px) {
  .lead-magnet-modal .lmm-content {
    flex: 1 1 50%;
  }
}

.stripe-101-quotes {
  color: #fff;
  background-image: radial-gradient(circle farthest-side at 75% 50%, #abb6b6 0%, #415151 55%, #213333 80%);
}
.stripe-101-quotes h1 {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #dcfffa;
}
.stripe-101-quotes h2 {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #dcfffa;
}

.stripe-lm-swa,
.stripe-lm-scrummaster-interview-questions {
  color: #fff;
  background: #25578b;
}

.stripe-lm-surviving-daily-scrum {
  color: #fff;
  background: #00c3a9;
}

.stripe-lm-swa {
  color: white;
  background: #25578b url("/mgs_assets/images/blue_ripple.jpg") no-repeat center center;
  background-size: cover;
}
.stripe-lm-swa h1, .stripe-lm-swa h2, .stripe-lm-swa h3, .stripe-lm-swa h4, .stripe-lm-swa h5, .stripe-lm-swa h6,
.stripe-lm-swa a {
  color: white;
}
.stripe-lm-swa table a:hover {
  color: white !important;
}
.stripe-lm-swa td {
  border-color: #65bef6;
}

.stripe-lm-book,
.stripe-lm-sprint-review-agenda {
  padding-top: 1.4736842105em;
  color: #25578b;
  background-color: #65bef6;
}

.stripe-lm-micromanagement-log {
  padding-top: 1.4736842105em;
  background-color: #ffa827;
}
.stripe-lm-micromanagement-log h2 {
  color: white;
}

.stripe-lm-bus {
  background-color: #11253a;
  color: #dbe3e5;
  background-position: 0 50%;
}
@media (min-width: 900px) {
  .stripe-lm-bus {
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/mgs_assets/images/v4/photos/content-bg-bus.jpg");
  }
}
.stripe-lm-bus .banner-content {
  padding-top: 2.9473684211em;
  padding-bottom: 4.4210526316em;
  text-align: center;
}
@media (min-width: 900px) {
  .stripe-lm-bus .banner-content {
    width: 40.7692307693%;
    float: left;
    margin-right: 1.5384615383%;
    margin-left: 50.7692307692%;
    margin-right: 8.4615384615%;
    text-align: left;
  }
}
@media (min-width: 1170px) {
  .stripe-lm-bus .banner-content {
    width: 40.7692307693%;
    float: left;
    margin-right: 1.5384615383%;
    margin-left: 42.3076923076%;
    margin-right: 16.9230769231%;
  }
}
.stripe-lm-bus .banner-content h2 {
  margin-top: 0.7368421053em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 0.3684210526em;
  font-size: 1.8em;
  color: #65bef6;
}

.banner-lead-magnet .promo-lead-magnet {
  background-color: transparent !important;
  color: #fff;
}
.banner-lead-magnet h2 {
  font-size: 2.44em;
  font-weight: 100;
}
.banner-lead-magnet h2::after {
  content: "";
  height: 2px;
  background-color: #65bef6;
  width: 2.25em;
  display: block;
  margin: 0.35em auto 0;
}
.banner-lead-magnet .promo-content-centered {
  max-width: 60ch;
  margin: 0 auto 1em;
  font-size: 1.1em;
  text-align: center;
}

.nav-primary > li:has(.mega-menu) {
  position: static;
}

.nav-primary > li:has(.mega-menu):hover .mega-menu,
.mega-menu:hover {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.nav-primary > li:has(.mega-menu):hover > span::after {
  display: block;
}

.nav-primary > li:has(.mega-menu) > span::after {
  content: "";
  height: 1.5em;
  margin: 0px -3em;
  position: absolute;
  bottom: -1.5em;
  width: calc(100% + 6em);
  display: none;
}

.mega-menu {
  --mm-gap: 2em;
  --mm-bg-color: #f3f7fa;
  --mm-text-color: #4c4d52;
  --mm-text-color-quiet: #6189b0;
  --mm-icon-color: #65bef6;
  --mm-list-gap: .5em;
  position: absolute;
  z-index: 100;
  top: calc(var(--header-height) - 6px);
  left: 0;
  width: 100vw;
  background: var(--mm-bg-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 11px;
  border-top: 1px solid #25578b;
  transition: all 0.2s;
  transform: translateY(0.25em);
  opacity: 0;
  visibility: hidden;
  font-size: 1em;
}
.mega-menu h3 {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #4797eb;
  margin: 0;
}
.mega-menu h3 + * {
  margin-block-start: 1em;
}
.mega-menu a {
  transition-duration: 0.15s;
  text-decoration: none;
  color: var(--mm-text-color);
}
.mega-menu a:hover {
  color: #4797eb;
}
.mega-menu *:where(ul, li) {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mm-columns {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  gap: var(--mm-gap);
  padding-inline-start: 2em;
}

.mm-primary {
  padding: 1.5em 0 2em;
}
.mm-primary h3 {
  color: #00c3a9;
}
.mm-primary svg {
  transition: all 0.2s;
}
.mm-primary a {
  background-color: #fff;
  display: block;
  padding: var(--mm-list-gap) calc(var(--mm-list-gap) * 2);
  border-radius: 0.25em;
  display: block;
}
.mm-primary a:hover {
  color: #111;
  box-shadow: 0 0 1px 1px rgba(129, 181, 214, 0.5);
}
.mm-primary a:hover small {
  color: #315d88;
}
.mm-primary a:hover svg {
  transform: scale(1.1);
}
.mm-primary small {
  display: block;
  color: #6189b0;
  font-size: 0.8em;
}
.mm-primary li + li {
  margin-block-start: var(--mm-list-gap);
}
.mm-primary .media {
  gap: 0.75em;
}

.mm-secondary {
  padding: 1.5em 1em 2em;
}
.mm-secondary * + h3 {
  margin-block-start: 2em;
}
.mm-secondary li + li {
  margin-block-start: calc(var(--mm-list-gap) * 0.5);
}
.mm-secondary svg {
  transition: all 0.2s;
}
.mm-secondary a {
  display: flex;
  gap: 0.35em;
}
.mm-secondary a:hover svg {
  transform: scale(1.1);
}
.mm-secondary a:hover span {
  color: #4797eb;
}
.mm-secondary span {
  color: #65bef6;
  cursor: pointer;
}

.mm-featured {
  background-color: #e5eef3;
  padding: 1.5em 2em 2em;
}
.mm-featured h3 {
  color: #25578b;
  text-align: center;
}
.mm-featured img {
  border-radius: 0.25em;
}

.mm-featured-inner {
  margin-inline: auto;
  max-width: 440px;
}

.mm-resources .mm-primary,
.mm-resources .mm-secondary {
  grid-column: 1/3;
}
.mm-resources .mm-primary {
  grid-row: 1/2;
  padding-block-end: 0;
}
.mm-resources .mm-primary h3 {
  text-align: center;
}
.mm-resources .mm-primary ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}
.mm-resources .mm-primary li {
  margin: 0;
}
.mm-resources .mm-secondary {
  grid-row: 2/3;
  padding-block-start: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}
.mm-resources .mm-secondary h3 {
  margin-block-start: 0;
}
.mm-resources .mm-featured {
  grid-row: 1/3;
  grid-column: 3/4;
}

.mm-about {
  background: var(--mm-bg-color) url(/mgs_assets/images/v4/backgrounds/bg-mountains-mm.svg) 50% 101%/100% repeat-x;
}
.mm-about .mm-secondary {
  grid-column: 1/3;
  padding-inline: 0;
}
.mm-about .mm-secondary > h3 {
  text-align: center;
  color: #00c3a9;
}
.mm-about .mm-secondary > ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}
.mm-about .mm-secondary > ul > li {
  background-color: #fff;
  display: block;
  padding: var(--mm-list-gap) calc(var(--mm-list-gap) * 2);
  border-radius: 0.25em;
  display: block;
}
.mm-about .mm-secondary > ul h3 {
  margin-block-start: 0.5em;
}
.mm-about .mm-featured {
  grid-column: 3/4;
}

.course-description-modal header, .course-description-modal footer {
  margin: -30px;
  padding: 1em 30px;
}
.course-description-modal header {
  margin-bottom: 0;
}
@media (min-width: 900px) {
  .course-description-modal header {
    display: flex;
  }
}
.course-description-modal header .media {
  flex: 5;
}
.course-description-modal header .media-item {
  max-width: 20%;
}
.course-description-modal header .course-modal-cta {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 0.5em;
  background-color: #d5efff;
  padding: 1em;
  margin-top: 1em;
  margin-right: -30px;
  text-align: center;
}
@media (min-width: 900px) {
  .course-description-modal header .course-modal-cta {
    margin-top: -1em;
    margin-bottom: -1em;
    flex-direction: column;
  }
}
.course-description-modal footer {
  margin-top: 1em;
}
.course-description-modal .kicker {
  color: #00c3a9;
}
.course-description-modal summary:before {
  top: 0.25em;
}
.course-description-modal details:first-child {
  margin-top: -0.75em;
}

.pagination .button {
  flex: 0 1 2em;
  aspect-ratio: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #abb4b9;
  color: #abb4b9 !important;
  background-color: transparent;
}
.pagination .button.active {
  border-color: #00c3a9;
  color: #00c3a9 !important;
  background-color: #fff;
  outline: 1px solid #00c3a9;
}
.pagination .button:hover {
  background-color: #dcfffa;
}
.pagination .button.page-next {
  margin-inline-start: 1.5em;
}
.pagination .button.page-previous {
  margin-inline-end: 1.5em;
}

.panel {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  margin-bottom: 1.4736842105em;
  border: 1px solid #d5efff;
  border-radius: 4px;
  text-align: center;
  background: white;
  position: relative;
  color: #4c4d52;
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .panel {
    font-size: 0.8rem;
  }
}
.panel-highlight {
  border-color: #00c3a9;
}
.panel-ghost {
  background: rgba(255, 255, 255, 0.09);
}
.panel-shadow {
  box-shadow: 0 5px 17px rgba(0, 0, 0, 0.2);
}
.panel > a {
  text-decoration: none;
  color: #4c4d52;
}
.panel > a:hover {
  color: #4c4d52;
}
.panel .course-type-icons {
  position: absolute;
  top: 14px;
  left: 14px;
}
.panel .course-type-icons i {
  vertical-align: middle;
  margin-right: 0.25em;
}

.panel-header {
  background-color: #f7fcff;
  border-top: 1px solid #d5efff;
  border-bottom: 1px solid #d5efff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  overflow: hidden;
}
.panel-header.no-padding {
  padding: 0;
}
.panel-header:first-child {
  border-top: none;
}

.panel-header,
.panel-body,
.panel-footer {
  padding: 14px;
}

.panel-overlap-bottom-image {
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.panel-photo-header {
  overflow: hidden;
}
.panel-photo-header img {
  width: 100%;
}

.panel-asset-header {
  background-size: cover;
}
.panel-asset-header img {
  display: block;
  margin: 10px auto 0;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.panel-blog-header img,
.panel-articles-header img {
  margin: 0;
  border: none;
}

.panel-presentation-header {
  background: #00c3a9 url(/mgs_assets/images/v4/pages/blog/presentation-bg.png) 0 0/cover no-repeat;
}
.panel-presentation-header img {
  max-width: 75%;
}

.panel-review-header {
  background: #65bef6 url(/mgs_assets/images/v4/pages/blog/review-bg.png) 0 0/cover no-repeat;
}
.panel-review-header img {
  max-width: 60%;
}

.panel-body {
  text-align: left;
}

.panel-footer {
  margin-top: auto;
}

.panel-footer > p:last-child {
  margin-bottom: 14px;
}

.panel-large .panel-body {
  line-height: 1.4736842105;
  font-size: 0.85rem;
  padding: 28px;
}
@media (min-width: 900px) {
  .panel-large .panel-body {
    font-size: 1rem;
  }
}
.panel-large .cs-content {
  padding-right: 0;
}
.panel-large .cs-sidebar {
  padding-left: 0;
}

.hover-panel {
  border-color: #dbe3e5;
  transition: all 0.25s;
  background-color: #fcfcfc;
}
.hover-panel:hover {
  border-color: #afc0c5;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.25em 6px;
  transform: translateY(-0.25em);
  background-color: #fff;
}
.hover-panel .block-link:hover {
  background-color: transparent;
  color: inherit;
}

.content-article-panel .panel-header {
  border-radius: 4px 4px 0 0;
  margin: -1px;
  aspect-ratio: 1.911;
}
.content-article-panel .heading-upper {
  font-size: 1.1em;
}
.content-article-panel .panel-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.content-article-panel .panel-body > h3 {
  margin-top: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 0.2947368421em;
  line-height: 1.4736842105;
  font-size: 1.0625rem;
  line-height: 1.3;
}
@media (min-width: 900px) {
  .content-article-panel .panel-body > h3 {
    font-size: 1.25rem;
  }
}
.content-article-panel .post-teaser {
  margin-bottom: 1em;
}
.content-article-panel .panel-footer {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.content-article-panel .post-metadata {
  padding: 0.25em 0;
}
.content-article-panel.no-footer .panel-footer {
  display: none;
}

.panel-horizontal {
  flex-direction: row;
  align-items: stretch;
}
.panel-horizontal .panel-header {
  flex: 1;
  aspect-ratio: initial;
  border-radius: 4px 0 0 4px;
  border: none;
}
.panel-horizontal .panel-body {
  flex: 2;
}
.panel-horizontal .panel-photo-header {
  max-height: none;
}
.panel-horizontal .panel-asset-header {
  height: 100%;
}
.panel-horizontal .panel-blog-header img,
.panel-horizontal .panel-article-header img {
  height: 100% !important;
  object-fit: cover;
}

.email-panel {
  background: #4797eb;
  color: white;
  padding: 2.5em;
  align-items: center;
  border: none;
}
.email-panel h3 {
  color: inherit;
  margin: 0.25em 0;
}
.email-panel .icon-mgs-newspaper {
  margin: -1em auto 0;
}

.content-article-panel-featured {
  margin-bottom: 0;
}
@media (min-width: 760px) {
  .content-article-panel-featured {
    flex-direction: row;
  }
  .content-article-panel-featured .panel-header {
    flex: 0 1 60%;
    border-radius: 4px 0 0 4px;
  }
  .content-article-panel-featured .panel-body {
    flex: 0 1 40%;
    align-self: center;
    padding: 1.5em;
  }
}
.content-article-panel-featured .panel-photo-header {
  max-height: none;
}
@media (min-width: 900px) {
  .content-article-panel-featured .panel-body h3 {
    font-size: 2.125em;
    line-height: 1.25;
    margin-top: -0.5em;
  }
}
.content-article-panel-featured .panel-body .article-summary {
  display: none;
}
@media (min-width: 900px) {
  .content-article-panel-featured .panel-body .article-summary {
    display: block;
    font-size: 1.3em;
    line-height: 1.5;
    color: #666;
  }
}
@media (min-width: 900px) {
  .content-article-panel-featured .panel-body .article-teaser {
    display: none;
  }
}

.content-topic-panel {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 15em;
}
.content-topic-panel .panel-shade {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.content-topic-panel .panel-content,
.content-topic-panel .panel-footer {
  position: relative;
  color: #fff;
}
.content-topic-panel .panel-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content-topic-panel .panel-content h3 {
  color: inherit;
}

.carousel-item .content-article-panel,
.carousel-item .content-topic-panel {
  min-height: 26em;
}

@media (min-width: 900px) {
  .expanding-grid {
    display: flex;
    align-items: flex-start;
  }
  .expanding-grid > * {
    flex: 1;
  }
  .expanding-grid > * + * {
    margin-left: 1rem;
  }
  .expanding-grid > *.expanded {
    flex-grow: 2;
  }
}

.start-here-expanding-panel {
  margin-bottom: 4em;
}
.start-here-expanding-panel:not(.expanded) {
  cursor: pointer;
}
.start-here-expanding-panel h2 {
  margin: 0.25em 0;
  font-size: 1.9em;
  color: #4c4d52;
}
.start-here-expanding-panel .pre-heading {
  color: #00c3a9;
}
.start-here-expanding-panel .panel-body {
  padding-bottom: 6.5em;
  z-index: 200;
}
.start-here-expanding-panel.expanded .collapse {
  display: block;
}
.start-here-expanding-panel.expanded .panel-overlap-bottom-image {
  bottom: -3rem;
}
.start-here-expanding-panel .media-item {
  max-width: 20%;
}
.start-here-expanding-panel h3 {
  margin: 0;
}
.start-here-expanding-panel .link-more {
  color: #65bef6;
}
.start-here-expanding-panel .link-more:hover {
  color: #4797eb;
}
.start-here-expanding-panel .stack-bordered > * + * {
  margin-top: 0.5em;
  padding-top: 0.5em;
}

.home-panels {
  margin-top: 1.5em;
  position: relative;
  top: -1.5em;
}
.home-panels .panel-header h3 {
  color: #4c4d52;
}
.home-panels .panel-header .kicker {
  color: #00c3a9;
}
.home-panels .panel-body > img {
  margin: -2em auto 0;
  position: relative;
}
.home-panels .panel-footer .button {
  font-size: 0.85rem;
}

.pdus-grid h2 {
  font-size: 1.8em;
  margin: 0.5em 0;
}
.pdus-grid h4 {
  font-size: 1.25em;
  color: #4c4d52;
}
.pdus-grid > * {
  width: 100%;
}

.pdus-box {
  position: relative;
}
.pdus-box .scrumalliance-badge {
  max-width: 150px;
  margin: -3.7em auto 0;
  display: block;
}
.pdus-box .certificate-bubble {
  display: none;
  position: absolute;
  top: -6em;
  right: 1em;
  width: 165px;
  max-width: 25%;
}
@media (min-width: 600px) {
  .pdus-box .certificate-bubble {
    display: block;
  }
}

.progress-circle {
  --size: 16px;
  --color: #65bef6;
  --value: 0%;
  --border-width: 2px;
  display: inline-block;
  width: var(--size);
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: var(--border-width) var(--color) solid;
  background: conic-gradient(var(--color) 0% var(--value), transparent var(--value));
}

.progress-circle.quarter {
  --value: 25%;
}

.progress-circle.half {
  --value: 50%;
}

.progress-circle.three-quarters {
  --value: 75%;
}

.progress-circle.full {
  --value: 100%;
}

.progress-circle-label {
  --color: #65bef6;
  text-transform: uppercase;
  color: var(--color);
  font-size: 0.9em;
  line-height: 1;
  font-weight: bold;
}

.scroller-wrapper {
  display: flex;
  justify-content: center;
}
.scroller-wrapper:has(.container) {
  overflow: scroll;
}
.scroller-wrapper:has(.container) .container {
  overflow: visible;
}
.scroller-wrapper:has(.container) .container > :where(.box, .panel):first-child {
  margin-inline-start: 0;
}

:is(.instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller) {
  --inline-margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1em 2em;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  scroll-padding-inline-start: var(--inline-margin);
}
:is(.instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller) > * {
  flex: 1 0 280px;
  scroll-snap-align: start;
}
:is(.instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller) > :where(.box, .panel):first-child {
  margin-inline-start: var(--inline-margin);
}
:is(.instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller) > :where(.box, .panel):last-child {
  margin-inline-end: var(--inline-margin);
}
:is(.instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller) > :where(.box, .panel):first-child:last-child {
  margin-inline: auto;
}
@media (min-width: 378px) {
  :is(.instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller) {
    --inline-margin: 2em;
  }
  :is(.instructors-scroller, .testimonials-scroller, .courses-scroller, .blog-scroller) > :is(.box, .panel) {
    flex-basis: 26%;
    min-width: 370px;
    max-width: 400px;
  }
}

.instructors-scroller {
  padding-block-start: 4em;
}
.instructors-scroller .box {
  background-color: #fff;
}
.instructors-scroller .box blockquote {
  margin: 1em 2em;
}
@media (min-width: 378px) {
  .instructors-scroller .box {
    flex-basis: 26%;
    min-width: 370px;
    max-width: 400px;
  }
  .instructors-scroller .box:first-child {
    margin-inline-start: 2em;
  }
  .instructors-scroller .box:first-child:last-child {
    margin-inline: auto;
  }
}

.courses-scroller {
  padding-block-start: 4em;
}
@media (min-width: 378px) {
  .courses-scroller .box {
    flex-basis: 26%;
    min-width: 370px;
  }
}

.testimonials-scroller {
  --large-width: 475px;
}
.testimonials-scroller .box {
  background-color: #fff;
}
.testimonials-scroller .box blockquote {
  margin: 1em 2em;
  width: 100%;
}
.testimonials-scroller .box .wistia_responsive_padding {
  width: 100%;
}
.testimonials-scroller .single-testimonial {
  font-style: italic;
  font-size: 1.1em;
  color: #447871;
}
@media (min-width: 475px) {
  .testimonials-scroller .box {
    flex-basis: 475px;
  }
  .testimonials-scroller .box:first-child {
    margin-inline-start: 2em;
  }
}

.blog-scroller-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 2em;
  flex-wrap: wrap;
  padding-inline-start: calc((100vw - var(--container-width)) / 2);
  padding-inline-end: 1em;
  margin-inline-start: 1rem;
}

.blog-scroller-actions {
  display: flex;
  gap: 1em;
  padding-block-end: 0.25em;
}
.blog-scroller-actions .grid-scroller-arrows {
  display: flex;
  gap: 0.5em;
}
.blog-scroller-actions .grid-scroller-arrow {
  border-color: #abb4b9;
  color: #abb4b9 !important;
  padding-inline: 0.55em;
}

.blog-scroller {
  padding-inline-start: 1rem;
  align-items: stretch;
}
.blog-scroller > *:first-child {
  margin-inline-start: 0;
}
@media (min-width: 1170px) {
  .blog-scroller {
    scroll-padding-inline-start: calc((100vw - var(--container-width)) / 2);
  }
  .blog-scroller > *:first-child {
    margin-inline-start: calc((100vw - var(--container-width)) / 2);
    scroll-margin-inline-start: calc((100vw - var(--container-width)) / 2);
  }
}
.blog-scroller > :is(.box, .panel) {
  margin-top: 0.5em;
}
@media (min-width: 378px) {
  .blog-scroller > :is(.box, .panel) {
    max-width: 377px;
  }
}

details {
  padding-block: 0.75em;
  padding-inline-start: calc(18px + 0.5em);
}

details + details {
  border-top: 1px solid #D0DAE2;
}

summary {
  outline: none;
  display: flex;
  align-items: start;
  gap: 0.5em;
  cursor: pointer;
  margin-inline-start: calc(-1 * (18px + 0.5em));
}
summary::-webkit-details-marker {
  display: none;
}
summary::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='18' height='18' fill='#4797eb' rx='2'/%3E%3Cpath fill='%23FFF' d='M10 4v4h4v2h-4v4H8V9.999L4 10V8l4-.001V4h2Z'/%3E%3C/g%3E%3C/svg%3E");
  display: block;
  height: 18px;
  width: 18px;
  position: relative;
  top: 0.125em;
}
summary:hover {
  color: #4797eb;
}
summary:hover::before {
  opacity: 0.8;
}
summary .badge {
  position: relative;
  top: 0.375em;
}
summary + * {
  margin-top: 0.25em;
}

details[open] summary {
  color: #00c3a9;
}
details[open] summary::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='18' height='18' fill='#00c3a9' rx='2'/%3E%3Cpath fill='%23FFF' d='M14 8v2H4V8z'/%3E%3C/g%3E%3C/svg%3E");
}

details :is(ul, ol) {
  padding-inline-start: 1em;
}

details.box {
  padding: 1em;
  padding-inline-start: calc(18px + 0.5em + 1em);
}

*[role=tablist] a {
  cursor: pointer;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.tabs {
  margin-top: 2.9473684211em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 2.2105263158em;
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  text-align: center;
}
.tabs > li {
  margin-bottom: 0.7368421053em;
  display: inline-block;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.tabs > li > a {
  text-transform: uppercase;
  color: #abb4b9;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.tabs > li > a:hover {
  color: #4797eb;
}
.tabs > li.active > a {
  color: #4797eb;
  border-bottom-color: #4797eb;
}

.vtabs .tabs {
  margin: 0;
  text-align: left;
}
.vtabs .tabs > li {
  margin: 0;
  padding: 0;
  display: block;
}
.vtabs .tabs > li > a {
  display: block;
  padding: 0.25em 0;
  border: none;
  text-transform: none;
}
.vtabs .tabs > li > a:hover {
  color: #abb4b9;
  padding-left: 0.75em;
}
.vtabs .tabs > li.active > a:hover {
  color: #4797eb;
  padding-left: 0;
}
.vtabs .tabs > li.active > a:after,
.vtabs .tabs > li > a:hover:after {
  content: "»";
  display: inline-block;
  padding: 0 0.25em;
}

[class*=floating-tabs] .tabs {
  margin: 0;
  text-align: left;
  font-size: 1.1em;
}
[class*=floating-tabs] .tabs > li {
  padding: 0;
}
[class*=floating-tabs] .tabs > li > a {
  text-transform: none;
  border: none;
  padding: 0.5em;
}
[class*=floating-tabs] .tabs > li.active a {
  color: #00c3a9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  background-color: #fff;
}
[class*=floating-tabs] .tab-pane {
  border: none;
  background-color: #ebf7ff;
}
[class*=floating-tabs] .tab-pane > *:first-child {
  margin-top: 0;
}
[class*=floating-tabs] .tab-pane > *:last-child {
  margin-bottom: 0;
}

.floating-tabs-l {
  display: flex;
  flex-direction: column;
}
.floating-tabs-l .tabs {
  flex: 1;
  position: relative;
  z-index: 1;
  margin-top: 2rem;
  display: flex;
  overflow-x: auto;
  padding: 0.5rem;
}
.floating-tabs-l .tabs > li {
  display: block;
  margin: 0;
  padding: 0;
}
.floating-tabs-l .tabs > li > a {
  display: block;
  text-align: center;
  white-space: nowrap;
}
.floating-tabs-l .tabs > li.active > a {
  transform: none;
  text-align: center;
}
.floating-tabs-l .tab-content {
  flex: 4;
}
.floating-tabs-l .tab-pane {
  padding: 1rem;
}

@media (min-width: 600px) {
  .floating-tabs-l {
    flex-direction: row;
  }
  .floating-tabs-l .tabs {
    padding: 0;
    flex-direction: column;
    overflow: visible;
  }
  .floating-tabs-l .tabs > li {
    padding: 0.25rem 0;
  }
  .floating-tabs-l .tabs > li > a {
    text-align: left;
  }
  .floating-tabs-l .tabs > li.active > a {
    width: 12em;
    transform: translateX(2em);
  }
  .floating-tabs-l .tab-pane {
    padding: 2rem 3rem 2rem 5rem;
  }
}
.volume-discount-tabs .tabs > li > a {
  text-transform: none;
  text-align: left;
  color: #fff;
  border-radius: 4px;
  padding: 0.25em 0.5em;
}
.volume-discount-tabs .tabs > li > a:hover {
  background-color: #6fb2f9;
}
.volume-discount-tabs .tabs > li.active > a {
  background-color: #d5efff;
  color: #444;
}
.volume-discount-tabs .media-item {
  margin-right: 0.25em;
}

.volume-discount-tabs + .panel {
  grid-column: span 2;
}

.private-training-tabs {
  border-bottom: 1px solid #4797eb;
}
.private-training-tabs .tabs {
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.private-training-tabs .tabs > li {
  margin: 0 0 -2px; /* pull next section up so line is behind active tab */
  padding: 0;
  flex: 1;
}
.private-training-tabs .tabs > li > a {
  text-decoration: none;
  border: none;
  text-transform: none;
  background: #E4EEF4;
  border: 1px solid #E4EEF4;
  margin: 0 0 1em;
  padding: 0.5em 1em;
  display: block;
  border-radius: 0.25em;
}
.private-training-tabs .tabs > li.active > a {
  background: #fff;
  border-color: #4797eb;
  border-bottom: none;
  border-radius: 0.25em 0.25em 0 0;
  padding-bottom: 1.5em;
  margin-bottom: 0;
}
.private-training-tabs .tabs strong {
  font-weight: normal;
  font-size: 1.2em;
}
.private-training-tabs .tabs .text-small {
  display: block;
  font-size: 0.75em;
}
.private-training-tabs .tabs .active .text-small {
  color: #808080;
}

.tabs.pill-tabs {
  font-size: 0.9em;
}
.tabs.pill-tabs li {
  padding: 0;
}
.tabs.pill-tabs a {
  text-transform: none;
  border: 1px solid #abb4b9;
  background-color: #fff;
  padding: 0.5em 1em;
  border-radius: 2em;
  margin: 1px;
}
.tabs.pill-tabs a:hover {
  background-color: #d5efff;
  border-color: #4797eb;
  color: #4797eb;
}
.tabs.pill-tabs .active a, .tabs.pill-tabs .active a:hover {
  border-color: #4797eb;
  color: #4797eb;
  border-width: 2px;
  margin: 0;
}

.tags .icon {
  vertical-align: sub;
  font-size: 1em;
  margin-right: 0.25em;
}
.tags .tags-heading {
  display: inline;
  font-size: inherit;
  color: inherit;
}
.tags ul {
  list-style-type: none;
}
.tags ul, .tags ul li {
  margin: 0;
  padding: 0;
  display: inline;
}
.tags ul li:not(:last-child)::after {
  content: ",";
}
.tags a {
  color: inherit;
  text-decoration: none;
}
.tags a:hover {
  color: #4797eb;
}

.testimonial blockquote {
  font-style: italic;
  margin: 0;
}
.testimonial blockquote > p:before {
  content: "“";
}
.testimonial blockquote > p:after {
  content: "”";
}
.testimonial blockquote cite {
  display: block;
  font-style: normal;
  font-weight: 600;
  margin-top: 1em;
  font-size: 0.9em;
}
.testimonial blockquote small {
  display: block;
  font-weight: 400;
  opacity: 0.6;
}
.testimonial .testimonial-avatar + blockquote {
  margin-top: 1rem;
}

.testimonial-avatar {
  --avatar-size: 4.25rem;
}

@media (min-width: 900px) {
  .testimonial-wide {
    padding-left: 90px;
    padding-right: 20px;
  }
  .testimonial-wide .testimonial-avatar {
    float: left;
    margin-left: -90px;
  }
}
.testimonial-wide blockquote p:first-child {
  margin-top: 0;
}
.testimonial-wide blockquote cite {
  text-align: left;
}

.testimonial-large blockquote {
  line-height: 1.4736842105;
  font-size: 0.85rem;
}
@media (min-width: 900px) {
  .testimonial-large blockquote {
    font-size: 1rem;
  }
}

blockquote.single {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  border-radius: 4px;
  background: #f7fcff url(/mgs_assets/images/v3/quote-bubble.png) -10px -10px no-repeat;
  padding: 28px 20px 28px 80px;
}
@media (min-width: 900px) {
  blockquote.single {
    font-size: 0.8rem;
  }
}
blockquote.single p {
  font-style: italic;
}
blockquote.single .testimonial-source {
  display: block;
  font-style: normal;
}

.bq-xl,
.bq-lg,
.bq-xxl {
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 300;
  margin: 0 0 1em;
  color: #25578b;
  font-style: italic;
}
.bq-xl cite,
.bq-lg cite,
.bq-xxl cite {
  display: block;
  font-size: 1.15rem;
}

.bq-xl {
  font-size: 1.8rem;
}

.bq-xxl {
  color: #65bef6;
  font-size: 2.4rem;
  line-height: 1.2;
}

.testimonial-style1 {
  --tst-1-border-radius: 1.5em;
}
@media (min-width: 760px) {
  .testimonial-style1 {
    display: grid;
    grid-template-columns: minmax(240px, 4fr) 1fr 7fr 1fr minmax(240px, 4fr);
    grid-template-rows: 2em 1fr 2em;
  }
}
.testimonial-style1 .content {
  border-radius: var(--tst-1-border-radius);
  background-image: url("data:image/svg+xml,%3Csvg width='78' height='68' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M53.625 68c4.367 0 8.43-1.088 12.188-3.263 3.757-2.176 6.728-5.136 8.912-8.88C76.908 52.113 78 48.065 78 43.714V7.286c0-2.024-.71-3.744-2.133-5.161C74.445.708 72.72 0 70.687 0h-19.5c-2.03 0-3.757.708-5.18 2.125-1.421 1.417-2.132 3.137-2.132 5.16v19.43c0 2.023.71 3.743 2.133 5.16C47.43 33.292 49.156 34 51.188 34h12.187v9.714c0 2.631-.965 4.908-2.895 6.83-1.93 1.923-4.214 2.885-6.855 2.885h-1.219c-1.015 0-1.879.354-2.59 1.062-.71.708-1.066 1.569-1.066 2.58v7.286c0 1.012.355 1.872 1.066 2.58.711.709 1.575 1.063 2.59 1.063h1.219zM9.75 68c4.367 0 8.43-1.088 12.188-3.263 3.757-2.176 6.728-5.136 8.912-8.88 2.183-3.744 3.275-7.792 3.275-12.143V7.286c0-2.024-.71-3.744-2.133-5.161C30.57.708 28.844 0 26.812 0h-19.5c-2.03 0-3.757.708-5.18 2.125C.712 3.542 0 5.262 0 7.285v19.43c0 2.023.71 3.743 2.133 5.16C3.555 33.292 5.28 34 7.313 34H19.5v9.714c0 2.631-.965 4.908-2.895 6.83-1.93 1.923-4.214 2.885-6.855 2.885H8.531c-1.015 0-1.879.354-2.59 1.062-.71.708-1.066 1.569-1.066 2.58v7.286c0 1.012.355 1.872 1.066 2.58.711.709 1.575 1.063 2.59 1.063H9.75z' fill='%23EEF9FF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 92.5% 1em;
  background-color: #d5efff;
  padding: 2em 3em;
  grid-row: 2/3;
  margin: 0;
  width: 100%;
  display: flex;
  align-content: center;
}
.testimonial-style1 .content cite {
  display: block;
}
.testimonial-style1 .image {
  background-image: url("data:image/svg+xml,%3Csvg width='400' height='414' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M313.523 49.575c-11.035 0-19.98-8.973-19.98-20.04V20.04C293.543 8.973 284.6 0 273.566 0H55.45c-11.035 0-19.98 8.973-19.98 20.04V110.2c0 11.07-8.945 20.04-19.978 20.04H12.87C5.76 130.24 0 136.022 0 143.152v198.78c0 7.132 5.76 12.913 12.87 12.913h66.195c11.033 0 19.977 8.973 19.977 20.04v18.57c0 11.07 8.946 20.044 19.98 20.044h207.6c11.034 0 19.978-8.974 19.978-20.044v-52.406c0-11.068 8.944-20.04 19.98-20.04h19.813c7.108 0 12.87-5.78 12.87-12.909V62.486c0-7.13-5.762-12.91-12.87-12.91h-72.87z' fill='%2365BEF6' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0.75em;
  grid-row: 1/4;
  z-index: 10;
  display: flex;
  align-items: center;
}
@media (max-width: 760px) {
  .testimonial-style1 .image {
    max-width: 35%;
    height: auto !important;
    margin: 0 auto -1.5em;
  }
}
.testimonial-style1 .image > img {
  border-radius: var(--tst-1-border-radius);
  box-shadow: 0 16px 26px rgba(0, 0, 0, 0.5);
  object-fit: cover;
  height: 100% !important;
  max-height: 400px;
}
.testimonial-style1 blockquote {
  margin: auto;
}
.testimonial-style1.image-left .content {
  grid-column: 2/6;
  padding-left: 12%;
}
.testimonial-style1.image-left .image {
  grid-column: 1/3;
}
.testimonial-style1.image-right .content {
  grid-column: 1/5;
  padding-right: 12%;
}
.testimonial-style1.image-right .image {
  grid-column: 4/6;
}

.testimonial-simple .avatar-circle {
  max-width: 3.5em;
  aspect-ratio: 1;
  object-fit: cover;
}
.testimonial-simple cite {
  font-style: normal;
}
.testimonial-simple .media {
  gap: 0.75em;
}
.testimonial-simple .media > * {
  flex: 1;
}
.testimonial-simple .text-quiet {
  font-weight: normal;
}
.testimonial-simple blockquote {
  font-style: normal;
}
.testimonial-simple blockquote > p::before,
.testimonial-simple blockquote > p::after {
  content: "";
}

.this-or-that {
  border: 1px solid #dbe3e5;
  background-color: #fcfcfc;
  display: flex;
  border-radius: 0.25em;
  align-items: center;
}

.this-or-that a {
  flex: 2;
  text-align: center;
  padding: 0.75em;
}

.this-or-that .or {
  background-color: #00b39b;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.select-css {
  display: block;
  font-size: 15px;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
  border-color: #888;
}

.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 2px #fbf6d4;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.select-css option {
  font-weight: normal;
}

*[dir=rtl] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

video {
  max-width: 100%;
  display: block;
}

.video-embed, .video-viewer-list .video-thumb,
.wistia_embed {
  max-width: 100%;
  aspect-ratio: 16/9;
}
.video-embed iframe, .video-viewer-list .video-thumb iframe,
.wistia_embed iframe {
  width: 100%;
  height: 100%;
}

@media (min-width: 900px) {
  .video-viewer-video {
    width: 66.1538461539%;
    float: left;
    margin-right: 1.5384615383%;
  }
}
.video-viewer-video .video-embed, .video-viewer-video .video-viewer-list .video-thumb, .video-viewer-list .video-viewer-video .video-thumb {
  box-shadow: 0 6px 16px hsla(0, 0%, 0%, 0.2);
}
@media (min-width: 900px) {
  .video-viewer-list {
    width: 32.3076923078%;
    float: right;
    margin-right: 0;
  }
}
.video-viewer-list h3 {
  margin-top: -0.3684210526em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 0.3684210526em;
  text-align: center;
}
.video-viewer-list .video-title {
  display: block;
  font-size: 0.75em;
  text-align: center;
  line-height: 1.3;
}
@media (min-width: 900px) {
  .video-viewer-list .video-title {
    margin-top: -0.5em;
  }
}
.video-viewer-list a {
  display: block;
  padding-left: 4%;
  padding-right: 4%;
}
.video-viewer-list .active .video-thumb {
  box-shadow: 0 0 1px 1px #00c3a9;
}
.video-viewer .video-viewer-videos {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin: 0;
}
.video-viewer .video-viewer-videos li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.video-viewer .video-viewer-videos li {
  margin-bottom: 1.1052631579em;
  width: 47.6190476193%;
  float: left;
}
.video-viewer .video-viewer-videos li:nth-child(2n+1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}
.video-viewer .video-viewer-videos li:nth-child(2n+2) {
  margin-left: 52.3809523807%;
  margin-right: -100%;
  clear: none;
}
@media (min-width: 600px) {
  .video-viewer .video-viewer-videos li {
    width: 23.2558139536%;
    float: left;
  }
  .video-viewer .video-viewer-videos li:nth-child(4n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .video-viewer .video-viewer-videos li:nth-child(4n+2) {
    margin-left: 25.5813953488%;
    margin-right: -100%;
    clear: none;
  }
  .video-viewer .video-viewer-videos li:nth-child(4n+3) {
    margin-left: 51.1627906976%;
    margin-right: -100%;
    clear: none;
  }
  .video-viewer .video-viewer-videos li:nth-child(4n+4) {
    margin-left: 76.7441860464%;
    margin-right: -100%;
    clear: none;
  }
}
@media (min-width: 900px) {
  .video-viewer .video-viewer-videos li {
    width: 47.6190476193%;
    float: left;
  }
  .video-viewer .video-viewer-videos li:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .video-viewer .video-viewer-videos li:nth-child(2n+2) {
    margin-left: 52.3809523807%;
    margin-right: -100%;
    clear: none;
  }
}

.video-playicon, .video-viewer-list .video-thumb {
  position: relative;
  display: inline-block;
  text-decoration: none;
  border-bottom: none !important;
}
.video-playicon:before, .video-viewer-list .video-thumb:before {
  transition: 0.25s all;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.25em;
  height: 2.25em;
  margin-left: -1.125em;
  margin-top: -1.125em;
  content: "";
  background: rgba(0, 0, 0, 0.5) url("/mgs_assets/images/v4/svg-icons-source/play-white.svg") 55% 50%/36% no-repeat;
  color: white;
  font-size: 1em;
  line-height: 2.45em;
  text-align: center;
  text-decoration: none;
}
.video-playicon:hover:before, .video-viewer-list .video-thumb:hover:before {
  background-color: #00c3a9;
}
.video-playicon img, .video-viewer-list .video-thumb img {
  display: block;
}

.site-header {
  border-top: 2px solid #65bef6;
  border-bottom: 4px solid #25578b;
  background-color: #fff;
  position: relative;
  z-index: 200;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
  transition: all 0.25s;
  height: var(--header-height);
}
@media (min-width: 1020px) {
  .site-header {
    flex-wrap: nowrap;
  }
}

.logo {
  padding: 0.5rem 1.5em 0.5rem 0;
  margin-right: 0.5em;
  max-width: 50%;
  transition: all 0.25s;
  display: flex;
  align-items: center;
  height: 54px;
}
@media (min-width: 600px) {
  .logo {
    padding: 0.3rem 1.5em 0.3rem 0;
    position: relative;
    top: -0.125em;
  }
}
@media (min-width: 1020px) {
  .logo {
    height: 54px;
    border-right: 1px solid #d5efff;
  }
}
.logo a, .logo img {
  border: none;
  max-height: 100%;
  transition: all 0.25s;
}
.logo img {
  width: 180px;
}
@media (min-width: 1020px) {
  .logo img {
    width: 200px;
  }
}

#nav,
.nav-buttons {
  display: none;
}
@media (min-width: 1020px) {
  #nav,
  .nav-buttons {
    display: block;
  }
}

.mobile-nav-button {
  margin-left: auto;
}
@media (min-width: 1020px) {
  .mobile-nav-button {
    display: none;
  }
}

@media (min-width: 900px) {
  .site-header.no-nav {
    text-align: center;
  }
  .site-header.no-nav .logo {
    width: 100%;
    max-width: none;
    margin: 0 auto;
  }
  .site-header.no-nav .logo a {
    width: 100%;
  }
  .site-header.no-nav .logo a img {
    display: inline-block;
  }
}
.site-announcement {
  background-color: #65bef6;
  color: white;
  font-size: 0.9em;
  padding: 0.35em 0.25em 0.25em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--announcement-height);
  z-index: 300;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.site-announcement a {
  color: inherit;
}

body:has(.site-announcement) {
  --announcement-height: 2rem;
}
@media (max-width: 600px) {
  body:has(.site-announcement) {
    --announcement-height: 3rem;
  }
}

@media (min-width: 1020px) {
  body.sticky-header .site-announcement,
  body.sticky-header .site-header {
    position: fixed;
    width: 100%;
  }
  body.sticky-header .site-header {
    top: var(--announcement-height);
  }
  body.sticky-header .wrapper {
    padding-block-start: var(--page-block-start);
  }
  body.scrolled {
    --header-height: 3.5rem;
  }
  body.scrolled .site-header {
    padding: 0 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid #65bef6;
  }
  body.scrolled .site-header .nav-buttons .button {
    font-size: 0.75em;
  }
  body.scrolled .site-header .logo {
    height: 45px;
  }
  body.scrolled .site-header .logo img {
    width: 180px;
  }
  body.scrolled #nav {
    font-size: 0.8em;
  }
  body.scrolled #nav .mega-menu {
    font-size: 1.128em;
  }
}
@media (max-width: 1020px) {
  body.sticky-header.scrolled-mobile {
    --header-height: 2.25rem;
    --header-border-height: 0;
  }
  body.sticky-header.scrolled-mobile .mobile-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    font-size: 0.8em;
    border-bottom: 1px solid #25578b;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  }
  body.sticky-header.scrolled-mobile .mobile-navbar ul {
    height: var(--header-height);
  }
}
#nav {
  font-size: 0.9em;
}

.nav-primary {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.nav-primary > li > span,
.nav-primary > li > a {
  color: #5480ab;
  transition-duration: 0.15s;
  text-decoration: none;
  position: relative;
  padding: 0.55em 1em;
  display: block;
}
.nav-primary > li > span:hover,
.nav-primary > li > a:hover {
  color: #65BEF6;
}
.nav-primary a.media {
  display: flex;
}
.nav-primary span {
  cursor: default;
}
.nav-primary > li {
  position: relative;
}
.nav-primary > li:hover > a {
  color: #65BEF6;
}
.nav-primary *[class*=icon-] {
  margin-left: 0.25em;
}

.primary-subnav {
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 4px;
  border-radius: 4px;
  background: #fcfcfc;
  z-index: 9999;
  padding: 0;
  font-size: 0.85rem;
  line-height: 1.5;
  overflow: hidden;
}
.primary-subnav .media {
  gap: 0.75em;
}
.primary-subnav a {
  color: #12243a;
  padding-left: 1em;
  padding-right: 0.5em;
}
.primary-subnav a:hover {
  color: #12243a;
}
.primary-subnav a:hover {
  background-color: #EBF7FF;
}
.primary-subnav em {
  font-size: 0.8em;
  color: #6089b1;
  display: block;
}
.primary-subnav .nav-item-secondary {
  background-color: #eaebeb;
  font-size: 0.9em;
}
.primary-subnav .nav-item-secondary a:hover {
  background-color: #d8d8d8;
}
.primary-subnav li:not(.nav-item-secondary) + .nav-item-secondary {
  border-top: 1px solid #cacbcb;
}
.primary-subnav li:not(.nav-item-secondary) + .nav-item-secondary > a {
  border-top: 1px solid #e7e8e8;
}

.icon.search {
  font-size: 0.9em;
  fill: #5480ab;
}

.icon.dropdown-arrow {
  font-size: 0.6em;
  vertical-align: middle;
  margin-left: 0.3em;
}

.icon.mobile-nav {
  font-size: 2.25em;
}

.nav-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5em;
  margin-left: auto;
}
.nav-buttons .button {
  background-color: #d5efff;
  color: #325687 !important;
  border-color: white;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.nav-buttons .button:hover {
  color: #325687 !important;
  background-color: #a2dcff;
}
.nav-buttons svg {
  margin-top: 0.1em;
  fill: #65BEF6;
}
.nav-buttons .button:hover svg {
  fill: #4797EB;
}
.nav-buttons .training-button {
  display: none;
}
@media (min-width: 1020px) {
  .nav-buttons .training-button {
    display: flex;
  }
}

#mm-nav {
  background: #222;
}
#mm-nav .icon.dropdown-arrow,
#mm-nav .icon-dropdown-arrow {
  display: none;
}
#mm-nav .button {
  margin: 1em;
}

#mobile-nav {
  background-color: #13253b;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent 15px);
}
#mobile-nav .divider {
  font-weight: bold;
  background-color: #304763;
  text-transform: uppercase;
}
#mobile-nav .divider:not(:first-child) {
  margin-top: 1em;
}

.mobile-navbar {
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);
  background: #4797eb;
  font-size: 0.9em;
}
@media (min-width: 1020px) {
  .mobile-navbar {
    display: none;
  }
}
.mobile-navbar ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  display: flex;
  justify-content: space-around;
  height: 2.5rem;
  margin: 0;
}
.mobile-navbar ul li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.mobile-navbar a {
  color: #fff;
  padding: 0 0.65rem;
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.mobile-navbar .icon {
  fill: #fff;
}

.stripe-nav {
  background: #4797eb;
}
.stripe-nav ul {
  display: flex;
  justify-content: center;
}
.stripe-nav .current a {
  color: #9cfff2;
  border-bottom-color: #9cfff2;
}

.sidenav {
  font-size: 0.9em;
  margin-top: 1em;
}
.sidenav ul, .sidenav ol {
  list-style: none;
  padding: 0;
  margin: 0 0 0.5em;
}
.sidenav ul ul,
.sidenav ol ol {
  padding-left: 1em;
}
.sidenav > ul > li > ul,
.sidenav > ol > li > ol {
  font-weight: bold;
}
@media (min-width: 600px) {
  .sidenav > ul > li > ul,
  .sidenav > ol > li > ol {
    font-size: 0.85em;
  }
}
.sidenav > ul > li > ul > li > ul,
.sidenav > ol > li > ol > li > ol {
  font-size: 1em;
  font-weight: normal;
}
.sidenav a {
  text-decoration: none;
  color: #4c4d52;
  display: block;
  padding: 0.25em 0;
}
.sidenav a:hover {
  color: #4797eb;
  text-decoration: underline;
}
.sidenav .current > a {
  color: #4797eb;
}
.sidenav .current > a::after {
  content: url("data:image/svg+xml; utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12'%3E%3Cpath fill='%234797EB' fill-rule='nonzero' d='m1.13.203 5.007 5.225a.651.651 0 0 1 0 .907L1.13 11.561a.654.654 0 0 1-.927.02.653.653 0 0 1-.02-.928l4.594-4.796L.184 1.11a.653.653 0 0 1 .02-.927.653.653 0 0 1 .926.02Z'/%3E%3C/svg%3E");
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: middle;
}

@media (min-width: 600px) {
  .sidenav-sticky {
    position: sticky;
    top: 4rem;
    max-height: calc(100vh - 4rem);
    overflow: auto;
  }
}
.sidenav-heading {
  --heading-color: $base-font-color;
  color: var(--heading-color);
  border-bottom: 2px solid #65bef6;
  padding-bottom: 0.35em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.sidenav-heading > a,
.sidenav-heading > a:hover {
  text-decoration: none;
  color: var(--heading-color);
}
.sidenav-heading .dropdown-arrow {
  display: none;
}

@media (max-width: 600px) {
  .sidenav-collapsible {
    border: 1px solid #ccc;
    background: linear-gradient(#fafafa, #ddd);
    border-radius: 0.25em;
    padding: 0.25em 1em;
    overflow: hidden;
    position: relative;
    z-index: 100;
  }
  .sidenav-collapsible:target, .sidenav-collapsible:focus, .sidenav-collapsible:focus-within {
    background: #fafafa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  .sidenav-collapsible:target > ul, .sidenav-collapsible:target > ol, .sidenav-collapsible:focus > ul, .sidenav-collapsible:focus > ol, .sidenav-collapsible:focus-within > ul, .sidenav-collapsible:focus-within > ol {
    max-height: none;
  }
  .sidenav-collapsible:target .sidenav-heading, .sidenav-collapsible:focus .sidenav-heading, .sidenav-collapsible:focus-within .sidenav-heading {
    border-bottom: 2px solid #65bef6;
    padding-bottom: 0.35em;
    margin-bottom: 0.5em;
  }
  .sidenav-collapsible:target ~ .sidenav-collapsible-close, .sidenav-collapsible:focus ~ .sidenav-collapsible-close, .sidenav-collapsible:focus-within ~ .sidenav-collapsible-close {
    display: block;
  }
  .sidenav-collapsible .sidenav-heading {
    border: none;
    margin: 0;
    padding: 0;
  }
  .sidenav-collapsible .sidenav-heading a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidenav-collapsible .sidenav-heading .dropdown-arrow {
    display: block;
    font-size: 1rem;
  }
  .sidenav-collapsible > ul, .sidenav-collapsible > ol {
    max-height: 0;
    margin: 0;
    overflow: hidden;
  }
}
.sidenav-collapsible-close {
  display: none;
  text-align: center;
  padding: 0.5em;
}
.sidenav-collapsible-close::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.3);
  content: "";
  cursor: default;
}

.site-footer {
  font-size: 0.75rem;
  background-color: #696969;
  border-top: none;
  border-bottom: 6px solid #4a4a4a;
  color: white;
  padding: 0 0 0.5em 0;
}
.site-footer > .container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.site-footer .logo-footer {
  margin-bottom: -0.9em;
  flex: 0 0 75px;
  display: none;
}
.site-footer .logo-footer img {
  display: block;
}
.site-footer .footer-info-nav ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-top: 0.75rem;
}
.site-footer .footer-info-nav ul li {
  display: inline-block;
  list-style: none;
  margin-left: 0;
  white-space: nowrap;
}
.site-footer .footer-info-nav li {
  margin-right: 1em;
  width: auto;
}
.site-footer a {
  color: inherit;
  text-decoration: none;
}
.site-footer a:hover {
  text-decoration: underline;
}

@media (min-width: 760px) {
  .site-footer > .container {
    flex-direction: row;
  }
  .site-footer .logo-footer {
    display: block;
  }
  .site-footer .footer-info-nav {
    flex: 2;
  }
  .site-footer .footer-info-nav ul {
    margin-top: 0;
  }
}
.footer-cta {
  background-color: #f7f7f7;
  background-image: url(/mgs_assets/images/v4/footer-mountains.svg);
  background-position: 105% 100%;
  background-repeat: no-repeat;
  padding: 2em 0 5em;
  color: #4c4d52;
}
.footer-cta h2 {
  letter-spacing: normal;
  color: #00c3a9;
  text-transform: none;
  font-size: 2.9em;
  margin-block-end: 0.4em;
}
.footer-cta p:not(.text-small) {
  font-size: 1.2em;
  text-wrap: pretty;
}
.footer-cta fieldset {
  overflow: visible;
  flex-wrap: wrap;
}
.footer-cta fieldset > * {
  flex: 1;
  min-width: 200px;
}
.footer-cta label {
  display: block;
  float: none;
  width: auto;
}
.footer-cta input[type=text],
.footer-cta input[type=email] {
  width: 100%;
}

.footer-nav {
  padding: 1em 0;
  color: #f7f7f7;
}
.footer-nav > .container {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 600px) {
  .footer-nav > .container {
    flex-wrap: nowrap;
  }
}
.footer-nav > .container > * {
  flex: 1;
  min-width: 12.5em;
}
.footer-nav ul {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  padding-right: 2em;
}
.footer-nav li {
  padding-left: 0;
  padding: 0.25em 0;
  opacity: 0.75;
}
.footer-nav li:hover {
  opacity: 1;
}
.footer-nav h4 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0;
}
.footer-nav :where(h4, strong, a) {
  color: inherit;
}
.footer-nav .button:hover {
  text-decoration: none;
}

.site-footer-simple {
  padding-bottom: 0;
}
.site-footer-simple :is(.footer-cta, .footer-nav) {
  display: none;
}
.site-footer-simple .logo-footer {
  margin-block: 0.5em 0;
}

.entry-title a {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  color: inherit;
}
.entry-title a:active, .entry-title a:focus {
  outline: none;
}
.entry-title a:hover, .entry-title a:focus {
  text-decoration: underline;
  color: #4797eb;
  cursor: pointer;
}

.post-large + .post-large {
  margin-top: 0em;
  padding-top: 4.4210526316em;
  padding-bottom: 4.4210526316em;
  margin-bottom: 0em;
  border-bottom: 1px solid #d5efff;
}
.post-large.hentry-full {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.post-large .entry-title {
  text-align: center;
  margin-bottom: 0.3684210526em;
}
.post-large .post-metadata,
.post-large .entry-summary {
  margin-bottom: 1.4736842105em;
}
.post-large :where(h2, h3, h4, h5) {
  scroll-padding-block-start: var(--page-block-start);
}

@media (min-width: 600px) {
  .post-large:has(.entry-toc) {
    display: grid;
    grid-template-columns: 7fr 3fr;
    grid-template-areas: "content subnav";
    gap: 0 3em;
  }
  .post-large:has(.entry-toc) .metadata {
    grid-column: content/subnav;
    grid-row: 1/2;
  }
  .post-large:has(.entry-toc) .entry-content, .post-large:has(.entry-toc) .entry-toc {
    grid-row-start: 2;
  }
  .post-large:has(.entry-toc) :is(.entry-content, #promo, .post-footer-full) {
    grid-column: content-start/content-end;
  }
  .post-large:has(.entry-toc) .entry-toc {
    grid-column: subnav-start/subnav-end;
  }
}
.entry-toc {
  margin-block-end: 1em;
}
.entry-toc .sidenav-heading {
  font-size: 1em;
  font-weight: normal;
}
@media (min-width: 600px) {
  .entry-toc .sidenav-heading {
    --heading-color: #00c3a9;
    font-size: 0.9em;
    margin-block: 0.125em 0;
    padding-block: 0;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.05ch;
  }
}
.entry-toc ol {
  list-style: none;
  padding-inline: 0;
}
.entry-toc ol > li + li {
  border-top: 1px solid #d5efff;
}
.entry-toc ol ol {
  padding-inline: 0.5em 0;
  margin-block-end: 0.25em;
}
.entry-toc ol ol > li {
  list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='9'%3E%3Cpath fill='%2398D8FF' fill-rule='nonzero' d='M1.375 9a.687.687 0 0 1-.486-1.174l2.952-2.951L.89 1.923a.687.687 0 1 1 .972-.972L5.3 4.388a.687.687 0 0 1 0 .973L1.86 8.798A.682.682 0 0 1 1.375 9Z'/%3E%3C/svg%3E");
  padding-inline: 0.25em 0;
  border-top: none;
}
.entry-toc ol a {
  color: #4c4d52;
  text-decoration: none;
  display: block;
  font-size: 0.9em;
  padding-block: 0.5em;
}
.entry-toc ol a:hover {
  color: #4797eb;
  transform: translateX(0.25em);
}
.entry-toc > ol > li > ol {
  font-size: 0.85em;
  font-weight: bold;
}
.entry-toc > ol > li > ol a {
  padding-block: 0.25em;
}
.entry-toc ol ol ol li {
  font-weight: normal;
}

.entry-toc .promo-lead-magnet {
  margin-block-start: 1em;
}
@media (max-width: 600px) {
  .entry-toc .promo-lead-magnet {
    display: none;
  }
}
.entry-toc .promo-lead-magnet .promo-image {
  display: none;
}
.entry-toc .promo-lead-magnet .promo-heading {
  font-weight: bold;
  text-align: left;
  margin-block-start: 0;
}
.entry-toc .promo-lead-magnet .promo-content {
  text-align: left;
  font-size: 0.9em;
}

.post-preview-image img {
  display: block;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .post-preview-image-small {
    margin-left: 25.3846153846%;
    margin-right: 25.3846153846%;
  }
}

.entry-subtitle {
  margin-top: -0.7368421053em;
  text-align: center;
}

.post-image-quote {
  margin: 0;
  margin-bottom: 1.4736842105em;
}

.post-metadata {
  color: #828f97;
}

.post-large .post-metadata {
  padding-bottom: 1em;
  font-size: 0.8em;
  color: #828f97;
  border-bottom: 1px solid #d5efff;
  display: flex;
  justify-content: center;
}
.post-large .post-metadata > * {
  max-width: 50%;
}
.post-large .post-metadata > *:not(:first-child) {
  margin-left: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.post-large .post-metadata .avatar-sm {
  --avatar-size: 3rem;
  margin-right: 0.8em;
}
.post-large .post-metadata a {
  text-decoration: none;
  color: inherit;
}
.post-large .post-metadata a:hover {
  color: #4797eb;
}
.post-large .post-metadata .tags strong {
  display: block;
}

.post-footer {
  overflow: hidden;
}
.post-footer .post-continue {
  float: right;
}

.post-footer-full {
  margin-top: 2.9473684211em;
  padding-top: 1.4736842105em;
  padding-bottom: 1.4736842105em;
  margin-bottom: 2.9473684211em;
  border-block: 1px solid #d5efff;
  position: relative;
  text-align: center;
}
.post-footer-full .addthis_toolbox > a {
  float: none;
  display: inline-block;
}

.logo-list {
  flex-wrap: nowrap;
}
.logo-list > * {
  min-width: auto;
}

.featured-resources .feature-box {
  padding: 1em;
}
.featured-resources .feature-box .featured-content-image-wrapper,
.featured-resources .feature-box .featured-content-summary {
  display: none;
}
.featured-resources .feature-box a {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.featured-resources h2 {
  font-size: 1em;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
  color: #828f97;
  text-align: center;
}
.featured-resources ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.featured-resources li {
  border-bottom: 1px solid #d5efff;
}
.featured-resources .featured-content-image-wrapper {
  flex: 0 0 8em;
  margin-right: 1em;
}
.featured-resources .featured-content-image {
  display: block;
  height: 0;
  padding-bottom: 63%;
  overflow: hidden;
  border-radius: 4px;
  background: #d0d0d0 url(/mgs_assets/images/v4/default-blog-image.png) 50% 50%/cover no-repeat;
}
.featured-resources a {
  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1em 0.5em;
}
.featured-resources a:hover {
  background-color: #d5efff;
}
.featured-resources .getting-started-with {
  display: block;
  font-size: 0.75em;
  line-height: 1;
  color: #65bef6;
}
.featured-resources p {
  color: #4c4d52;
  line-height: 1.4;
  font-size: 0.8em;
}

#gmap img {
  max-width: none;
}

.map-infobox {
  line-height: 1.4736842105;
  font-size: 0.8rem;
  padding: 0.5em 1em;
  min-width: 215px;
}
@media (min-width: 900px) {
  .map-infobox {
    font-size: 0.8rem;
  }
}
@media (min-width: 900px) {
  .map-stripe .gmap {
    width: 66.1538461539%;
    float: right;
    margin-right: 0;
  }
}

.gmap {
  height: 460px;
  margin: 0 1em;
}
@media (min-width: 600px) {
  .gmap {
    margin: 0;
  }
}

.map-stripe-single .map-stripe-overlay,
.map-stripe .map-stripe-overlay {
  position: relative;
  z-index: 10;
  padding-right: 1em;
  display: none;
}
.map-stripe-single .map-stripe-overlay h2,
.map-stripe .map-stripe-overlay h2 {
  margin-bottom: 0.7368421053em;
}
.map-stripe-single .map-stripe-overlay .view-more-list li,
.map-stripe .map-stripe-overlay .view-more-list li {
  margin-bottom: 0.7368421053em;
}

.map-stripe .map-stripe-overlay {
  display: block;
}
@media (min-width: 900px) {
  .map-stripe .map-stripe-overlay {
    width: 32.3076923078%;
    float: left;
    margin-right: 1.5384615383%;
    height: 460px;
    margin-top: -460px;
  }
}

#search {
  margin-top: 0em;
  padding-top: 1.4736842105em;
  padding-bottom: 0em;
  margin-bottom: 0em;
  background-color: #25578b;
  position: relative;
  height: 25em;
  top: -25em;
  margin-bottom: -25em;
}
#search.is-open-results, #search.is-open {
  top: 0;
  margin-bottom: 0;
}
#search.has-opened {
  transition: all 0.4s ease-in-out;
}
#search .search-title {
  color: #d5efff;
  margin: 0 auto 1em;
  font-size: 1.5em;
  font-weight: 300;
}
#search form {
  margin-bottom: 2.9473684211em;
  position: relative;
}
#search input[type=search],
#search button {
  font-size: 2em;
  height: 1.5em;
}
@media (min-width: 900px) {
  #search input[type=search],
  #search button {
    font-size: 3em;
  }
}
#search input[type=search] {
  transition: all 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: 2px solid #65bef6;
  padding-bottom: 0.125em;
  padding-right: 2em;
  font-family: "interface", sans-serif;
  font-weight: 300;
  color: #fff;
  width: 100%;
}
#search input[type=search]::placeholder {
  color: #13253b;
}
#search input[type=search]::-webkit-search-decoration, #search input[type=search]::-webkit-search-cancel-button, #search input[type=search]::-webkit-search-results-button, #search input[type=search]::-webkit-search-results-decoration {
  display: none;
}
#search input[type=search]::-ms-clear, #search input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
#search input[type=search]:focus {
  outline: none;
  border-bottom-color: #fff;
}
#search button {
  width: 1.5em;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
}
#search .search-browse {
  display: none;
}

.stripe {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.stripe-med {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.stripe-thin {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.stripe-x-thin {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.stripe-blue-gradient, .bg-blue-gradient {
  background: linear-gradient(#4898dd, #2c7acb);
}

.stripe-blue, .bg-blue {
  background-color: #4797eb;
}

.stripe-blue-dark, .bg-blue-dark {
  background-color: #2c7acb;
}

.stripe-blue-x-dark, .bg-blue-x-dark {
  background-color: #25578b;
}

.stripe-blue-xxx-dark, .bg-blue-xxx-dark {
  background-color: #13253b;
}

.stripe-blue-x-light, .bg-blue-x-light {
  background-color: #ebf7ff;
}

.stripe-blue-xx-light, .bg-blue-xx-light {
  background-color: #f7fcff;
}

.stripe-lightblue, .bg-lightblue,
.stripe-blue-light, .bg-blue-light {
  background-color: #d5efff;
  color: #5d7891;
}

.bg-blue-bright {
  background-color: #65bef6;
}

.stripe-teal, .bg-teal {
  background-color: #00c3a9;
}

.stripe-grey-dark, .bg-grey-dark {
  background-color: #4c4d52;
  color: white;
}

.stripe-grey-light, .bg-grey-light {
  background-color: #abb4b9;
}

.stripe-grey-x-light, .bg-grey-x-light {
  background-color: #dbe3e5;
}

.stripe-grey-x-light-alt, .bg-grey-x-light-alt {
  background-color: #f7f7f7;
}

.stripe-dark,
.stripe-blue,
.stripe-blue-dark,
.stripe-blue-x-dark,
.stripe-blue-xxx-dark {
  color: white;
}
.stripe-dark h1, .stripe-dark h2, .stripe-dark h3,
.stripe-dark a,
.stripe-blue h1,
.stripe-blue h2,
.stripe-blue h3,
.stripe-blue a,
.stripe-blue-dark h1,
.stripe-blue-dark h2,
.stripe-blue-dark h3,
.stripe-blue-dark a,
.stripe-blue-x-dark h1,
.stripe-blue-x-dark h2,
.stripe-blue-x-dark h3,
.stripe-blue-x-dark a,
.stripe-blue-xxx-dark h1,
.stripe-blue-xxx-dark h2,
.stripe-blue-xxx-dark h3,
.stripe-blue-xxx-dark a {
  color: white;
}
.stripe-dark table a:hover,
.stripe-blue table a:hover,
.stripe-blue-dark table a:hover,
.stripe-blue-x-dark table a:hover,
.stripe-blue-xxx-dark table a:hover {
  color: white !important;
}
.stripe-dark > table td,
.stripe-blue > table td,
.stripe-blue-dark > table td,
.stripe-blue-x-dark > table td,
.stripe-blue-xxx-dark > table td {
  border-color: #65bef6;
}

.stripe-bordered {
  border-top: 1px solid #d5efff;
  border-bottom: 1px solid #d5efff;
}
.stripe-bordered.stripe-grey-x-light-alt {
  border-color: #e6e6e6;
}

.banner-heading {
  font-size: 1.85rem;
  line-height: 1.2;
  padding: 1em 0;
  margin: 0;
}
@media (min-width: 600px) {
  .banner-heading {
    font-size: 2rem;
  }
}
@media (min-width: 900px) {
  .banner-heading {
    font-size: 2.5rem;
    padding: 0;
  }
}

.stripe-photo-blue {
  overflow: hidden;
}
@media (min-width: 900px) {
  .stripe-photo-blue {
    height: 420px;
  }
}
.stripe-photo-blue-filtering {
  overflow: visible;
  position: relative;
}
.stripe-photo-blue-image {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: none;
}
@media (min-width: 900px) {
  .stripe-photo-blue-image {
    display: block;
  }
}
.stripe-photo-blue-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 87, 139, 0.4);
}
.stripe-photo-blue-image img {
  width: 100%;
}
.stripe-photo-blue-heading, .stripe-photo-blue-heading-full, .stripe-photo-blue-heading-filtered {
  text-align: center;
  color: white;
  position: relative;
  background-color: #2c7acb;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  align-items: center;
}
@media (min-width: 900px) {
  .stripe-photo-blue-heading, .stripe-photo-blue-heading-full, .stripe-photo-blue-heading-filtered {
    background-color: rgba(44, 122, 203, 0.75);
    margin-top: -112px;
    height: 112px;
    line-height: 112px;
    padding-left: 0;
    padding-right: 0;
  }
}
.stripe-photo-blue-heading h1, .stripe-photo-blue-heading-full h1, .stripe-photo-blue-heading-filtered h1 {
  font-size: 1.85rem;
  line-height: 1.2;
  padding: 1em 0;
  margin: 0;
  color: inherit;
  width: 100%;
}
@media (min-width: 600px) {
  .stripe-photo-blue-heading h1, .stripe-photo-blue-heading-full h1, .stripe-photo-blue-heading-filtered h1 {
    font-size: 2rem;
  }
}
@media (min-width: 900px) {
  .stripe-photo-blue-heading h1, .stripe-photo-blue-heading-full h1, .stripe-photo-blue-heading-filtered h1 {
    font-size: 2.5rem;
    padding: 0;
  }
}
@media (min-width: 900px) {
  .stripe-photo-blue-heading h1, .stripe-photo-blue-heading-full h1, .stripe-photo-blue-heading-filtered h1 {
    line-height: 112px;
  }
}
.stripe-photo-blue-heading-full, .stripe-photo-blue-heading-filtered {
  padding-top: 4.4210526316em;
  line-height: 1.4736842105;
  height: 420px;
  margin-top: -420px;
}
.stripe-photo-blue-heading-filtered {
  padding-top: 2.2105263158em;
}
@media (min-width: 600px) {
  .stripe-photo-blue-heading-filtered {
    margin-top: -315px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 900px) {
  .stripe-photo-blue-heading-filtered {
    background-color: rgba(44, 122, 203, 0.75);
    margin-top: -420px;
  }
  .stripe-photo-blue-heading-filtered h1 {
    line-height: 85px;
  }
}

.stripe-mike-cohn-left .container,
.stripe-lance-dacy-left .container {
  padding-top: 65%;
}
.stripe-mike-cohn-left .grid,
.stripe-lance-dacy-left .grid {
  --grid-item-width: 100%;
}

@media (min-width: 900px) {
  .stripe-mike-cohn-left .container,
  .stripe-lance-dacy-left .container {
    padding-top: 0;
  }
  .stripe-mike-cohn-left .grid,
  .stripe-lance-dacy-left .grid {
    --grid-item-width: 300px;
  }
}
.stripe-mike-cohn-left {
  background: #4e4f54 url(/mgs_assets/images/v4/photos/mike-left.jpg) 0 0/150% no-repeat;
}
@media (min-width: 900px) {
  .stripe-mike-cohn-left {
    background-position: 0 50%;
    background-size: contain;
  }
}

.stripe-mike-alt {
  background-color: #dee5f4;
}
@media (min-width: 900px) {
  .stripe-mike-alt {
    background: #ccd9eb url(/mgs_assets/images/v4/mike-large-alt.jpg) 70% 0/contain no-repeat;
  }
  .stripe-mike-alt .content-narrow {
    margin-left: 8.4615384615%;
    margin-right: 42.3076923076%;
    padding-right: 1em;
  }
}

[class*=stripe-content-bg-],
[class*=stripe-coursetype-] {
  background-color: #11253a;
  color: #dbe3e5;
}
@media (min-width: 900px) {
  [class*=stripe-content-bg-],
  [class*=stripe-coursetype-] {
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 760px) {
  .stripe-content-bg-1, .stripe-coursetype-csm,
  .stripe-coursetype-eus,
  .stripe-coursetype-lgk {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-1.jpg");
  }
  .stripe-content-bg-2 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-2.jpg");
  }
  .stripe-content-bg-3 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-3.jpg");
  }
  .stripe-content-bg-4 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-4.jpg");
  }
  .stripe-content-bg-5, .stripe-coursetype-cspo {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-5.jpg");
  }
  .stripe-content-bg-6 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-6.jpg");
  }
  .stripe-content-bg-7 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-7.jpg");
  }
  .stripe-content-bg-8, .stripe-coursetype-swa {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-8.jpg");
  }
  .stripe-content-bg-9 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-9.jpg");
  }
  .stripe-content-bg-10 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-10.jpg");
  }
  .stripe-content-bg-11 {
    background-image: url("/mgs_assets/images/v4/photos/content-bg-11.jpg");
  }
}
.stripe-content-bg-1, .stripe-coursetype-csm,
.stripe-coursetype-eus,
.stripe-coursetype-lgk {
  background-position: 100% 60%;
}

.stripe-content-bg-4 {
  background-color: #20387e;
}

.stripe-coursetype, .stripe-csm, .stripe-aep,
.stripe-eus,
.stripe-swa,
.stripe-cspo,
.stripe-us {
  margin-top: 0em;
  padding-top: 0.7368421053em;
  padding-bottom: 0em;
  margin-bottom: 0em;
  color: #fff;
  position: relative;
}
.stripe-coursetype.breadcrumbs a, .breadcrumbs.stripe-csm a, .breadcrumbs.stripe-aep a,
.breadcrumbs.stripe-eus a,
.breadcrumbs.stripe-swa a,
.breadcrumbs.stripe-cspo a,
.breadcrumbs.stripe-us a {
  color: rgba(255, 255, 255, 0.8);
}

.stripe-csm, .stripe-aep,
.stripe-eus,
.stripe-swa,
.stripe-cspo,
.stripe-us {
  background-color: #5e9a64;
  border-bottom: 112px solid #4b7a4f;
}

.stripe-coursetype-bus {
  background-image: url("/mgs_assets/images/v4/photos/content-bg-bus-2.jpg");
  background-position: 100% 50%;
}

.stripe-instructor-lance-dacy {
  background-image: url("/mgs_assets/images/v4/photos/content-bg-lance-1.jpg");
  background-position: 100% 0%;
}

.stripe-blog-title {
  margin-bottom: 1.4736842105em;
  background-color: #2c7acb;
  color: white;
}
.stripe-blog-title .entry-title,
.stripe-blog-title a {
  color: white;
}
.stripe-blog-title a:hover {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.stripe-blog-title .entry-subtitle {
  margin-top: 0;
  color: #d5efff;
}

[class*=srg-landing-] {
  background-color: #11253a;
  color: #dbe3e5;
}
@media (min-width: 900px) {
  [class*=srg-landing-] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 50%;
  }
}
[class*=srg-landing-] .banner-content {
  padding-bottom: 1.4736842105em;
  text-align: center;
}
@media (min-width: 900px) {
  [class*=srg-landing-] .banner-content {
    width: 49.2307692308%;
    float: right;
    margin-right: 0;
    text-align: left;
  }
  [class*=srg-landing-] .banner-content h1 {
    margin-top: 0.7368421053em;
    padding-top: 0em;
    padding-bottom: 0em;
    margin-bottom: 0.3684210526em;
    color: #65bef6;
  }
  [class*=srg-landing-] .banner-content p {
    font-size: 1.2em;
    font-weight: 300;
  }
}
@media (min-width: 1110px) {
  [class*=srg-landing-] .banner-content {
    width: 57.6923076924%;
    float: right;
    margin-right: 0;
  }
}

@media (min-width: 900px) {
  .srg-landing-1 {
    background-image: url("/mgs_assets/images/v4/photos/srg-landing-1.jpg");
  }
  .srg-landing-2 {
    background-image: url("/mgs_assets/images/v4/photos/srg-landing-2.jpg");
  }
  .srg-landing-3 {
    background-image: url("/mgs_assets/images/v4/photos/srg-landing-3.jpg");
  }
  .srg-landing-4 {
    background-image: url("/mgs_assets/images/v4/photos/srg-landing-4.jpg");
  }
  .srg-landing-5 {
    background-image: url("/mgs_assets/images/v4/photos/srg-landing-5.jpg");
  }
}
.banner-surviving-email {
  background: #b9def2;
}
@media (min-width: 900px) {
  .banner-surviving-email {
    background: #b9def2 url("/mgs_assets/images/v4/photos/daily-scrum.png") 50% 100% no-repeat;
  }
}
.banner-surviving-email h1 {
  color: #2c7acb;
}
.banner-surviving-email p {
  color: #25578b;
}
.banner-surviving-email .panel {
  margin-top: 1em;
  box-shadow: rgba(0, 0, 0, 0.5) 0 4px 14px;
}
@media (min-width: 900px) {
  .banner-surviving-email .panel {
    margin-top: 0;
  }
}
@media (min-width: 900px) {
  .banner-surviving-email .cs-content {
    margin-bottom: 13em;
  }
}

.banner-book-promo {
  background: #2c7acb;
  color: white;
}
.banner-book-promo .content {
  float: left;
  position: relative;
}
@media (min-width: 900px) {
  .banner-book-promo .content {
    margin-left: 18.6153846154%;
    margin-right: 18.6153846154%;
  }
}
.banner-book-promo h1, .banner-book-promo h2, .banner-book-promo h3, .banner-book-promo h4 {
  color: white;
}
.banner-book-promo .promo-image {
  width: 100%;
  float: left;
}
@media (min-width: 600px) {
  .banner-book-promo .promo-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    padding-left: 1.5384615383%;
  }
  .banner-book-promo .promo-image > img {
    display: block;
  }
}
.banner-book-promo .promo-text {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 30px;
  text-align: center;
}
@media (min-width: 600px) {
  .banner-book-promo .promo-text {
    padding-left: 45%;
  }
}

.stripe-tags {
  margin-top: 0em;
  padding-top: 1.4736842105em;
  padding-bottom: 0.7368421053em;
  margin-bottom: 0em;
  border-bottom: 1px solid #d5efff;
}
.stripe-tags p {
  color: #808080;
}

.banner {
  text-align: center;
  padding-bottom: 1px;
}
@media (min-width: 900px) {
  .banner {
    margin-top: 0em;
    padding-top: 4.4210526316em;
    padding-bottom: 1.4736842105em;
    margin-bottom: 0em;
  }
}
.banner h1 {
  font-size: 1.85rem;
  line-height: 1.2;
  padding: 1em 0;
  margin: 0;
  color: inherit;
  margin: 0;
}
@media (min-width: 600px) {
  .banner h1 {
    font-size: 2rem;
  }
}
@media (min-width: 900px) {
  .banner h1 {
    font-size: 2.5rem;
    padding: 0;
  }
}

.welcome-banner {
  background: #4d4e53 url(/mgs_assets/images/v4/pages/welcome/mike-video-full.jpg) top center/contain no-repeat;
  padding-top: 69%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 760px) {
  .welcome-banner {
    background: #4d4e53 url(/mgs_assets/images/v4/pages/welcome/mike-video-left.jpg) top center/cover no-repeat;
    min-height: 500px;
    padding-top: 0;
  }
}
.welcome-banner h1 {
  margin: -0.5em 0 0.25em;
}
@media (max-width: 760px) {
  .welcome-banner .content-split-section:first-child {
    padding-bottom: 29%;
    margin-top: -49%;
  }
}

.stripe-blob {
  background-image: url(/mgs_assets/images/v4/blob.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.stripe-mountains,
.bg-mountains {
  background-image: url(/mgs_assets/images/v4/mountains.svg);
  background-position: 85% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 3em 0;
}
.stripe-mountains.stripe-bordered,
.bg-mountains.stripe-bordered {
  border-top: none;
  border-bottom: 3px solid #b8e4ff;
}

.bg-light-grey-mountains {
  background: #f7f7f7 url(/mgs_assets/images/v4/footer-mountains.svg) 105% 100% no-repeat;
}

*[class*=banner-circles],
.banner-blue-circles {
  --bg-color: #25578b;
  background-color: var(--bg-color);
  background-image: url(/mgs_assets/images/v4/backgrounds/bg-circles-bottom.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 0% 100%;
  padding: 0;
  position: relative;
  color: white;
}
*[class*=banner-circles] h1, *[class*=banner-circles] h2, *[class*=banner-circles] h3,
*[class*=banner-circles] a,
.banner-blue-circles h1,
.banner-blue-circles h2,
.banner-blue-circles h3,
.banner-blue-circles a {
  color: white;
}
*[class*=banner-circles] table a:hover,
.banner-blue-circles table a:hover {
  color: white !important;
}
*[class*=banner-circles] > table td,
.banner-blue-circles > table td {
  border-color: #65bef6;
}
*[class*=banner-circles].image-right,
.banner-blue-circles.image-right {
  background-position: 100% 100%;
}
*[class*=banner-circles] .content-image-bleed .content,
.banner-blue-circles .content-image-bleed .content {
  padding-bottom: 2em;
}
@media (min-width: 760px) {
  *[class*=banner-circles] .content-image-bleed,
  .banner-blue-circles .content-image-bleed {
    grid-template-columns: minmax(1rem, 1fr) minmax(0, 26rem) minmax(150px, 4.5rem) minmax(150px, 4.5rem) minmax(0, 26rem) minmax(1rem, 1fr);
    align-items: center;
    justify-items: center;
  }
  *[class*=banner-circles] .content-image-bleed .content,
  .banner-blue-circles .content-image-bleed .content {
    grid-column: 2/4;
  }
  *[class*=banner-circles] .content-image-bleed .image,
  .banner-blue-circles .content-image-bleed .image {
    grid-column: 4/6;
    padding-left: 1rem;
  }
  *[class*=banner-circles] .content-image-bleed.reverse .image,
  .banner-blue-circles .content-image-bleed.reverse .image {
    grid-column: 1/4;
    padding-right: 1rem;
    padding-left: 0;
  }
  *[class*=banner-circles] .content-image-bleed.reverse .content,
  .banner-blue-circles .content-image-bleed.reverse .content {
    grid-column: 4/6;
  }
}

.bg-rings {
  background-image: url(/mgs_assets/images/v4/backgrounds/bg-rings.svg);
  background-position: 175% 2em;
  background-repeat: no-repeat;
  background-size: 70% auto;
}
@media (min-width: 900px) {
  .bg-rings {
    background-position: 105% 2em;
    background-size: auto;
  }
}

:is(.stripe-goat, .bg-goat) {
  background-color: #4796ea;
  background-image: url(/mgs_assets/images/v4/backgrounds/bg-goat.svg);
  background-position: 90% 25%;
  background-repeat: no-repeat;
  padding: 3em 0;
}
:is(.stripe-goat, .bg-goat) .h1 {
  color: #fff;
}

.banner-circles.course-banner.image-right {
  background-position: 110% 100%;
}

.banner-cta {
  background-color: #4797eb;
  padding: 1.5em 0;
}

.banner-circles-bright-blue + .banner-cta {
  background-color: #25578b;
  color: #fff;
}

.course-agile-mentors-bg {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #4797eb url(/mgs_assets/images/v4/pages/training/bubbles-pattern.svg) 0 0 repeat;
  color: #fff;
}
@media (min-width: 900px) {
  .course-agile-mentors-bg .course-features {
    margin-top: -2.5em;
  }
}

.course-agile-mentors-bg-dark {
  background-color: rgba(37, 87, 139, 0.5);
}

.bg-learning-paths {
  background: transparent url(/mgs_assets/images/v4/backgrounds/bg-learning-paths-road.svg) 50% 100%/100% no-repeat;
  padding-bottom: 26%;
}

.bg-transition-management {
  background: transparent url(/mgs_assets/images/v4/backgrounds/bg-scrum-transition.svg) 50% 100%/100% no-repeat;
  padding-bottom: 25%;
}

.bg-sample-sign {
  background: transparent url(/mgs_assets/images/v4/backgrounds/bg-sample-sign.svg) 50% 100%/100% no-repeat;
  padding: 3em 0;
}

.bg-questions {
  background: #65bef6 url(/mgs_assets/images/v4/backgrounds/bg-questions.svg) 50% 0 no-repeat;
}

.client-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.client-list li {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.client-list .client-name,
.client-list .client-logo,
.client-list .client-logo-small {
  text-align: center;
  flex: 1 1 22%;
  min-width: 150px;
  padding: 0 0.5em;
}
.client-list .client-name {
  line-height: 1.4736842105;
  font-size: 0.8rem;
}
@media (min-width: 900px) {
  .client-list .client-name {
    font-size: 0.8rem;
  }
}
.client-list .client-name a {
  padding: 1em 0;
}
.client-list .client-logo-small {
  flex-basis: 15%;
}
.client-list a {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  display: block;
}
.client-list a:active, .client-list a:focus {
  outline: none;
}
.client-list img {
  display: inline-block;
  max-width: 80%;
}

.logo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.logo-list > * {
  flex: 0 1 25%;
  min-width: 150px;
}

header[role=banner].no-nav .logo {
  width: 100%;
  margin-left: 0;
}
header[role=banner].no-nav .logo a {
  width: 100%;
  background-position: 50% 0;
  background-size: auto;
}

@media (min-width: 600px) {
  body.landing-video-course .benefit-list, body.landing-video-course .banner-intro {
    font-size: 16px;
  }
}

body.landing .stripe-first {
  padding-top: 1.4736842105em;
}
body.landing .heading-light {
  font-size: 26px;
  margin-top: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 0.7368421053em;
  font-weight: 100;
  color: #4c4d52;
}
body.landing .pretty-embed {
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 3px 0;
}
body.landing .pretty-embed img, body.landing .pretty-embed iframe {
  display: block;
}
body.landing .video iframe {
  display: block;
}

body.landing-video-course .button {
  padding-left: 4em;
  padding-right: 4em;
}
body.landing-video-course .stripe-mountains.stripe-first h1 {
  margin-bottom: 0;
}
body.landing-video-course .stripe-mountains.stripe-first h2 {
  font-size: 19px;
  margin-top: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  margin-bottom: 1.4736842105em;
  color: #4c4d52;
  font-weight: 600;
  text-align: center;
}
@media (min-width: 600px) {
  body.landing-video-course .banner-intro {
    width: 49.2307692308%;
    float: left;
    margin-right: 1.5384615383%;
  }
}
body.landing-video-course .banner-intro p:first-child {
  margin-top: 0;
}
@media (min-width: 600px) {
  body.landing-video-course .banner-video {
    width: 49.2307692308%;
    float: left;
    margin-right: 1.5384615383%;
    float: right;
    margin-right: 0;
  }
}
body.landing-video-course .banner-video-footer {
  font-size: 14px, 0.75;
  border-bottom-radius: 4px;
  background: #fff;
  color: #63a437;
  text-align: center;
  font-weight: 600;
  padding: 0.5em 1em 0.75em;
  border: 1px solid #bbbbbb;
}
@media (min-width: 600px) {
  body.landing-video-course .intro-stacked .banner-intro,
  body.landing-video-course .intro-stacked .banner-video {
    width: 83.0769230769%;
    float: left;
    margin-right: 1.5384615383%;
    margin-left: 8.4615384615%;
  }
}
body.landing-video-course .intro-stacked .banner-video iframe {
  margin: 0 auto 2em;
}
body.landing-video-course .intro-stacked .banner-intro ul {
  font-size: 18px;
  list-style-type: none;
  display: block;
  text-align: center;
}
body.landing-video-course .intro-stacked .banner-intro ul, body.landing-video-course .intro-stacked .banner-intro ul li {
  margin: 0;
  padding: 0;
  display: inline;
}
body.landing-video-course .intro-stacked .banner-intro ul li:not(:last-child)::after {
  content: ",";
}
@media (min-width: 900px) {
  body.landing-video-course .intro-video-narrow .banner-intro {
    width: 57.6923076924%;
    float: left;
    margin-right: 1.5384615383%;
  }
  body.landing-video-course .intro-video-narrow .banner-video {
    width: 40.7692307693%;
    float: left;
    margin-right: 1.5384615383%;
    float: right;
    margin-right: 0;
  }
}
body.landing-video-course .intro-video-narrow .heading-primary-alt {
  margin-bottom: 1em !important;
}
body.landing-video-course .intro-video-narrow .banner-intro ul {
  list-style-type: none;
  display: block;
  text-align: center;
}
body.landing-video-course .intro-video-narrow .banner-intro ul, body.landing-video-course .intro-video-narrow .banner-intro ul li {
  margin: 0;
  padding: 0;
  display: inline;
}
body.landing-video-course .intro-video-narrow .banner-intro ul li:not(:last-child)::after {
  content: ",";
}
@media (min-width: 600px) {
  body.landing-video-course .enroll-description {
    width: 40.7692307693%;
    float: left;
    margin-right: 1.5384615383%;
    margin-left: 8.4615384615%;
  }
}
body.landing-video-course .benefit-list ol {
  padding-left: 0;
}
body.landing-video-course .benefit-list li {
  margin-bottom: 1.4736842105em;
  padding-right: 2em;
}
body.landing-video-course .benefit-list h4 {
  color: #4c4d52;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}
body.landing-video-course .benefit-list p {
  margin-top: 0;
  color: #abb4b9;
}
@media (min-width: 900px) {
  body.landing-video-course .benefit-list-1 {
    width: 49.2307692308%;
    float: left;
    margin-right: 1.5384615383%;
  }
  body.landing-video-course .benefit-list-2 {
    width: 49.2307692308%;
    float: left;
    margin-right: 1.5384615383%;
    float: right;
    margin-right: 0;
  }
}
body.landing-video-course .role {
  margin-bottom: 1.4736842105em;
  clear: both;
}
@media (min-width: 900px) {
  body.landing-video-course .role {
    margin-left: 8.4615384615%;
    margin-right: 8.4615384615%;
  }
}
body.landing-video-course .role-avatar-wrapper {
  text-align: center;
}
@media (min-width: 600px) {
  body.landing-video-course .role-avatar-wrapper {
    width: 18.5185185186%;
    float: left;
    margin-right: 1.8518518517%;
    text-align: right;
  }
}
body.landing-video-course .role-avatar {
  border-radius: 100%;
  background: #fff;
  width: 90px;
  height: 90px;
  display: inline-block;
}
body.landing-video-course .role-avatar img {
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
body.landing-video-course .role h2 {
  margin: 0;
}
body.landing-video-course .role h2, body.landing-video-course .role p {
  color: #4c4d52;
}
@media (min-width: 600px) {
  body.landing-video-course .role h2, body.landing-video-course .role p {
    width: 79.6296296297%;
    float: left;
    margin-right: 1.8518518517%;
    float: right;
    margin-right: 0;
  }
}

body.landing-mindtools .banner-feature {
  margin-bottom: 1.4736842105em;
}
@media (min-width: 900px) {
  body.landing-mindtools .banner-feature {
    width: 49.2307692308%;
    float: left;
    margin-bottom: 0;
  }
  body.landing-mindtools .banner-feature:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  body.landing-mindtools .banner-feature:nth-child(2n+2) {
    margin-left: 50.7692307692%;
    margin-right: -100%;
    clear: none;
  }
}
@media (min-width: 900px) {
  body.landing-mindtools .post-med {
    width: 49.2307692308%;
    float: left;
  }
  body.landing-mindtools .post-med:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  body.landing-mindtools .post-med:nth-child(2n+2) {
    margin-left: 50.7692307692%;
    margin-right: -100%;
    clear: none;
  }
}
@media (min-width: 900px) {
  body.landing-mindtools .img-bleed {
    margin: -1em;
  }
}

.start-here-amc-panel {
  background: #4b9aea url(/mgs_assets/images/v4/pages/landing/amc-pattern.png) 0 0 repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem;
}
@media (min-width: 900px) {
  .start-here-amc-panel {
    flex-direction: row;
  }
}
.start-here-amc-panel > * {
  flex: 1 1 33%;
  margin: 1em;
}

.courses-grid {
  justify-items: center;
  gap: 2rem 1rem;
}

@media print, pdf {
  html, body {
    background: white;
  }
  header.site-header {
    display: none;
  }
  footer.site-footer {
    display: none;
  }
  nav.mm-menu {
    display: none;
  }
  nav.breadcrumbs {
    display: none;
  }
  .container {
    page-break-after: auto;
  }
  .related-entries, .entry-comments {
    display: none;
  }
  .post-footer-full .footer-actions, .post-footer-full .social-media, .post-footer-full .about-avatar {
    display: none;
  }
}