
// Course Description Modal
.course-description-modal {
  header, footer {
    margin: -30px;
    padding: 1em 30px;
  }
  header {
    margin-bottom: 0;

    @include breakpoint($lg) {
      display: flex;
    }

    .media {
      flex: 5;
    }
    .media-item {
      max-width: 20%;
    }
    .course-modal-cta {
      flex: 1;
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: .5em;
      background-color: palette(blue, x-light);
      padding: 1em;
      margin-top: 1em;
      margin-right: -30px;
      text-align: center;

      @include breakpoint($lg) {
        margin-top: -1em;
        margin-bottom: -1em;
        flex-direction: column;
      }
    }
  }
  footer {
    margin-top: 1em;
  }
  .kicker {
    color: palette(teal);
  }
  summary:before {
    top: .25em;
  }
  details:first-child {
    margin-top: -.75em;
  }
}