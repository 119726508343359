// Badges
// ----------------------------------------------------------------

$badge-bg    : #00c3a9 !default;
$badge-color : #fff !default;


.badge {
  background-color: $badge-bg;
  color: $badge-color;
  font-weight: bold;
  font-size: 0.6rem;
  display: inline-block;
  text-align: center;
  padding: .125em 1em;
  text-transform: uppercase;
  letter-spacing: .05em;
  border-radius: 1.25em;
}

.badge-yellow {
  background-color: palette(yellow, light);
  color: #222;
}

.badge-alt {
  border-radius: .5em;
}

.badge-wide {
  padding-left: 2em;
  padding-right: 2em;
}

.badge[class*="in-person"]       { background-color: #ffa728; }
.badge[class*="live-online"]     { background-color: #fb606e; }
.badge[class*="on-demand-video"] { background-color: #25578b; }
.badge[class*="certified"]       { background-color: #40a4b3; }

.badge-large,
.badge-xl {
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
}
.badge-xl {
  font-size: 1.25em;
}