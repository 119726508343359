// Filters
// ----------------------------------------------------------------

$filters-custom-input-height: 40px;

.filters {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > * {
    margin-top: .125em;
    margin-bottom: .125em;
  }

  > .field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  label {
    width: auto;
    margin: 0 .5em;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 400;
  }

  .chosen-label {
    line-height: $filters-custom-input-height; // fudge-factor to valign with the chosen-container
  }


  // Fixes for Chosen
  .chosen-container {
    text-align: left;
    z-index: 1;

    & + input[type=submit] {
      vertical-align: top;
    }
  }
  .chosen-container-multi .chosen-choices li {
    clear: none;
  }
  .chosen-container-multi .chosen-choices {
    border-radius: $default-border-radius;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .search-choice-close {
    transition: none;
    border: none;
  }

  // Make chosen select taller
  .chosen-single {
    height: $filters-custom-input-height;
    padding-top: 7px;
  }

  // Styles for custom selectboxit select boxes
  .selectboxit-container {
    .selectboxit,
    .selectboxit-options a,
    span {
      height: $filters-custom-input-height;
      line-height: $filters-custom-input-height;
    }
    .selectboxit-text {
      color: #333;      
    }
  }


  // Show submit button when form is changed
  .filters-submit {
    transition: all $default-transition-duration;
    position: relative;
    left: -.5em;
    opacity: 0;
    z-index: 0;
    height: $filters-custom-input-height;
    padding: 0 .75em
  }
  .is-changed .filters-submit {
    left: .25em;
    opacity: 1;
  }

}

.selectboxit-option-anchor {
  border-bottom: none !important;
}

// Only show filters-tags on wider screens -- ie. not on mobile
.filters-tags {
  display: none;
  @include breakpoint($med) {
    display: block;
  }
}

// Hide filters until chosen is loaded to avoid flash of unstyled select box
.filter-tags-form { height: 1px; overflow: hidden; }
.filter-tags-form.is-chosen-ready { height: auto; overflow: visible; }
