// Client List & Logos
// ----------------------------------------------------------------

.client-list {
  @include unstyled-list;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .client-name,
  .client-logo,
  .client-logo-small {
    text-align: center;
    flex: 1 1 22%;
    min-width: 150px;
    padding: 0 .5em;
  }

  .client-name {
    @include type-setting(-1);
    // border-bottom: lighten(palette(grey, x-light), 5) solid 1px; 

    a { padding: 1em 0; }   
  }

  .client-logo-small {
    flex-basis:  15%;
  }

  a {
    @include unstyled-link;
    display: block;
  }

  img {
    display: inline-block;
    max-width: 80%;
  }
}


// As seen in logos
// ----------------------------------------------------------------
.logo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    flex: 0 1 25%;
    min-width: 150px;
  }
}