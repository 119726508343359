// Details Summary Accordion
// ----------------------------------------------------------------
$summary-closed-color: palette(blue) !default;
$summary-open-color: palette(teal) !default;
$summary-icon-width: 18px !default;
$summary-icon-gap: .5em !default;

details {
  padding-block: .75em;
  padding-inline-start: calc($summary-icon-width + $summary-icon-gap);
}
details + details {
  border-top: 1px solid #D0DAE2;
}

summary {
  outline: none;
  display: flex;
  align-items: start;
  gap: .5em;
  cursor: pointer;
  margin-inline-start: calc(-1* ($summary-icon-width + $summary-icon-gap));

  &::-webkit-details-marker {
    display: none
  }

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='18' height='18' fill='#{$summary-closed-color}' rx='2'/%3E%3Cpath fill='%23FFF' d='M10 4v4h4v2h-4v4H8V9.999L4 10V8l4-.001V4h2Z'/%3E%3C/g%3E%3C/svg%3E");
    display: block;
    height: $summary-icon-width;
    width: $summary-icon-width;
    position: relative;
    top: .125em;
  }

  &:hover {
    color: $link-color;

    &::before {
      opacity: .8;
    }
  }

  .badge {
    position: relative;
    top: 0.375em;
  }

  & + * {
    margin-top: .25em;
  }
}

details[open] summary {
  color: $summary-open-color;

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='18' height='18' fill='#{$summary-open-color}' rx='2'/%3E%3Cpath fill='%23FFF' d='M14 8v2H4V8z'/%3E%3C/g%3E%3C/svg%3E");
  }
}

// Correct lists in details
details :is(ul,ol) {
  padding-inline-start: 1em;
}

// Box Summary/Details
details.box {
  padding: $box-padding;
  padding-inline-start: calc($summary-icon-width + $summary-icon-gap + $box-padding);
}