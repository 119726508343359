// Stripe Padding
// ----------------------------------------------------------------
.stripe        { padding-top: 3rem; padding-bottom: 3rem; }
.stripe-med    { padding-top: 2.25rem; padding-bottom: 2.25rem; }
.stripe-thin   { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.stripe-x-thin { padding-top: .75rem; padding-bottom: .75rem; }


// Stripe Colors
// ----------------------------------------------------------------
.stripe-blue-gradient, .bg-blue-gradient       { background: linear-gradient(#4898dd, #2c7acb); }

.stripe-blue, .bg-blue                         { background-color: palette(blue); }
.stripe-blue-dark, .bg-blue-dark               { background-color: palette(blue, dark); }
.stripe-blue-x-dark, .bg-blue-x-dark           { background-color: palette(blue, x-dark); }
.stripe-blue-xxx-dark, .bg-blue-xxx-dark       { background-color: palette(blue, xxx-dark); }
.stripe-blue-x-light, .bg-blue-x-light         { background-color: #ebf7ff; }
.stripe-blue-xx-light, .bg-blue-xx-light       { background-color: palette(blue, xx-light); }
.stripe-lightblue, .bg-lightblue,
.stripe-blue-light, .bg-blue-light             { background-color: palette(blue, x-light); color: #5d7891; }

.bg-blue-bright                                 { background-color: palette(blue, light);}

.stripe-teal, .bg-teal                         { background-color: palette(teal); }

.stripe-grey-dark, .bg-grey-dark               { background-color: palette(grey, mid-dark); color: white;}
.stripe-grey-light, .bg-grey-light             { background-color: palette(grey, light); }
.stripe-grey-x-light, .bg-grey-x-light         { background-color: palette(grey, x-light); }
.stripe-grey-x-light-alt, .bg-grey-x-light-alt { background-color: #f7f7f7; }

// Dark bg stripes require white text
// ----------------------------------------------------------------

@mixin stripe-invert {
  color: white;

  h1, h2, h3,
  a {
    color: white;
  }

  table a:hover {
    color: white !important;
  }

  > table td {
    border-color: palette(blue, light);
  }
}

.stripe-dark,
.stripe-blue,
.stripe-blue-dark,
.stripe-blue-x-dark,
.stripe-blue-xxx-dark {
  @include stripe-invert;
}


.stripe-bordered {
  border-top: 1px solid $divider-color;
  border-bottom: 1px solid $divider-color;

  &.stripe-grey-x-light-alt {
    border-color: #e6e6e6;
  }
}

@mixin banner-heading {
  font-size: 1.85rem;
  line-height: 1.2;
  padding: 1em 0;
  margin: 0;

  @include breakpoint($med) {
    font-size: 2rem
  }

  @include breakpoint($lg) {
    font-size: 2.5rem;
    padding: 0;
  }
}
.banner-heading {
  @include banner-heading;
}


// Photo Backgrounds
// ----------------------------------------------------------------

$spb-height: $base-line-height * 15;
$spb-heading-height: $base-line-height * 4;

.stripe-photo-blue {
  overflow: hidden;

  @include breakpoint($lg) {
    height: $spb-height;
  }

  &-filtering {
    overflow: visible;
    position: relative;
  }

  &-image {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: none;

    @include breakpoint($lg) {
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(37, 87, 139, 0.4);
    }

    img {
      width: 100%;      
    }
  }

  &-heading {
    text-align: center;
    color: white;
    position: relative;
    background-color: palette(blue, dark);
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    align-items: center;

    @include breakpoint($lg) {
      background-color: rgba(44, 122, 203, .75);
      margin-top: $spb-heading-height * -1;
      height: $spb-heading-height;
      line-height: $spb-heading-height;
      padding-left: 0;
      padding-right: 0;
    }

    h1 {
      @include banner-heading;
      color: inherit;
      width: 100%;

      @include breakpoint($lg) {
        line-height: $spb-heading-height;
      }
    }
  }

  &-heading-full {
    @extend .stripe-photo-blue-heading;
    @include padding-leader(3);
    line-height: line-height(2);
    height: $spb-height;
    margin-top: $spb-height * -1;
  }

  &-heading-filtered {
    @extend .stripe-photo-blue-heading-full;
    @include padding-leader(1.5);
    @include breakpoint($med) {
      margin-top: $spb-height * -.75;
      padding-left: 0;
      padding-right: 0;
    }
    @include breakpoint($lg) {
      background-color: rgba(44, 122, 203, .75);
      margin-top: $spb-height * -1;
      h1 {
        line-height: 85px;
      }
    }
  }
}

// Instructors
// ----------------------------------------------------------------
.stripe-mike-cohn-left,
.stripe-lance-dacy-left {
  .container {
    padding-top: 65%;
  }
  .grid {
    --grid-item-width: 100%; // Ensure grid item (bio) stays wide
  }
}
@include breakpoint($lg) {
  .stripe-mike-cohn-left,
  .stripe-lance-dacy-left {
    .container {
      padding-top: 0;
    }
    .grid {
      --grid-item-width: 300px; // reset to standard grid width
    }
  }
}

.stripe-mike-cohn-left {
  background: #4e4f54 url(/mgs_assets/images/v4/photos/mike-left.jpg) 0 0 / 150% no-repeat;

  @include breakpoint($lg) {
    background-position: 0 50%;
    background-size: contain;
  }
}

.stripe-mike-alt {
  background-color: #dee5f4;

  @include breakpoint($lg) {
    background: #ccd9eb url(/mgs_assets/images/v4/mike-large-alt.jpg) 70% 0 / contain no-repeat;

    .content-narrow {
      @include squish(1,5);
      padding-right: 1em;
    }
  }
 
}

// Content Backgrounds
// ----------------------------------------------------------------
[class*='stripe-content-bg-'],
[class*='stripe-coursetype-'] {
  background-color: #11253a; // extra dark blue to match content-bg images
  color: palette(grey, x-light);

  @include breakpoint($lg) {
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
// Only show pictures if big enough
@include breakpoint($med-lg) {
  @for $i from 1 through 11 {
    .stripe-content-bg-#{$i} {
      background-image: url('/mgs_assets/images/v4/photos/content-bg-#{$i}.jpg');
    }
  }  
}
.stripe-content-bg-1 { background-position: 100% 60%; }
.stripe-content-bg-4 { background-color: #20387e; }


// Course stripes
// ----------------------------------------------------------------
.stripe-coursetype {
  @include rhythm(0,.5,0,0);
  color: #fff;
  position: relative;

  &.breadcrumbs a {
    color: rgba(255,255,255,.8);
  }
}

@mixin coursetype-stripe-color($color) {
  background-color: $color;
  border-bottom: $base-line-height * 4 solid darken($color, 10);
}

.stripe-csm  { @extend .stripe-coursetype; @include coursetype-stripe-color(#5e9a64); }
.stripe-aep,
.stripe-eus,
.stripe-swa,
.stripe-cspo,
.stripe-us {
  @extend .stripe-csm;
}

.stripe-coursetype-cspo { @extend .stripe-content-bg-5; }

.stripe-coursetype-csm,
.stripe-coursetype-eus,
.stripe-coursetype-lgk { @extend .stripe-content-bg-1; }

.stripe-coursetype-swa { @extend .stripe-content-bg-8; }

.stripe-coursetype-bus {
  background-image: url('/mgs_assets/images/v4/photos/content-bg-bus-2.jpg');
  background-position: 100% 50%;
}

.stripe-instructor-lance-dacy {
  background-image: url('/mgs_assets/images/v4/photos/content-bg-lance-1.jpg');
  background-position: 100% 0%;
}

// Blog and Agile Pages
// ----------------------------------------------------------------
.stripe-blog-title {
  @include trailer;
  background-color: palette(blue, dark);
  color: white;

  .entry-title,
  a {
    color: white;
  }
  a:hover {
    color: white;
    text-decoration: none;
    border-bottom: 1px solid rgba(255,255,255,.5);
  }

  .entry-subtitle {
    margin-top: 0;
    color: palette(blue, x-light);
  }
}


// Scrum Landing Page banner backgrounds
// ----------------------------------------------------------------
[class*='srg-landing-'] {
  background-color: #11253a; // extra dark blue to match content-bg images
  color: palette(grey, x-light);

  @include breakpoint($lg) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 50%;
  }

  .banner-content {
    @include padding-trailer;
    text-align: center;

    @include breakpoint($lg) {
      @include span(last 6);
      text-align: left;

      h1 {
        @include rhythm(.5, 0, 0, .25);
        color: palette(blue, light);
      }
      p {
        font-size: 1.2em;
        font-weight: 300;
      }
    }

    @include breakpoint(1110px) {
      @include span(last 7);
    }
  }
}
@include breakpoint($lg) {
  @for $i from 1 through 5 {
    .srg-landing-#{$i} {
      background-image: url('/mgs_assets/images/v4/photos/srg-landing-#{$i}.jpg');
    }
  }
}

// Surviving Email Banner
.banner-surviving-email {
  background: #b9def2;

  @include breakpoint($lg) {
    background: #b9def2 url('/mgs_assets/images/v4/photos/daily-scrum.png') 50% 100% no-repeat;
  }

  h1 { color: palette(blue, dark); }
  p { color: palette(blue, x-dark); }

  .panel {
    margin-top: 1em;
    box-shadow: rgba(0, 0, 0, .5) 0 4px 14px;

    @include breakpoint($lg) {
      margin-top: 0;
    }
  }

  .cs-content {
    @include breakpoint($lg) {
      margin-bottom: 13em; // ensure space underneath for illustration
    }
  }
}


// Book Promo Banner
// ----------------------------------------------------------------
.banner-book-promo {
  background: palette(blue, dark);
  color: $body-bg-color;
  .content {
    float: left;
    position: relative;
    @include breakpoint($lg) {
      @include squish(2.2,2.2);
    }
  }
  h1, h2, h3, h4 {
    color: $body-bg-color;
  }
  .promo-image {
    width: 100%;
    float: left;
    @include breakpoint($med) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40%;
      padding-left: gutter();
      > img {
        display: block;
      }
    }
  }
  .promo-text {
    @include span(12);
    padding-bottom: 30px;
    text-align: center;
    @include breakpoint($med) {
      padding-left: 45%;
    }
  }
}


// Blog Tags
// ----------------------------------------------------------------
.stripe-tags {
  @include rhythm(0,1,.5,0);
  border-bottom: 1px solid $divider-color;
  p {
    color: palette(grey, base);
  }
}


// Banner
// ----------------------------------------------------------------
.banner {
  text-align: center;
  padding-bottom: 1px;

  @include breakpoint($lg) {
    @include rhythm(0,3,1,0);
  }

  h1 {
    @include banner-heading;
    color: inherit;
    margin: 0;
  }
}


// Welcome Banner
// ----------------------------------------------------------------
.welcome-banner {
  background: #4d4e53 url(/mgs_assets/images/v4/pages/welcome/mike-video-full.jpg) top center / contain no-repeat;
  padding-top: 69%; // aspect ratio to push content below image

  @include breakpoint($med-lg) {
    background: #4d4e53 url(/mgs_assets/images/v4/pages/welcome/mike-video-left.jpg) top center / cover no-repeat;
    min-height: 500px; // show good amount of image
    padding-top: 0;
  }

  // Ensure all content is centered vertically
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: -.5em 0 .25em;
  }

  @include breakpoint(max-width $med-lg) {
    .content-split-section:first-child {
      padding-bottom: 29%;
      margin-top: -49%;
    }
  }
}


// Blob
.stripe-blob {
  background-image:url(/mgs_assets/images/v4/blob.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

// Mountains
.stripe-mountains,
.bg-mountains {
  background-image: url(/mgs_assets/images/v4/mountains.svg);
  background-position: 85% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 3em 0;
  
  &.stripe-bordered {
    border-top: none;
    border-bottom: 3px solid #b8e4ff;
  }
}
.bg-light-grey-mountains {
  background: #f7f7f7 url(/mgs_assets/images/v4/footer-mountains.svg) 105% 100% no-repeat;
}

// BG Circles
*[class*="banner-circles"],
.banner-blue-circles {
  --bg-color: #25578b;
  background-color: var(--bg-color);
  background-image: url(/mgs_assets/images/v4/backgrounds/bg-circles-bottom.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 0% 100%;
  padding: 0; // no padding to sit the illustration in the blue circles
  position: relative;
  @include stripe-invert;

  &.image-right {
    background-position: 100% 100%;
  }

  // Add padding to bottom of content so illustration can sit flush ottom
  .content-image-bleed .content {
    padding-bottom: 2em;
  }

  // Content-image bleeds are now 50/50 in a banner
  @include breakpoint($med-lg) {
    .content-image-bleed {
      grid-template-columns: minmax(1rem,1fr) minmax(0, 26rem) minmax(150px, 4.5rem) minmax(150px, 4.5rem) minmax(0, 26rem) minmax(1rem,1fr);
      align-items: center;
      justify-items: center;

      .content { grid-column: 2/4; }
      .image { grid-column: 4/6; padding-left: 1rem; }
    
      &.reverse {
        .image { grid-column: 1/4; padding-right: 1rem; padding-left: 0;}
        .content { grid-column: 4/6; }
      }
    }
  }
}

// Rings
.bg-rings {
  background-image: url(/mgs_assets/images/v4/backgrounds/bg-rings.svg);
  background-position: 175% 2em;
  background-repeat: no-repeat;
  background-size: 70% auto;

  @include breakpoint($lg) {
    background-position: 105% 2em;
    background-size: auto;
  }
}

// Goat
:is(.stripe-goat, .bg-goat) {
  background-color: #4796ea;
  background-image: url(/mgs_assets/images/v4/backgrounds/bg-goat.svg);
  background-position: 90% 25%;
  background-repeat: no-repeat;
  padding: 3em 0;

  .h1 { color: #fff; }
}

.banner-circles.course-banner.image-right {
  background-position: 110% 100%;
}

// Banner CTA
.banner-cta {
  background-color: palette(blue);
  padding: 1.5em 0;
}
// If following a banner, adjust the bg color
.banner-circles-bright-blue + .banner-cta {
  background-color: palette(blue, x-dark);
  color: #fff;
}


// Agile Mentors Promo
// ----------------------------------------------------------------
.course-agile-mentors-bg {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #4797eb url(/mgs_assets/images/v4/pages/training/bubbles-pattern.svg) 0 0 repeat;
  color: #fff;

  @include breakpoint($lg) {
    .course-features {
      margin-top: -2.5em;
    }  
  }
}
.course-agile-mentors-bg-dark {
  background-color: rgba(37,87,139,0.50);
}


// Misc BG image stripes
// ----------------------------------------------------------------

.bg-learning-paths {
  background: transparent url(/mgs_assets/images/v4/backgrounds/bg-learning-paths-road.svg) 50% 100% / 100% no-repeat;
  padding-bottom: 26%;
}

.bg-transition-management {
  background: transparent url(/mgs_assets/images/v4/backgrounds/bg-scrum-transition.svg) 50% 100% / 100% no-repeat;
  padding-bottom: 25%;
}

.bg-sample-sign {
  background: transparent url(/mgs_assets/images/v4/backgrounds/bg-sample-sign.svg) 50% 100% / 100% no-repeat;
  padding: 3em 0;
}

.bg-questions {
  background: #65bef6 url(/mgs_assets/images/v4/backgrounds/bg-questions.svg) 50% 0 no-repeat;
}