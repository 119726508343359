@use 'sass:math';

// Forms
// ----------------------------------------------------------------

$default-label-width:   25% !default;
$default-label-margin:  2% !default;
$default-input-width:   70% !default;


@mixin form-defaults() {
  // Defaults from http://html5boilerplate.com
  
  textarea { overflow: auto; } /* www.sitepoint.com/blogs/2010/08/20/ie-remove-textarea-scrollbars/ */
   
  .ie6 legend, .ie7 legend { margin-left: -7px; }
   
  // Align checkboxes, radios, text inputs with their label by: Thierry Koblentz tjkdesign.com/ez-css/css/base.css
  input[type="radio"] { vertical-align: baseline; }
  input[type="checkbox"] { vertical-align: baseline; }
  .ie7 input[type="checkbox"] { vertical-align: baseline; }
  .ie6 input { vertical-align: text-bottom; }
   
  // Hand cursor on clickable input elements
  label, input[type="button"], input[type="submit"], input[type="image"], button { cursor: pointer; }
   
  // Webkit browsers add a 2px margin outside the chrome of form elements
  button, input, select, textarea { margin: 0; }
  
  // make buttons play nice in IE:   
  // www.viget.com/inspire/styling-the-button-element-in-internet-explorer/
  button {  width: auto; overflow: visible; }
}


// FORM FIELD DEFAULTS
// ========================================
@mixin no-label-padding {
  @include breakpoint($sm) {
    padding-left: $default-label-width + $default-label-margin;
  }
}

@mixin form-element {
  float: left;
  clear: left;
  width: 100%;
  margin: 0 0 math.div($base-line-height, 2);
}

@mixin form-element-stacked {
  float: none;
  clear: both;
  width: 100%;
  margin: 0 0 1.2em;
}

@mixin label-stacked {
  display: block;
  float: none;
  width: auto;
  margin: 0;
}

@mixin label-left {
  @include label-stacked;
  font-weight: bold;
  padding: 4px 0;

  @include breakpoint($sm) {
    float: left;
    margin-right: $default-label-margin;
    width: $default-label-width;
  }
}

@mixin text-input {
  border-radius: 3px;
  font-family: $sans-font;
  font-size: $base-font-size;
  line-height: $base-line-height;
  border: 1px solid #c6c7c0;
  margin: 0;
  padding: 7px .5em;
  width: 100%;

  @include breakpoint($sm) {
    width: $default-input-width;
    &.tiny  { width: math.div($default-input-width, 7); }
    &.short { width: math.div($default-input-width, 2.25); }
    &.large { width: $default-input-width * 1.25; }
  }  

  &.bigtext {
    font-size: 18px;
    line-height: $base-line-height * 2;
  }  

  &.default,
  &.placeholder { color: #999999; }
  
  &:active, &:focus, &:hover {
    border-color: #555555;
  }

  &[disabled],
  &[disabled]:hover {
    color: $quiet-font-color;
    border-color: #d3d8da;
    background-color: #f7f7f7;
    cursor: default;
  }
}



%text-input {
  @include text-input;
}


fieldset,
fieldset li {
  &.no_margin { margin-bottom: 0; }
}

fieldset {
  overflow: hidden;
  @include trailer;
  border: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

form {
  p {
    @include trailer;
  }
  ol, ul {

    float: left;
    clear: left;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    
    &.ruled {
      border-top: 1px solid lighten($divider-color,5);
      > li {
        padding: .5em 0;
        margin-bottom: 0;
        border-bottom: 1px solid lighten($divider-color,5);
      }
    }
    
    li {
      @include form-element;
      
      /* groups of inputs (usually checkboxes or radio buttons) */
      .inputgroup {
        margin-left: $default-label-width;
        padding-top: .5em;
      }
      ul.inputgroup {
        clear: none;
        list-style: none;
        margin-bottom: 0;
        li {
          margin-bottom: 0;
          label {
            width: auto;
            font-weight: normal;
          }
        }
      }
      
      /* instructions beneath the input */
      .instructions {
        float: left;
        margin-right: 0.5em;
        font-size: 0.91em;
        &.optional {
          color: $quiet-font-color;
          font-style: italic;
          font-size: 12px;
        }
        label {
          float: none;
          clear: both;
          display: block;
          margin: 0;
          padding-top: 4px;
          width: auto;
          font-size: 0.91em;
          font-weight: normal;
          line-height: 1;
          color: #777777;
        }
      }
    }
  }
}

p.submit,
div.submit,
fieldset.submit,
li.submit {
  @include no-label-padding;
  input { display: inline; }
}

.no-label {
  @include no-label-padding;
}

legend span {
  display: block;
  line-height: 22px;
  font-weight: bold;
  font-family: $heading-font;
  padding-bottom: 1.2em;
  white-space: normal;
}

label {
  @include label-left;
}

input.text,
input[type=text],
input[type=email],
input[type=url],
input[type=tel],
input[type=password],
textarea {
  @extend %text-input;
}
textarea {
  height: 8em;
}

select {
  margin: 4px 0;
}

.checkboxes,
.radios,
.choices-group {
  margin-left: 0;

  li {
    margin-bottom: 0;
  }
  
  input[type=checkbox],
  input[type=radio] {
    margin-right: .5em;
    display: inline;
  }
  input[type=radio] {
    vertical-align: baseline;
  }
  label {
    float: none;
    width: auto;
    display: inline;
    font-weight: normal;
  }
}

input.checkbox, input[type=checkbox] {
  margin-right: 0.5em;
  display: inline-block;
}
label.checkbox {
  float: none;
  width: auto;
  display: inline-block;
}

/* Top Labels
---------------------------------------- */
.top_labels,
.stacked {
  ul, ol, .instructions {
    float: none;
  }

  li {
    @include form-element-stacked;

    li {
      margin-bottom: 0;
    }
  }

  label {
    @include label-stacked;
  }

  .checkboxes,
  .radios,
  .choices-group {
    li {
      @include trailer(.5);
    }
    label {
      display: inline;
    }
  }

  input.text,
  input[type=text],
  input[type=email],
  input[type=url],
  input[type=tel],
  textarea {
    margin-left: 0;
    width: 100%;
  }
  .submit, &.submit {
    padding-left: 0;
  }
}

/* Wide Labels
---------------------------------------- */
.wide-labels {
  li > label {
    width: 45%
  }
  input.text,
  input[type=text],
  input[type=email],
  input[type=url],
  input[type=tel],
  input[type=password],
  textarea {
    width: 50%;
  }
  .submit,
  .no-label {
    padding-left: 47%;
  }
}


// Button Radios
// ----------------------------------------------------------------
.button-radios {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include breakpoint($lg) {
    flex-direction: row;
  }


  li {
    flex: 1 0 0;
    padding: .25em;
  }

  label {
    @include type-setting(-1);
    transition: all .2s;
    border-radius: 4px;
    background: white;
    border: 1px solid palette(blue, x-light);
    padding: 1em .5em;
    text-align: center;
    display: block;
    cursor: pointer;
    color: palette(blue, light);
    position: relative;
    height: 100%;
    width: 100%;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      background-color: palette(blue, x-light);
    }

    &.has-icon {
      padding-top: 2em;
    }
  }

  .button-radio-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    font-size: 3em;
    margin: -.5em auto .25em;

    &.fa-question-circle,
    &.fa-meh,
    &.fa-dizzy,
    &.fa-grin-stars {
      &:before {
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }

  strong {
    @include type-setting(3);
    line-height: 1.1;
    display: inline-block;
    width: 100%;
  }

  input[type=radio] {
    position: absolute;
    opacity: 0;

    &:checked + label,
    &:checked + label:active {
      border-color: palette(teal);
      color: palette(teal);
      background-color: white;
    }
  }
}




// Email Signup Form
// ----------------------------------------------------------------
form.email-signup-horizontal {
  @extend .stacked;

  @include breakpoint($lg) {
    > p {
      display: inline-block;
      width: span(3 of 9);

      & + p {
        margin-top: 0;
        margin-left: gutter();
      }
    }
    .button {
      width: 100%;
    }
  }
}





// FLASH NOTICES AND ERRORS
// ========================================

#flash {
  clear: both;
  padding: 8px 10px;
  margin-bottom: 1em;
  color: #222222;
  background: #fffbbe;
  border: 1px solid #d8d056;
  
  &.error {
    background: #fde5e5;
    border: 1px solid #cb7a7a;
  }
  p {
    margin-bottom: 0;
  }
}

#errorExplanation,
#error_explanation,
.error-explanation {
  clear: both;
  padding: 8px 10px;
  margin-bottom: 2em;
  color: #222222;
  background: #fde5e5;
  border: 1px solid #cb7a7a;
  
  h2, .error-title {
    color: #990000;
    font-size: 1.4em;
    margin-bottom: 0.35em;
  }
  p {
    margin-bottom: 0.8em;
  }
  ul {
    margin-bottom: 0;
  } 
}

.fieldWithErrors,
.field_with_erors,
.field-with-errors {
  label {
    color: #880000;
  }
  input.text,
  input[type=text],
  input[type=email],
  input[type=url],
  input[type=tel],
  input[type=password],
  textarea {
    background: #fde5e5;
  }
}

// Group error messages to inputs
.input-group {
  display: inline-block;
  width: 70%;
  input, textarea {
    width: 100%;
  }
  .error-explanation {
    margin-top: .5em;
    margin-bottom: 1em;
  }
}

//  SPECIFIC FORM ELEMENTS
// ======================================== 

// Address
// ----------------------------------------
$address-input-width: 100%;
form {
  ol.address,
  ul.address {
    float: left;
    clear: none;
    width: $default-input-width;
    margin-left: 0;
    margin-bottom: 0;
    
    .street_address,
    .locality,
    .region,
    .postal_code {
      float: left;
      clear: none;
      
      label {
        width: auto;
        position: relative;
        top: $base-line-height * 1.5;
        font-weight: normal;
        font-size: $base-font-size * .84;
        color: $quiet-font-color;
      }
      input {
        @include trailer(.5);
        position: relative;
        top: -1.95em;
        width: 100%;
      }
    }
    .locality {
      width: $address-input-width * .5;
    }
    .region {
      width: $address-input-width * .2;
      margin: 0 ($address-input-width * .05);
    }
    .postal_code {
      width: $address-input-width * .2;
      float: left;
    }
    .country {
      label {
        display: none;
      }
    }
  }
}


// Name
// ----------------------------------------
form {
  ol.name,
  ul.name {
    float: left;
    clear: none;
    width: $default-input-width;
    margin-left: 0;
    margin-bottom: .5em;
    
    .fname,
    .lname {
      float: left;
      clear: none;
      margin-right: 10px;
      margin-bottom: 0;
      width: math.div($default-input-width, 2);
      
      label {
        width: auto;
        position: relative;
        top: 2.25em;
        font-weight: normal;
        font-size: 0.91em;
      }
      input {
        position: relative;
        top: -1.95em;
        width: 100%;
      }
    }
    .lname {
      margin-right: 0;
    }
  }
}


// Comment Forms
// ----------------------------------------
@mixin comment-field-defaults {
  .notify_me {
    @include no-label-padding;
    .top_labels & { padding-left: 0; }
  }
}


// Login
// ----------------------------------------
@mixin login-field-defaults {
  form {
    .forgot_password {
      padding-top: $base-line-height/2;
      @include no-label-padding;
      font-size: $base-font-size - 1;
    }
    .remember_me {
      @include no-label-padding;
      label {
        display: inline;
        float: none;
        font-weight: normal;
      }
      input {
        display: inline;
        float: none;
      }
    }
  }
}


// Search field
// ----------------------------------------------------------------
@mixin unstyled-search-input {
  // Color of placeholder text
  &::-webkit-input-placeholder { color: $quiet-font-color; }
  &:-moz-placeholder { color: $quiet-font-color; }

  // Disable webkit appearance
  -webkit-appearance: textfield;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  
  // Disable focus rectangle
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}


// Star Rating
// ----------------------------------------------------------------
// <span class="star-rating-input">
//   <input type="radio" name="star-rating" value="1"><i></i>
//   <input type="radio" name="star-rating" value="2"><i></i>
//   <input type="radio" name="star-rating" value="3"><i></i>
//   <input type="radio" name="star-rating" value="4"><i></i>
//   <input type="radio" name="star-rating" value="5"><i></i>
// </span>
// <strong class="star-rating-choice">&nbsp;</strong>

.star-rating-input {
  @include trailer;
  font-size: 0;
  white-space: nowrap;
  display: inline-block;
  width: 120px;
  height: 24px;
  overflow: hidden;
  position: relative;
  background: url('/mgs_assets/images/v4/svg-icons-source/star.svg');
  background-size: contain;
  vertical-align: text-bottom;

  i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background: url('/mgs_assets/images/v4/svg-icons-source/star-orange.svg');
    background-size: contain;
  }

  input { 
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    display: inline-block !important;
    width: 20%;
    height: 100%; 
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
    cursor: pointer;

    &:hover + i,
    &:checked + i {
      opacity: 1;    
    }
  }

  i ~ i             { width: 40%; }
  i ~ i ~ i         { width: 60%; }
  i ~ i ~ i ~ i     { width: 80%; }
  i ~ i ~ i ~ i ~ i { width: 100%; }
}

.star-rating-choice {
  display: inline-block;
  padding-left: .5em;
}

.counter {
  text-align: right;
  font-weight: bold;

  &-alert {
    color: red;
  }
}


// In-field Labels
// ----------------------------------------------------------------
// <div class="infield-label">
//   <input type="text|search" id="input-id" required>
//   <label for="input-id">Text Here</label>
// </div>

.infield-label {
  position: relative;

  > label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: .2s;
    opacity: 1;
  }

  &.hide-label {
    > input:focus + label,
    > input:valid + label {
      opacity: 0;
    }  
  }

  
}



// Custom Select Styling
// ----------------------------------------------------------------
// ref. https://gist.github.com/mojaray2k/569305b307ff10f44176
.custom-select {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 100%;

  // Theme-y styles
  border: 1px solid #bbb;
  border-radius: .3em;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  background: #f3f3f3; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#e5e5e5));
  background: -webkit-linear-gradient(top, #ffffff 0%,#e5e5e5 100%);
  background: -o-linear-gradient(top, #ffffff 0%,#e5e5e5 100%);
  background: -ms-linear-gradient(top, #ffffff 0%,#e5e5e5 100%);
  background: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 );

   // Custom arrow - could be an image, SVG, icon font, etc.
  &:after {
    background: none;
    color: #bbb;
    content: "\25BC";
    font-size: .7em;
    padding:0;
    position: absolute;
    right: 1em;
    // top: 1.2em;
    bottom: .3em;
    z-index: 1;
    // This hack makes the select behind the arrow clickable in some browsers
    pointer-events:none;
  }

  // Hover style - tricky because we're clipping the overflow
  &:hover {
    border:1px solid #888;
  }

  select {
    // Make sure the select is wider than the container so we can clip the arrow
    width:110%;
    max-width:110%;
    min-width:110%;
    appearance: none; // Remove select styling
    -webkit-appearance: none;
    -moz-appearance: window; // Ugly Firefox way of doing it
    text-indent: 0.01px;
    text-overflow: "";
    font-size:16px; // Magic font size number to prevent iOS text zoom
    font-weight: bold;
    background:none;
    border: none;
    color: #444;
    outline: none;
    padding: .4em 19% .4em .8em; // Padding works surpringly well
    font-family: helvetica, sans-serif;
    line-height:1.2;
    margin:.2em;

    // This hides native dropdown button arrow in IE
    &::-ms-expand {
      display: none;
    }
    
    // Focus style
    &:focus {
      outline: none;
      box-shadow: 0 0 3px 3px rgba(180,222,250, .85);
    }
    
    // This hides focus around selected option in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }
}