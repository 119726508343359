// Posts
// ----------------------------------------------------------------

$blog-color: palette(blue);
$presentations-color: palette(blue);
$reviews-color: palette(blue);

// Titles
// ----------------------------------------------------------------
.entry-title {
  a {
    @include unstyled-link-with-hover;
  }
}


// Post Large
// ----------------------------------------------------------------
.post-large {

  & + .post-large {
    @include rhythm(0,3,3,0);
    border-bottom: 1px solid $divider-color;
  }

  &.hentry-full {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .entry-title {
    text-align: center;
    @include trailer(.25);
  }

  .post-metadata,
  .entry-summary {
    @include trailer;
  }

  :where(h2, h3, h4, h5) {
    scroll-padding-block-start: var(--page-block-start);
  }
}

// TOC
// ----------------------------------------------------------------
@include breakpoint($med) {
  .post-large:has(.entry-toc) {
    display: grid;
    grid-template-columns: 7fr 3fr;
    grid-template-areas: "content subnav";
    gap: 0 3em;
  
    .metadata {
      grid-column: content / subnav;
      grid-row: 1/2;
    }
    .entry-content, .entry-toc {
      grid-row-start: 2;
    }
    :is(.entry-content, #promo, .post-footer-full) {
      grid-column: content-start / content-end;
    }
    .entry-toc {
      grid-column: subnav-start / subnav-end;
    }
  }
}

.entry-toc {
  margin-block-end: 1em;

  .sidenav-heading {
    font-size: 1em;
    font-weight: normal;
    
    @include breakpoint($med) {
      --heading-color: #00c3a9; // palette(teal);
      
      font-size: 0.9em;
      margin-block: .125em 0;
      padding-block: 0;
      border: none;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: .05ch;
    }
  }
  
  ol { 
    list-style: none;
    padding-inline: 0;

    > li + li {
      border-top: 1px solid palette(blue, x-light);
    }
  }
  ol ol {
    padding-inline: .5em 0;
    margin-block-end: .25em;

    > li {
      list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='9'%3E%3Cpath fill='%2398D8FF' fill-rule='nonzero' d='M1.375 9a.687.687 0 0 1-.486-1.174l2.952-2.951L.89 1.923a.687.687 0 1 1 .972-.972L5.3 4.388a.687.687 0 0 1 0 .973L1.86 8.798A.682.682 0 0 1 1.375 9Z'/%3E%3C/svg%3E");
      padding-inline: .25em 0;
      border-top: none;
    }
  }

  ol a {
    color: $base-font-color;
    text-decoration: none;
    display: block;
    font-size: 0.9em;
    padding-block: .5em;

    &:hover {
      color: $link-color;
      transform: translateX(.25em);
    }
  }

  // Sub Items
  > ol > li > ol {
    font-size: 0.85em;
    font-weight: bold;

    a { padding-block: .25em; }
  }

  // Sub-sub Items
  ol ol ol li {
    font-weight: normal;
  }
}

// Lead magnets in sidebar nav
.entry-toc .promo-lead-magnet {
  margin-block-start: 1em;

  @include breakpoint(max-width $med) {
    display: none;
  }
  .promo-image {
    display: none;
  }
  .promo-heading {
    font-weight: bold;
    text-align: left;
    margin-block-start: 0;
  }
  .promo-content {
    text-align: left;
    font-size: 0.9em;
  }
}



// Preview Image
.post-preview-image {
  img {
    display: block;
    margin: 0 auto;
  }
}

.post-preview-image-small {
  @include breakpoint($med) {
    @include squish(3);
  }
}

// Post Full
// ----------------------------------------------------------------
.entry-subtitle {
  @include leader(-.5);
  text-align: center;
}

.post-image-quote {
  margin: 0;
  @include trailer;
}


// Post Metadata
// ----------------------------------------------------------------
.post-metadata {
  color: palette(grey, mid-light);
}
.post-large .post-metadata {
  padding-bottom: 1em;
  font-size: .8em;
  color: palette(grey, mid-light);
  border-bottom: 1px solid $divider-color;
  display: flex;
  justify-content: center;

  > * {
    max-width: 50%;
  }

  > *:not(:first-child) {
    margin-left: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .avatar-sm {
    --avatar-size: 3rem;
    margin-right: 0.8em;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: $link-color;
    }
  }

  .tags strong {
    display: block;
  }
}


// Post Footer
// ----------------------------------------------------------------

.post-footer {
  overflow: hidden;

  .post-continue {
    float: right;
  }
}

// Footer Large (on full post pages)
.post-footer-full {
  @include rhythm(2,1,1,2);
  border-block: 1px solid $divider-color;
  position: relative;
  text-align: center;

  .addthis_toolbox {
    > a {
      float: none;
      display: inline-block;
    }
  }
}



