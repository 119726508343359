.progress-circle {
  --size: 16px;
  --color: #65bef6;
  --value: 0%;
  --border-width: 2px;
  
  display: inline-block;
  width: var(--size);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: var(--border-width) var(--color) solid;
  background: conic-gradient(
          var(--color) 0% var(--value),
          transparent var(--value)
  )
}

.progress-circle.quarter        { --value: 25%; }
.progress-circle.half           { --value: 50%; }
.progress-circle.three-quarters { --value: 75%; }
.progress-circle.full           { --value: 100%; }

.progress-circle-label {
  --color: #65bef6;

  text-transform: uppercase;
  color: var(--color);
  font-size: .9em;
  line-height: 1;
  font-weight: bold;
}