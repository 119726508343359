// Alerts
// ----------------------------------------------------------------
$alert-border-radius : 4px !default;

$alert-bg-color      : #fffde7 !default;
$alert-txt-color     : #555 !default;

$error-txt-color     : #990000 !default;
$error-bg-color      : #fde5e5 !default;
$error-border-color  : #cb7a7a !default;

// Alerts
// ----------------------------------------------------------------

@mixin alert-colors($bg-color, $bdr-color) {
  background: $bg-color;
  border: 1px solid $bdr-color;
}

@mixin alert {
  @include alert-colors($alert-bg-color, darken($alert-bg-color, 10));
  margin-bottom: 1em;
  border-radius: $alert-border-radius;
  padding: .5em 1em;
  color: $alert-txt-color;

  > *:first-child { margin-top: 0; }
  > *:last-child { margin-bottom: 0; }

  p {
    margin-bottom: 0;
  }
  p + p {
    @include leader(.5);
  }
}

.alert,
.alert-info {
  @include alert;
}



// Errors
// ----------------------------------------------------------------

.alert-error,
.errors,
#errorExplanation,
#error_explanation {
  @include alert;
  @include alert-colors($error-bg-color, $error-border-color);
  text-align: left;
}

.error,
.fieldWithErrors,
.field_with_erors {
  label {
    color: $error-txt-color;
  }
  input.text,
  input[type=text],
  input[type=email],
  input[type=url],
  input[type=tel],
  input[type=password],
  textarea {
    background: $error-bg-color;
  }
}