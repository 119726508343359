$tab-text-color: palette(grey, light) !default;
$tab-text-hover-color: palette(blue) !default;


// Tab Lists
*[role="tablist"] a {
  cursor: pointer;
}

// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

.tabs {
  @include rhythm(2, 0, 0, 1.5);
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  text-align: center;

  > li {
    @include trailer(.5);
    display: inline-block;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  > li > a {
    text-transform: uppercase;
    color: $tab-text-color;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $tab-text-hover-color;
    }
  }

  > li.active > a {
    color: $link-color;
    border-bottom-color: $link-color;
  }
}

.vtabs .tabs {
  margin: 0;
  text-align: left;

  > li {
    margin: 0;
    padding: 0;
    display: block;

    > a {
      display: block;
      padding: .25em 0;
      border: none;
      text-transform: none;

      &:hover {
        color: $tab-text-color;
        padding-left: .75em;
      }
    }

    &.active > a:hover {
      color: $tab-text-hover-color;
      padding-left: 0;
    }

    &.active > a,
    > a:hover {
      &:after {
        content: '\00BB';
        display: inline-block;
        padding: 0 .25em;
      }
    }
  }
}


[class*="floating-tabs"] {
  .tabs {
    margin: 0;
    text-align: left;
    font-size: 1.1em;

    > li {
      padding: 0;

      > a {
        text-transform: none;
        border: none;
        padding: .5em;
      }
    }
    > li.active a {
      color: palette(teal);
      box-shadow: 0 2px 4px rgba(0,0,0,.25);
      border-radius: .5rem;
      background-color: #fff;
    }
  }

  .tab-pane {
    border: none;
    background-color: #ebf7ff;

    > *:first-child { margin-top: 0; }
    > *:last-child { margin-bottom: 0; }
  }
}

.floating-tabs-l {
  display: flex;
  flex-direction: column;
  
  .tabs {
    flex: 1;
    position: relative;
    z-index: 1;
    margin-top: 2rem;
    display: flex;
    overflow-x: auto;
    padding: .5rem;

    > li {
      display: block;
      margin: 0;
      padding: 0;
    }
    > li > a {
      display: block;
      text-align: center;
      white-space: nowrap;
    }
    > li.active > a {
      transform: none;
      text-align: center;
    }
  }
  .tab-content {
    flex: 4;
  }
  .tab-pane {
    padding: 1rem
  }
}

  
@include breakpoint($med) {
  .floating-tabs-l {
    flex-direction: row;

    .tabs {
      padding: 0;
      flex-direction: column;
      overflow: visible;
    }
    .tabs > li {
      padding: .25rem 0;
    }
    .tabs > li > a {
      text-align: left;
    }
    .tabs > li.active > a {
      width: 12em;
      transform: translateX(2em);
    }
    .tab-pane {
      padding: 2rem 3rem 2rem 5rem;
    }
  }
}


// Volume Discount Tabs
.volume-discount-tabs {
  .tabs {
    > li > a {
      text-transform: none;
      text-align: left;
      color: #fff;
      border-radius: 4px;
      padding: .25em .5em;

      &:hover {
        background-color: #6fb2f9;
      }
    }
    > li.active > a {
      background-color: #d5efff;
      color: #444;
    }
  }

  .media-item {
    margin-right: .25em;
  }

}

.volume-discount-tabs + .panel {
  grid-column: span 2;
}


// Private Training Tabs
.private-training-tabs {
  border-bottom: 1px solid palette(blue);

  .tabs {
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;

    > li {
      margin: 0 0 -2px; /* pull next section up so line is behind active tab */
      padding: 0;
      flex: 1;
    }


    > li > a {
      text-decoration: none;
      border: none;
      text-transform: none;
      background: #E4EEF4;
      border: 1px solid #E4EEF4;
      margin: 0 0 1em;
      padding: 0.5em 1em;
      display: block;
      border-radius: 0.25em;
    }

    > li.active > a {
      background: #fff;
      border-color: palette(blue);
      border-bottom: none;
      border-radius: .25em .25em 0 0;
      padding-bottom: 1.5em;
      margin-bottom: 0;
    }

    strong {
      font-weight: normal;
      font-size: 1.2em;
    }
    .text-small {
      display: block;
      font-size: .75em;
    }
    .active .text-small {
      color: palette(grey);
    }
  }
  
}


.tabs.pill-tabs {
  font-size: 0.9em;
  
  li {
    padding: 0;
  }

  a {
    text-transform: none;
    border: 1px solid palette(grey, light);
    background-color: #fff;
    padding: .5em 1em;
    border-radius: 2em;
    margin: 1px;

    &:hover {
      background-color: palette(blue, x-light);
      border-color: palette(blue);
      color: palette(blue);
    }
  }

  .active a, .active a:hover {
    border-color: palette(blue);
    color: palette(blue);
    border-width: 2px;
    margin: 0;
  }
}