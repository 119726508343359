$card-bg-color      : white !default;
$card-border-radius : 4px !default;
$card-border-color  : #dbe3e5 !default;
$card-padding       : 1rem !default;
$card-font-size     : .9rem !default;
$card-hero-size     : 20rem;

$calc-max: 999;

// Base card styles and layout
.card {
  background: $card-bg-color;
  border-radius: $card-border-radius;
  border: 1px solid $card-border-color;
  font-size: $card-font-size;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  > * {
    padding-left: $card-padding;
    padding-right: $card-padding;
  }
  > *:first-child:not(.card-hero) {
    padding-top: $card-padding;
  }
  > *:last-child {
    padding-bottom: $card-padding;
  }
}

// Ensure no link styles applied if whole card is a link
a.card {
  text-decoration: none;
  color: inherit;
  transition: all .25s;

  &:hover {
    border-color: darken($card-border-color, 15);
    box-shadow: rgba(0,0,0,.15) 0 .25em 3px;
    transform: translateY(-.25em);
    background-color: darken($card-bg-color, 1);
  }

  > * {
    width: 100%; // for IE11
  }
}

// Hero doesn't have padding
.card-hero {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; // for IE11
}

.card-title {
  font-size: 1.5em;
  line-height: 1.2;
}

// Footer sticks to bottom
.card-footer {
  margin-top: auto;
}

// Card Switcher, displays elements horizontally at smaller screen sizes
// Intended for when cards are within a grid
@include breakpoint(max-width $med-lg) {
  .card-switcher {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
  
    .card-hero {
      flex: 3;
      align-self: stretch;
      margin-right: 0;
    }
    .card-title {
      flex: 5;
      margin-top: 0; // disable stack margins
      font-size: 1.2em;
      padding-top: .25em;
      padding-bottom: .25em;
      font-weight: 400;
    }
    .card-switcher-full {
      flex-basis: 100%; // force to wrap
    }
    .card-switcher-hide {
      display: none;
    }
    .text-center {
      text-align: left; // force centered text to be left-aligned
    }
    
    // Inset the content
    .card-switcher-inset {
      background: #f9fbfc;
      margin-top: 0;
      padding-top: .75rem;
      box-shadow: 0 1px 1px rgba(0,0,0,0.1) inset;
      color: #647587;
      height: 100%;
    }
     
  }
}

// Horizontal Cards
// requires alternate markup with title nested within body
@include breakpoint(min-width $med) {
  .card-horizontal {
    flex-direction: row;

    .card-hero {
      max-width: 33%;
    }
  }
}