// Landing Pages
// ----------------------------------------------------------------

// Centered Logo in Header
// ----------------------------------------------------------------
header[role="banner"].no-nav {
  .logo {
    width: 100%;
    margin-left: 0;

    a {
      width: 100%;
      background-position: 50% 0;
      background-size: auto;
    }
  }
}


// General Landing Page
// ----------------------------------------------------------------

%lp-type {
  @include breakpoint($med) {
    font-size: 16px;
  }
}

body.landing {

  .stripe-first {
    @include padding-leader(1);
  }

  .heading-light {
    font-size: 26px;
    @include rhythm(0,0,0,.5);
    font-weight: 100;
    color: $base-font-color;
  }

  .pretty-embed {
    box-shadow: $default-box-shadow;

    img, iframe { display: block; }
  }

  .video iframe {
    display: block;
  }

}


// Video Course Landing Page
// ----------------------------------------------------------------

body.landing-video-course {

  .button {
    padding-left: 4em;
    padding-right: 4em;
  }

  .stripe-mountains.stripe-first {
    h1 {
      margin-bottom: 0;
    }
    h2 {
      font-size: 19px;
      @include rhythm(0,0,0,1);
      color: $base-font-color;
      font-weight: 600;
      text-align: center;
    }
  }

  .banner-intro {
    @extend %lp-type;

    @include breakpoint($med) {
      @include span(6);
    }

    p:first-child {
      margin-top: 0;
    }
  }

  .banner-video {
    @include breakpoint($med) {
      @include span(6);
      @include omega;
    }
  }

  .banner-video-footer {
    font-size: 14px, .75;
    border-bottom-radius: $default-border-radius;
    background: #fff;
    color: #63a437;
    text-align: center;
    font-weight: 600;
    padding: .5em 1em .75em;
    border: 1px solid #bbbbbb;
  }

  // Stacked intro 
  .intro-stacked {
    .banner-intro,
    .banner-video {
      @include breakpoint($med) {
        @include span(10);
        @include pre(1);
      }
    }

    .banner-video iframe {
      margin: 0 auto 2em;
    }

    .banner-intro ul {
      font-size: 18px;
      @include delimited-list(',');
      display: block;
      text-align: center;
    }
  }

  // Smaller video
  .intro-video-narrow {
    @include breakpoint($lg) {
      .banner-intro {
        @include span(7);
      }
      .banner-video {
        @include span(5);
        @include omega;
      }
    }
    .heading-primary-alt {
      margin-bottom: 1em !important;
    }
    .banner-intro ul {
      @include delimited-list(',');
      display: block;
      text-align: center;
    }
  }

  .enroll-description {
    @include breakpoint($med) {
      @include span(5);
      @include pre(1);
    }
  }

  .benefit-list {
    @extend %lp-type;

    ol {
      padding-left: 0;
    }

    li {
      @include trailer;
      padding-right: 2em;
    }

    h4 {
      color: $base-font-color;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
      color: $quiet-font-color;
    }
  }

  @include breakpoint($lg) {

    .benefit-list-1 {
      @include span(6);
    }
    .benefit-list-2 {
      @include span(6);
      @include omega;
    }

  }

  .role {
    @include trailer;
    clear: both;

    @include breakpoint($lg) {
      @include squish(1, 1);
    }

    &-avatar-wrapper {
      text-align: center;

      @include breakpoint($med) {
        @include span(2 of 10);
        text-align: right;
      }
    }

    &-avatar {
      border-radius: 100%;
      background: #fff;
      width: 90px;
      height: 90px;
      display: inline-block;

      img {
        max-height: 100%;
        display: block;
        margin: 0 auto;
      }
    }

    h2 {
      margin: 0;
    }

    h2, p {
      color: $base-font-color;

      @include breakpoint($med) {
        @include span(8 of 10);
        @include omega;
      }
    }

  }

}


// Mindtools
// ----------------------------------------------------------------

body.landing-mindtools {

  .banner-feature {
    @include trailer;
    @include breakpoint($lg) {
      @include gallery(6);
      margin-bottom: 0;
    }
  }

  .post-med {
    @include breakpoint($lg) {
      @include gallery(6);
    }
  }

  .img-bleed {
    @include breakpoint($lg) {
      margin: -1em;
    }
  }

}


// Start Here
// ----------------------------------------------------------------
.start-here-amc-panel {
  background: #4b9aea url(/mgs_assets/images/v4/pages/landing/amc-pattern.png) 0 0 repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: .5rem;
  
  @include breakpoint($lg) {
    flex-direction: row;
  }

  > * {
    flex: 1 1 33%;
    margin: 1em;
  }
}