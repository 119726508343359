// Tags
// ----------------------------------------------------------------
.tags {
  .icon {
    vertical-align: sub;
    font-size: 1em;
    margin-right: .25em;
  }

  .tags-heading {
    display: inline;
    font-size: inherit;
    color: inherit;
  }

  ul {
    @include delimited-list(',');
  }

  a {
    @include link-colors(inherit, $link-color);
    text-decoration: none;
  }
}