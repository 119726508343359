.cluster {
  --gap: .5rem;
}

.cluster {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap);
}

// Centered
.cluster-centered {
  justify-content: center;
}

// Loosely spaced
.cluster-loose {
  --gap: 2rem;
}

// No wrapping, clip the overflow
.cluster-line {
  flex-wrap: nowrap;
  overflow: hidden;
}