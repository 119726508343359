// Maps
// ----------------------------------------------------------------

$map-height: 460px;

#gmap {
  img {
    max-width: none;
  }
}

.map-infobox {
  @include type-setting(-1);
  padding: .5em 1em;
  min-width: 215px;

  a {
    @extend .content-link;
  }
}

// Make space for overlay on non-single map-stripe
.map-stripe {
  .gmap {
    @include breakpoint($lg) {
      @include span(last 8);
    }
  }
}

.gmap {
  height: $map-height;
  margin: 0 1em;

  @include breakpoint($med) {
    margin: 0;
  }
}


.map-stripe-single,
.map-stripe {
  .map-stripe-overlay {
    position: relative;
    z-index: 10;
    padding-right: 1em;
    display: none;

    h2 {
      @include trailer(.5);
    }

    .view-more-list {
      li {
        @include trailer(.5);
      }
    }
  }
}

// Show map-stripe-overlay on non-single map-stripe
.map-stripe {
  .map-stripe-overlay {
    display: block;
    @include breakpoint($lg) {
      @include span(4); 
      height: $map-height;
      margin-top: -1*$map-height;
    }
  }
}
