.pagination .button {
  flex: 0 1 2em;
  aspect-ratio: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: palette(grey, light);
  color: palette(grey, light) !important;
  background-color: transparent;

  &.active {
    border-color: palette(teal);
    color: palette(teal) !important;
    background-color: #fff;
    outline: 1px solid palette(teal);
  }

  &:hover {
    background-color: palette(teal, x-light);
  }

  &.page-next     { margin-inline-start: 1.5em; }
  &.page-previous { margin-inline-end: 1.5em; }
}