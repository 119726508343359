// Breadcrumbs
// ----------------------------------------------------------------

$breadcrumbs-height     : $base-line-height * 1.5 !default;
$breadcrumb-color       : palette(blue, light) !default;
$breadcrumb-color-dark  : palette(blue, dark) !default;


.breadcrumbs {
  @include padding-trailer(.5);
  height: $breadcrumbs-height;
  line-height: $breadcrumbs-height;
  padding-left: gutter();
  display: none;

  &[class*='stripe-'] {
    border: none;
  }

  @include breakpoint($med) {
    display: block;
  }

  ol {
    @include inline-block-list;
  }

  li {
    margin-right: .25em;
    &:after {
      content: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%227px%22%20height%3D%2210px%22%20viewBox%3D%220%200%207%2010%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%3E%20%20%20%20%20%20%20%20%3Ctitle%3Ebreadcrumb-divider%3C%2Ftitle%3E%20%20%20%20%3Cdescription%3ECreated%20with%20Sketch.%3C%2Fdescription%3E%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%20%20%20%20%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20sketch%3Atype%3D%22MSPage%22%3E%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M2.00000787%2C0.999976397%20C1.9479244%2C0.999976397%201.90625762%2C1.02080978%201.86459085%2C1.05205987%20L0.552087404%2C2.36456331%20C0.479170546%2C2.43748017%200.479170546%2C2.56248049%200.552087404%2C2.63539735%20L3.41667825%2C5.4999882%20L0.552087404%2C8.36457904%20C0.479170546%2C8.4374959%200.479170546%2C8.56249623%200.552087404%2C8.63541309%20L1.86459085%2C9.94791653%20C1.9375077%2C10.0208334%202.06250803%2C10.0208334%202.13542489%2C9.94791653%20L6.4479362%2C5.63540522%20C6.52085306%2C5.56248836%206.52085306%2C5.43748803%206.4479362%2C5.36457118%20L2.13542489%2C1.05205987%20C2.09375811%2C1.02080978%202.05209134%2C0.999976397%202.00000787%2C0.999976397%20L2.00000787%2C0.999976397%20Z%22%20id%3D%22breadcrumb-divider%22%20fill%3D%22%23BAC5CF%22%20sketch%3Atype%3D%22MSShapeGroup%22%3E%3C%2Fpath%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E');
      display: inline-block;
      position: relative;
      margin-left: 1em;
      margin-right: 0.2em;
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }

  a {
    @include type-setting(-1);
    transition-duration: 0;
    color: $breadcrumb-color;
    text-transform: capitalize;
    text-decoration: none;
    // font-weight: 600;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb-home {
    position: relative;
    left: .35em;
    background-repeat: no-repeat;
    display: inline-block;
    width: 12px;
    height: 13px;

    &:hover {
      border-bottom: none;
    }

    svg {
      fill: $breadcrumb-color;
    }
  }
}

.stripe-green .breadcrumbs a,
.stripe-green.breadcrumbs a {
  color: #fff;
}

.stripe-blue .breadcrumbs a,
.stripe-blue.breadcrumbs a {
  color: #e3eaf1;
}

// Pull up the proceeding content and show breadcrumbs on top
.breadcrumbs-pullup {
  margin-bottom: $breadcrumbs-height * -1;
  position: relative;
  z-index: 1;
}

// Alter the colors to light
.breadcrumbs-light {
  a {
    color: white;
  }
  .breadcrumb-home svg {
    fill: white;
  }
}

// Alter the colors to dark
.breadcrumbs-dark {
  a {
    color: $breadcrumb-color-dark;
  }
  .breadcrumb-home svg {
    fill: $breadcrumb-color-dark;
  }
}

.breadcrumbs-fade {
  transition: opacity .5s;
  opacity: .6;

  &:hover {
    opacity: 1;
  }
}