// Nav Adjustments
.nav-primary > li:has(.mega-menu) {
  position: static;
}

// Hover Trigger
.nav-primary > li:has(.mega-menu):hover .mega-menu,
.mega-menu:hover {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}
.nav-primary > li:has(.mega-menu):hover > span::after {
  display: block;
}

// Extra spacer to avoid MM disappearing too soon
.nav-primary > li:has(.mega-menu) > span::after {
  content: '';
  height: 1.5em;
  margin: 0px -3em;
  position: absolute;
  bottom: -1.5em;
  width: calc(100% + 6em);
  display: none;
}

.mega-menu {
  --mm-gap: 2em;
  --mm-bg-color: #f3f7fa;
  --mm-text-color: #4c4d52;
  --mm-text-color-quiet: #6189b0;
  --mm-icon-color: #65bef6;
  --mm-list-gap: .5em;

  position: absolute;
  z-index: z-index(mega-menu);
  top: calc(var(--header-height) - 6px); // relative parent is header, so no need to calc announcement, minus header borders
  left: 0;
  width: 100vw;
  background: var(--mm-bg-color);
  box-shadow: rgba(0,0,0,.2) 0 12px 11px;
  border-top: 1px solid palette(blue, x-dark);
  transition: all .2s;
  transform: translateY(.25em);
  opacity: 0;
  visibility: hidden;
  font-size: 1em;


  h3 {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: .05em;
    color: palette(blue);
    margin: 0;
  }
  h3 + * {
    margin-block-start: 1em;
  }

  a {
    transition-duration: .15s;
    text-decoration: none;
    color: var(--mm-text-color);
  }
  a:hover {
    color: $link-color;
  }

  *:where(ul, li) {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.mm-columns {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  gap: var(--mm-gap);
  padding-inline-start: 2em;
}

.mm-primary {
  padding: 1.5em 0 2em;

  h3 {
    color: palette(teal);
  }

  svg {
    transition: all .2s;
  }

  a {
    background-color: #fff;
    display: block;
    padding: var(--mm-list-gap) calc(var(--mm-list-gap) * 2);
    border-radius: .25em;
    display: block;

    &:hover {
      color: #111;
      box-shadow: 0 0 1px 1px rgba(129,181,214,0.5);

      small {
        color: #315d88;
      }
      svg {
        transform: scale(1.1);
      }
    }
  }
  
  small {
    display: block;
    color: #6189b0;
    font-size: 0.8em;
  }

  li + li {
    margin-block-start: var(--mm-list-gap);
  }

  .media {
    gap: .75em;
  }
}

.mm-secondary {
  padding: 1.5em 1em 2em;

  * + h3 {
    margin-block-start: 2em;
  }
  
  li + li {
    margin-block-start: calc(var(--mm-list-gap) * .5);
  }

  svg {
    transition: all .2s;
  }

  a {
    display: flex;
    gap: .35em;
  }
  a:hover svg {
    transform: scale(1.1);
  }
  a:hover span {
    color: $link-color;
  }

  span {
    color: palette(blue, light);
    cursor: pointer;
  }
}

.mm-featured {
  background-color: #e5eef3;
  padding: 1.5em 2em 2em;

  h3 {
    color: palette(blue, x-dark);
    text-align: center;
  }

  img {
    border-radius: .25em;
  }
}
.mm-featured-inner {
  margin-inline: auto;
  max-width: 440px;
}

// Specific menus

.mm-resources {

  .mm-primary,
  .mm-secondary {
    grid-column: 1/3;

  }
  
  .mm-primary   {
    grid-row: 1/2;
    padding-block-end: 0;

    h3 {
      text-align: center;
    }
    
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1em;
    }
    li {
      margin: 0;
    }
  }

  .mm-secondary {
    grid-row: 2/3;
    padding-block-start: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;

    h3 {
      margin-block-start: 0;
    }
  }

  .mm-featured  {
    grid-row: 1/3;
    grid-column: 3/4;
  }
}

.mm-about {

  background: var(--mm-bg-color) url(/mgs_assets/images/v4/backgrounds/bg-mountains-mm.svg) 50% 101% / 100% repeat-x;

  .mm-secondary {
    grid-column: 1/3;
    padding-inline: 0;

    > h3 {
      text-align: center;
      color: palette(teal);
    }

    > ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1em;

      > li {
        background-color: #fff;
        display: block;
        padding: var(--mm-list-gap) calc(var(--mm-list-gap) * 2);
        border-radius: .25em;
        display: block;
      }

      h3 {
        margin-block-start: .5em;
      }
    }
  }

  .mm-featured {
    grid-column: 3/4;
  }
}